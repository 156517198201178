import React, { useContext, useState } from "react";
import { parse } from "date-fns";
import { useIntl } from "react-intl";
import type { FormatDateOptions } from "react-intl";
import {
  Banner,
  Button,
  ConfirmationModal,
  Content,
  Heading,
  Text,
} from "@jobber/components";
import type { ApolloError } from "@apollo/client";
import { useMutation } from "@apollo/client";
import type {
  GoogleBusinessAccountDisconnectMutation,
  NotificationSchedule,
} from "~/utilities/API/graphql";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { SideDrawerMenuOption } from "~/jobber/SideDrawerMenuOption/SideDrawerMenuOption";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { messages } from "../messages";
import styles from "../ReviewsSettings.module.css";
import { GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION } from "../ReviewsSettings.graphql";

export function ManageSettings({
  error,
  loading,
  schedules,
}: {
  error?: ApolloError;
  loading: boolean;
  schedules: NotificationSchedule[];
}): JSX.Element {
  const { formatMessage, formatTime } = useIntl();
  const { drawerActions } = useContext(ReviewsSettingsDrawerContext);
  const [disconnectAccount, { loading: disconnectLoading }] =
    useMutation<GoogleBusinessAccountDisconnectMutation>(
      GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION,
    );
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const { initialSchedule, secondarySchedule } = {
    initialSchedule: schedules[0],
    secondarySchedule: schedules[1],
  };

  const renderFollowUpLayout = () => {
    const failedToGetSchedules =
      error ||
      (!loading && initialSchedule === undefined) ||
      (!loading && secondarySchedule === undefined);

    if (failedToGetSchedules) {
      return (
        <>
          <Heading level={4}>{formatMessage(messages.followUpsTitle)}</Heading>
          <Text variation="error">{formatMessage(messages.followUpError)}</Text>
        </>
      );
    }

    const firstFollowUpLabel = initialSchedule?.enabled
      ? formatMessage(
          messages.initialFollowUp,
          buildMessageParams(initialSchedule, formatTime),
        )
      : formatMessage(messages.followUpScheduleDisabled);
    const secondFollowUpLabel = secondarySchedule?.enabled
      ? formatMessage(
          messages.secondaryFollowUp,
          buildMessageParams(secondarySchedule, formatTime),
        )
      : formatMessage(messages.followUpScheduleDisabled);

    return (
      <>
        <SideDrawerMenuOption
          ariaLabel={formatMessage(
            messages.firstFollowUpMessageSettingsAriaLabel,
          )}
          heading={formatMessage(messages.followUpsTitle)}
          label={firstFollowUpLabel}
          loading={loading}
          onClick={() =>
            drawerActions?.goTo(DrawerView.FollowUpMessageSettings1)
          }
        />
        <SideDrawerMenuOption
          ariaLabel={formatMessage(
            messages.secondFollowUpMessageSettingsAriaLabel,
          )}
          label={secondFollowUpLabel}
          loading={loading}
          onClick={() => {
            drawerActions?.goTo(DrawerView.FollowUpMessageSettings2);
          }}
        />
      </>
    );
  };

  return (
    <PrivacyMask disabled>
      <div className={styles.container}>
        {displayBanner && (
          <Banner type="error" dismissible={false}>
            {formatMessage(messages.generalError)}
          </Banner>
        )}
        {error && (
          <Banner type="error" dismissible={true}>
            {formatMessage(messages.scheduledTimeOffsetError)}
          </Banner>
        )}

        <Heading level={3}>{formatMessage(messages.template)}</Heading>
        <Content>
          <SideDrawerMenuOption
            ariaLabel={formatMessage(messages.messageSettingsAriaLabel)}
            heading={formatMessage(messages.messageTitle)}
            label={formatMessage(messages.messageDescription)}
            loading={loading}
            onClick={() => drawerActions?.goTo(DrawerView.MessageSettings)}
          />
          {renderFollowUpLayout()}
        </Content>
        <div>
          <Heading level={3}>{formatMessage(messages.accounts)}</Heading>
          <div className={styles.inlineSection}>
            <Text>{formatMessage(messages.accountsDescription)}</Text>
            <Button
              label={formatMessage(messages.disconnect)}
              type="secondary"
              variation="destructive"
              size="small"
              loading={disconnectLoading}
              onClick={() => setConfirmationModalOpen(true)}
            />
            <ConfirmationModal
              title={formatMessage(messages.confirmDisconnectTitle)}
              message={formatMessage(messages.confirmDisconnectDescription)}
              variation="destructive"
              size="small"
              open={confirmationModalOpen}
              confirmLabel={formatMessage(messages.disconnect)}
              onConfirm={() =>
                disconnectAccount()
                  .then(() => {
                    window.location.href = "/reviews/";
                  })
                  .catch(() => {
                    setDisplayBanner(true);
                  })
              }
              onRequestClose={() => setConfirmationModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </PrivacyMask>
  );
}

function buildMessageParams(
  node: Partial<NotificationSchedule>,
  formatTime: (
    value: number | Date | string,
    opts?: FormatDateOptions,
  ) => string,
) {
  let nodeAt = "";

  if (node.at) {
    const parsedTime = parse(node.at, "HH:mm:ss.SSSSSSSSS", new Date());
    nodeAt = formatTime(parsedTime, {
      hourCycle: window.is_24_hour_format ? "h23" : "h12",
    });
  }

  return {
    offsetValue: node.offset?.value || 0,
    offsetUnit:
      node.offset?.value === 1
        ? node.offset?.unit.toLowerCase()
        : `${node.offset?.unit.toLowerCase()}s`,
    at: nodeAt,
  };
}
