import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from "jobber/marketplace/ANALYTICS_EVENTS";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "../../messages";

export interface ChangePlanModalProps {
  appId: string;
  appName: string;
  requiredPlan: string;
  openChangePlanModal: boolean;
  onCancelChangePlan(): void;
}

export function ChangePlanModal({
  appId,
  appName,
  requiredPlan,
  openChangePlanModal,
  onCancelChangePlan,
}: ChangePlanModalProps) {
  const { formatMessage } = useIntl();

  const {
    changePlanModalConfirmLabel,
    changePlanModalUpgradeMessage,
    changePlanModalTitle,
    changePlanModalRequiredPlan,
  } = messages;

  const { confirmButtonLabel, upgradeMessage, modalTitle, requiredPlanText } = {
    modalTitle: formatMessage(changePlanModalTitle, { appName }),
    upgradeMessage: formatMessage(changePlanModalUpgradeMessage, { appName }),
    requiredPlanText: formatMessage(changePlanModalRequiredPlan, {
      requiredPlan: requiredPlan,
    }),
    confirmButtonLabel: formatMessage(changePlanModalConfirmLabel),
  };

  function onConfirmChangePlan() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickChoosePlan, {
      appId: appId,
      appName: appName,
      source: ANALYTICS_PROPERTIES.marketplacePageSource,
    });
    window.open("/accounts/billing_info/pricing", "_blank");
  }

  return (
    <Modal
      title={modalTitle}
      open={openChangePlanModal}
      primaryAction={{
        label: confirmButtonLabel,
        onClick: onConfirmChangePlan,
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: onCancelChangePlan,
      }}
      onRequestClose={onCancelChangePlan}
    >
      <Content>
        <Text>{upgradeMessage}</Text>
        <Text>{requiredPlanText}</Text>
      </Content>
    </Modal>
  );
}
