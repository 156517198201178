import { gql } from "@apollo/client";

const CLIENT_SEGMENT_WITH_CLIENT_FIELDS = gql`
  fragment clientSegmentWithClientFields on ClientSegment {
    clients(first: $first, after: $after) {
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          emails {
            primary
            id
            description
            address
          }
          defaultEmails
          name
        }
      }
      totalCount
    }
    type
    friendlyName
    id
  }
`;

export const GET_CLIENTS_SEGMENT = gql`
  query getCommsClientsSegment(
    $interval: Int = 12
    $unit: IntervalUnits = MONTHS
    $first: Int = 20
    $after: String = ""
    $sort: [ClientSegmentSortInput!] = [{ key: NAME, direction: ASCENDING }]
    $pastClientLineItemCriteria: [LineItemCriteriaInput!] = []
    $pastClientTagCriteria: [ClientTagCriteriaInput!] = []
    $pastClientJobTypeCriteria: [JobTypeCriteriaInput!] = []
    $allClientTagCriteria: [ClientTagCriteriaInput!] = []
    $allClientTypeCriteria: [ClientTypeCriteriaInput!] = []
    $allClientLineItemCriteria: [LineItemCriteriaInput!] = []
    $allClientJobStatusCriteria: [JobStatusCriteriaInput!] = []
    $allClientJobTypeCriteria: [JobTypeCriteriaInput!] = []
    $upcomingClientLineItemCriteria: [LineItemCriteriaInput!] = []
    $upcomingClientTagCriteria: [ClientTagCriteriaInput!] = []
    $upcomingClientJobTypeCriteria: [JobTypeCriteriaInput!] = []
    $upcomingClientJobStatusCriteria: [JobStatusCriteriaInput!] = []
    $upcomingClientInterval: Int = 30
    $upcomingClientUnit: IntervalUnits = DAYS
    $isPastClientDrawer: Boolean = false
    $isAllClientDrawer: Boolean = false
    $isUpcomingClientDrawer: Boolean = false
  ) {
    commsPastClientsSegment(
      interval: $interval
      unit: $unit
      sort: $sort
      lineItemCriteria: $pastClientLineItemCriteria
      clientTagCriteria: $pastClientTagCriteria
      jobTypeCriteria: $pastClientJobTypeCriteria
    ) @include(if: $isPastClientDrawer) {
      ...clientSegmentWithClientFields
    }
    commsAllClientsSegment(
      sort: $sort
      clientTagCriteria: $allClientTagCriteria
      clientTypeCriteria: $allClientTypeCriteria
      lineItemCriteria: $allClientLineItemCriteria
      jobStatusCriteria: $allClientJobStatusCriteria
      jobTypeCriteria: $allClientJobTypeCriteria
    ) @include(if: $isAllClientDrawer) {
      ...clientSegmentWithClientFields
    }
    commsUpcomingClientsSegment(
      sort: $sort
      lineItemCriteria: $upcomingClientLineItemCriteria
      clientTagCriteria: $upcomingClientTagCriteria
      jobTypeCriteria: $upcomingClientJobTypeCriteria
      jobStatusCriteria: $upcomingClientJobStatusCriteria
      interval: $upcomingClientInterval
      unit: $upcomingClientUnit
    ) @include(if: $isUpcomingClientDrawer) {
      ...clientSegmentWithClientFields
    }
  }
  ${CLIENT_SEGMENT_WITH_CLIENT_FIELDS}
`;
