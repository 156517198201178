import type React from "react";
import { useEffect } from "react";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";

interface JobStatusCriterionProps {
  criteriaIndex: number;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

export function JobStatusCriterion({
  criteriaIndex,
  updateAdditionalCriteria,
}: JobStatusCriterionProps): JSX.Element {
  useEffect(() => {
    updateAdditionalCriteria({
      type: "updateJobStatusCriteria",
      payload: {
        index: criteriaIndex,
        isDirty: true,
      },
    });
    // We only want this to fire when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this criterion does not return anything - it is here to trigger the updateAdditionalCriteria action so the count is properly recalculated
  // @ts-ignore
  return null;
}
