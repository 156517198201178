import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React from "react";
import { useIntl } from "react-intl";
import { Flex } from "@jobber/components/Flex";
import { Box } from "@jobber/components/Box";
import { AddDebitCardDialog } from "jobber/payments_sca/components/AddDebitCardDialog/AddDebitCardDialog";
import CreditCardListItem from "jobber/payments_sca/components/CreditCardListItem";
import useDialogOpen from "jobber/payments_sca/hooks/useDialogOpen";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { withStripeElementProvider } from "~/bunker/payments_react/clientHubJobberPayments/utilities/withStripeElementProvider";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import { messages } from "./messages";

export interface InstantPayoutDetailsProps {
  debitCard?: CreditCard;
  onDebitCardChange?(debitCard: CreditCard): void;
  isJobberMoneyPayout: boolean;
}

export const InstantPayoutDetails =
  withStripeElementProvider<InstantPayoutDetailsProps>(
    InstantPayoutDetailsWithStripe,
  );

export function InstantPayoutDetailsWithStripe(
  props: InstantPayoutDetailsProps,
) {
  const { debitCard, isJobberMoneyPayout } = props;
  const [learnMoreUrl] = useUrls("docsInstantPayoutsUrl");
  const { formatMessage } = useIntl();

  return (
    <Content>
      <Box padding={{ vertical: "small" }}>
        <Flex template={["grow", "shrink"]}>
          <Heading level={3}>{formatMessage(messages.instantPayouts)}</Heading>
          <ChangeDebitCard {...props} />
        </Flex>
      </Box>

      {isJobberMoneyPayout === true ? (
        <Text>{formatMessage(messages.instantPayoutUnavailable)}</Text>
      ) : (
        <>
          <Box gap="small">
            <Text variation="subdued">
              {formatMessage(messages.instantPayoutDescription)}
            </Text>
            <Text>
              <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
                {formatMessage(messages.learnAboutInstantPayout)}
              </a>
            </Text>
          </Box>
          <Content>
            {debitCard && (
              <section>
                <CreditCardListItem {...debitCard} />
              </section>
            )}
          </Content>
        </>
      )}
    </Content>
  );
}

function ChangeDebitCard(props: InstantPayoutDetailsProps) {
  const { debitCard, onDebitCardChange } = props;
  const [isDialogOpen, openDialog, closeDialog] = useDialogOpen(false);
  const {
    permissions,
    enabled: jobberPaymentsEnabled,
    instantPayoutOverrideEnabled,
  } = useJobberPayments();
  const shouldShow = permissions.canChangeBankAccount;
  const shouldDisable = !jobberPaymentsEnabled || !instantPayoutOverrideEnabled;
  const buttonLabel = debitCard ? "Change debit card" : "Link a debit card";

  return (
    <>
      {shouldShow && (
        <>
          <div className="js-add-debit-card-button">
            <TextActionButton
              label={buttonLabel}
              disabled={shouldDisable}
              onClick={openDialog}
            />
          </div>
          {jobberPaymentsEnabled && !instantPayoutOverrideEnabled && (
            <shared-tooltip
              bind="hover"
              target=".js-add-debit-card-button"
              direction="above"
              class="tooltip"
            >
              Changing debit cards is temporarily disabled
            </shared-tooltip>
          )}
        </>
      )}
      <AddDebitCardDialog
        isDialogOpen={isDialogOpen}
        onRequestCloseDialog={closeDialog}
        onDebitCardAdded={onDebitCardChange}
      />
    </>
  );
}
