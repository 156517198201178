import React from "react";
import { Content } from "@jobber/components/Content";
import { Form } from "@jobber/components/Form";
import type { Segment } from "~/utilities/API/graphql";
import type { UseClientSegmentOptionsType } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentOptions/useClientSegmentOptions";
import { SelectableSegment } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/SelectableSegments/SelectableSegment/SelectableSegment";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { AllClientsSegmentEdit } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AllClientsSegmentEdit/AllClientsSegmentEdit";
import { UpcomingClientsSegmentEdit } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/UpcomingClientsSegmentEdit/UpcomingClientsSegmentEdit";
import { PastClientsSegmentEdit } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/PastClientsSegmentEdit/PastClientsSegmentEdit";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import type { CriteriaProps } from "jobber/campaigns/types";
import {
  isAllClientCriteriaInput,
  isPastClientCriteriaInput,
  isUpcomingClientCriteriaInput,
} from "./utils";

interface CriteriaPropsEditable extends CriteriaProps {
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
}

export interface SelectableSegmentsProps {
  segmentOptions: UseClientSegmentOptionsType[];
  value: Segment;
  name: string;
  onChange: (newValue: Segment) => void;
  loading: boolean;
  criteria: CriteriaPropsEditable;
  openSideDrawer?: () => void;
}

export function SelectableSegments({
  segmentOptions,
  value,
  name,
  onChange,
  loading,
  criteria,
  openSideDrawer,
}: SelectableSegmentsProps) {
  const {
    campaignSegment: { segmentCriteriaFormRef, setSegmentCriteriaFormState },
  } = useCampaignWizardContext();

  return (
    <PrivacyMask disabled>
      <Content spacing="large">
        {segmentOptions.map((segment, index) => {
          return (
            <SelectableSegment
              heading={segment.heading}
              recommended={segment.recommended}
              isSelected={value === (segment.value as Segment)}
              name={name}
              segmentValue={segment.value}
              onChange={onChange}
              loading={loading}
              key={index}
            >
              <Form
                onStateChange={setSegmentCriteriaFormState}
                ref={segmentCriteriaFormRef}
              >
                {isAllClientCriteriaInput(criteria.baseCriteria, value) && (
                  <AllClientsSegmentEdit
                    additionalCriteria={criteria.additionalCriteria}
                    updateAdditionalCriteria={criteria.updateAdditionalCriteria}
                    openSideDrawer={openSideDrawer}
                  />
                )}

                {isUpcomingClientCriteriaInput(
                  criteria.baseCriteria,
                  value,
                ) && (
                  <UpcomingClientsSegmentEdit
                    additionalCriteria={criteria.additionalCriteria}
                    updateAdditionalCriteria={criteria.updateAdditionalCriteria}
                    openSideDrawer={openSideDrawer}
                  />
                )}

                {isPastClientCriteriaInput(criteria.baseCriteria, value) && (
                  <PastClientsSegmentEdit
                    additionalCriteria={criteria.additionalCriteria}
                    updateAdditionalCriteria={criteria.updateAdditionalCriteria}
                    openSideDrawer={openSideDrawer}
                  />
                )}
              </Form>
            </SelectableSegment>
          );
        })}
      </Content>
    </PrivacyMask>
  );
}
