import React, { memo } from "react";
import { useIntl } from "react-intl";
import { KPISection } from "~/shared/KPISection/KPISection";
import { TrendLabel } from "~/shared/TrendLabel/TrendLabel";
import {
  computePercentChange,
  getCurrentLabel,
  getPreviousLabel,
} from "jobber/dataVisualizations/utils/dataVisualizationsUtils";
import {
  getPast30Days,
  getPast60Days,
} from "jobber/dataVisualizations/utils/dateRangeUtils";
import type { Maybe } from "~/utilities/API/graphql";
import type {
  CreateDescriptionsProps,
  CurrentAndPreviousValues,
  Descriptions,
  Metric,
  MetricProps,
  TrendComponentProps,
  UseAverageDaysMetricsProps,
} from "./types";
import { messages } from "../messages";

const { after: currentStart, before: currentEnd } = getPast30Days();
const { after: previousStart } = getPast60Days();

export const useAverageDaysMetrics = ({
  comparativeAverageDaysToGettingPaid,
  tooltipEventCallback,
}: UseAverageDaysMetricsProps) => {
  const { formatMessage: t } = useIntl();

  const { commercial, residential, previous } =
    comparativeAverageDaysToGettingPaid ?? {};
  const { commercial: previousCommercial, residential: previousResidential } =
    previous ?? {};
  const descriptions = createDescriptions({ commercial, residential, t });

  const metrics: Metric[] = [];

  const addMetric = (
    currentValue: Maybe<number>,
    previousValue: Maybe<number>,
    description: string,
  ): void => {
    if (displayValue({ currentValue, previousValue })) {
      metrics.push(
        createMetric({
          currentValue,
          previousValue,
          description,
          tooltipEventCallback,
        }),
      );
    }
  };

  addMetric(commercial, previousCommercial, descriptions.commercialDescription);
  addMetric(
    residential,
    previousResidential,
    descriptions.residentialDescription,
  );

  return metrics.length ? metrics : [createMetric({ tooltipEventCallback })];
};

const createDescriptions = ({
  commercial,
  residential,
  t,
}: CreateDescriptionsProps): Descriptions => {
  const areBothValid = isValidValue(commercial) && isValidValue(residential);
  return {
    commercialDescription: areBothValid
      ? t(messages.averageDaysToGettingPaidCommercialTrend)
      : "",
    residentialDescription: areBothValid
      ? t(messages.averageDaysToGettingPaidResidentialTrend)
      : "",
  };
};

const displayValue = ({
  currentValue,
  previousValue,
}: CurrentAndPreviousValues) => {
  const isComparable = hasValidComparableValues({
    currentValue: currentValue,
    previousValue: previousValue,
  });

  return isComparable || isValidValue(currentValue);
};

const createMetric = ({
  currentValue,
  previousValue,
  description = "",
  tooltipEventCallback,
}: MetricProps): Metric => {
  const trendIsComparable = hasValidComparableValues({
    currentValue,
    previousValue,
  });

  return {
    value: currentValue?.toString() ?? "-",
    valueDescription: description,
    trend: trendIsComparable ? (
      <TrendComponent
        previousValue={previousValue}
        currentValue={currentValue}
        tooltipEventCallback={tooltipEventCallback}
      />
    ) : null,
  };
};

const isValidValue = (value: Maybe<number>): boolean =>
  value !== null && value !== undefined;

const hasValidComparableValues = ({
  currentValue,
  previousValue,
}: CurrentAndPreviousValues): boolean =>
  isValidValue(currentValue) && isValidValue(previousValue);

const calculatePercentChange = ({
  currentValue,
  previousValue,
}: CurrentAndPreviousValues): number =>
  computePercentChange(currentValue as number, previousValue as number) ?? 0;

const useBuildTrendData = ({
  previousValue,
  currentValue,
}: CurrentAndPreviousValues): [
  { label: string; value: string },
  { label: string; value: string },
] => {
  const { formatMessage: t } = useIntl();

  return [
    {
      label: getPreviousLabel(previousStart, currentStart),
      value: t(messages.averageDaysToGettingPaidTrendValue, {
        value: previousValue,
      }),
    },
    {
      label: getCurrentLabel(currentStart, currentEnd),
      value: t(messages.averageDaysToGettingPaidTrendValue, {
        value: currentValue,
      }),
    },
  ];
};

const TrendComponent = memo(
  ({
    previousValue,
    currentValue,
    tooltipEventCallback,
  }: TrendComponentProps) => {
    const { formatMessage: t } = useIntl();
    const buildTrendData = useBuildTrendData({ previousValue, currentValue });

    return (
      <KPISection.Trend
        title={t(messages.averageDaysToGettingPaidTitle)}
        data={buildTrendData}
        eventCallback={tooltipEventCallback}
      >
        <TrendLabel
          value={calculatePercentChange({ currentValue, previousValue })}
          invertTrend
        />
      </KPISection.Trend>
    );
  },
);

TrendComponent.displayName = "TrendComponent";
