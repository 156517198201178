import { defineMessages } from "react-intl";

export const messages = defineMessages({
  firstNamePlaceholder: {
    id: "billing.paymentDetailsEdit.firstNamePlaceholder",
    defaultMessage: "First name",
    description: "Label text for first name field",
  },
  lastNamePlaceholder: {
    id: "billing.paymentDetailsEdit.lastNamePlaceholder",
    defaultMessage: "Last name",
    description: "Label text for last name field",
  },
  cardNumberPlaceholder: {
    id: "billing.paymentDetailsEdit.cardNumberPlaceholder",
    defaultMessage: "Card number",
    description: "Label text for card number field",
  },
  secureCheckoutText: {
    id: "billing.paymentDetailsEdit.secureCheckoutText",
    defaultMessage: "Secure checkout",
    description: "Text to disclose secure checkout",
  },
});
