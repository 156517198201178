import {
  add,
  differenceInDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  format,
  isBefore,
  isMatch,
  isWithinInterval,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
} from "date-fns";
import type { Datum } from "@nivo/line";
import type { BarDatum } from "@nivo/bar";
import { ChartDataAggregationInterval } from "jobber/dataVisualizations/types";
import {
  getEndOfWeekDay,
  getStartOfWeekDay,
} from "jobber/dataVisualizations/utils/dateRangeUtils";
import type { Iso8601DateRange } from "jobber/dataVisualizations/types";

// The format of the date in the data returned from the query.
export const dayFormat = "yyyy-MM-dd";

export const xAxisLabelMapping: { [key: string]: string } = {
  [ChartDataAggregationInterval.DAILY]: "d",
  [ChartDataAggregationInterval.WEEKLY]: "MMM d",
  [ChartDataAggregationInterval.MONTHLY]: "MMM yyyy", // needed for if repeating months are in the beginning and end of the range
  [ChartDataAggregationInterval.QUARTERLY]: "MMM yyyy", // needed for if there are repeating months
  [ChartDataAggregationInterval.YEARLY]: "yyyy",
};

export const timePeriodMapping: { [key: string]: string } = {
  [ChartDataAggregationInterval.DAILY]: "yyyy-MM-dd",
  [ChartDataAggregationInterval.WEEKLY]: "yyyy-MM-dd",
  [ChartDataAggregationInterval.MONTHLY]: "yyyy-MM",
  [ChartDataAggregationInterval.QUARTERLY]: "yyyy-MM", // needed for if there are repeating months
  [ChartDataAggregationInterval.YEARLY]: "yyyy",
};

export function formatTick(
  tick: number,
  {
    yTickPrefix,
    yTickSuffix,
  }: { yTickPrefix?: string; yTickSuffix?: string } = {},
) {
  const formattedNumber = Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 0,
  }).format(tick);

  return `${yTickPrefix || ""}${formattedNumber}${yTickSuffix || ""}`;
}

export function computePercentChange(current: number, prevVal: number) {
  if (prevVal === 0) return current === 0 ? 0 : 100;

  return Math.round(((current - prevVal) / prevVal) * 100);
}

export function getCurrentLabel(start: string, end: string) {
  const startDate = format(parseISO(start), "MMM d");
  const endDate = format(parseISO(end), "MMM d");
  return `${startDate} - ${endDate}`;
}

export function getPreviousLabel(start: string, end: string) {
  const startDate = format(subDays(parseISO(start), 1), "MMM d");
  const endDate = format(subDays(parseISO(end), 1), "MMM d");
  return `${startDate} - ${endDate}`;
}

export const getChartDataAggregationInterval = (
  daysInRange: number,
): string => {
  if (daysInRange <= 12) {
    return ChartDataAggregationInterval.DAILY;
  } else if (daysInRange <= 84) {
    return ChartDataAggregationInterval.WEEKLY;
  } else if (daysInRange <= 365) {
    return ChartDataAggregationInterval.MONTHLY;
  } else if (daysInRange <= 3 * 365) {
    return ChartDataAggregationInterval.QUARTERLY;
  } else {
    return ChartDataAggregationInterval.YEARLY;
  }
};

const getWeeklyIntervals = ({
  dateRange,
  weekStartsOnMonday,
}: {
  dateRange: Iso8601DateRange;
  weekStartsOnMonday: boolean;
}): Date[] => {
  const daysInRange = differenceInDays(
    parseISO(dateRange.before),
    parseISO(dateRange.after),
  );

  const intervals = [startOfDay(parseISO(dateRange.after))];
  const endOfWeekDay = getEndOfWeekDay(
    parseISO(dateRange.after),
    weekStartsOnMonday,
  );
  const firstDayOfNextInterval = add(endOfWeekDay, { days: 1 });
  const lengthOfFirstWeek = differenceInDays(
    endOfWeekDay,
    parseISO(dateRange.after),
  );
  for (let i = 0; i < daysInRange - lengthOfFirstWeek; i += 7) {
    intervals.push(startOfDay(add(firstDayOfNextInterval, { days: i })));
  }
  return intervals;
};

// eslint-disable-next-line max-statements
export const getIntervals = ({
  chartDataAggregationInterval,
  dateRange,
  weekStartsOnMonday,
}: {
  chartDataAggregationInterval: string;
  dateRange: Iso8601DateRange;
  weekStartsOnMonday: boolean;
}): Date[] => {
  switch (chartDataAggregationInterval) {
    case ChartDataAggregationInterval.DAILY: {
      return eachDayOfInterval({
        start: parseISO(dateRange.after),
        end: parseISO(dateRange.before),
      });
    }
    case ChartDataAggregationInterval.WEEKLY: {
      return getWeeklyIntervals({ dateRange, weekStartsOnMonday });
    }
    case ChartDataAggregationInterval.MONTHLY: {
      return eachMonthOfInterval({
        start: parseISO(dateRange.after),
        end: parseISO(dateRange.before),
      });
    }

    case ChartDataAggregationInterval.YEARLY: {
      return eachYearOfInterval({
        start: parseISO(dateRange.after),
        end: parseISO(dateRange.before),
      });
    }

    case ChartDataAggregationInterval.QUARTERLY: {
      const intervals: Date[] = [];
      const monthly = eachMonthOfInterval({
        start: parseISO(dateRange.after),
        end: parseISO(dateRange.before),
      });
      for (let i = 0; i < monthly.length; i += 3) {
        intervals.push(monthly[i]);
      }
      return intervals;
    }

    default:
      return [];
  }
};

export const getGroupLabel = ({
  date,
  dateRange,
  chartDataAggregationInterval,
  weekStartsOnMonday,
}: {
  date: string;
  dateRange: Iso8601DateRange;
  chartDataAggregationInterval: string;
  weekStartsOnMonday: boolean;
}) => {
  switch (chartDataAggregationInterval) {
    case ChartDataAggregationInterval.DAILY: {
      // TODO: See if this is necessary as part of JOB-105138
      // If the date is already in yyyy-MM-dd format, trying to format it again results in the previous day being returned.
      return isMatch(
        date,
        timePeriodMapping[ChartDataAggregationInterval.DAILY],
      )
        ? date
        : format(
            parseISO(date),
            timePeriodMapping[ChartDataAggregationInterval.DAILY],
          );
    }
    case ChartDataAggregationInterval.WEEKLY: {
      const startOfWeek = getStartOfWeekDay(parseISO(date), weekStartsOnMonday);
      return isBefore(startOfWeek, parseISO(dateRange.after))
        ? dateRange.after
        : startOfWeek.toISOString();
    }
    case ChartDataAggregationInterval.MONTHLY:
    case ChartDataAggregationInterval.QUARTERLY:
      return format(
        startOfMonth(parseISO(date)),
        timePeriodMapping[
          ChartDataAggregationInterval[chartDataAggregationInterval]
        ],
      );

    case ChartDataAggregationInterval.YEARLY: {
      return format(
        parseISO(date),
        timePeriodMapping[ChartDataAggregationInterval.YEARLY],
      );
    }
    default:
      return "unknown interval";
  }
};

export const getTimePeriod = (
  date: string,
  chartDataAggregationInterval: string,
) => {
  return format(
    parseISO(date),
    timePeriodMapping[chartDataAggregationInterval],
  );
};

export const getXAxisTick = (
  date: string,
  chartDataAggregationInterval: string,
) => {
  return format(
    parseISO(date),
    xAxisLabelMapping[chartDataAggregationInterval],
  );
};

export const findQuarterForDate = (date: Date, quarters: Date[]): string => {
  for (let i = 0; i < quarters.length; i++) {
    const currentQuarterStartDate = quarters[i];
    const nextQuarterStartDate =
      i < quarters.length - 1 ? quarters[i + 1] : null;

    if (
      nextQuarterStartDate === null ||
      isWithinInterval(date, {
        start: currentQuarterStartDate,
        end: nextQuarterStartDate,
      })
    ) {
      return format(
        currentQuarterStartDate,
        timePeriodMapping[ChartDataAggregationInterval.QUARTERLY],
      );
    }
  }
  // If no matching quarter is found, return the date. It should not happen.
  return format(
    date,
    timePeriodMapping[ChartDataAggregationInterval.QUARTERLY],
  );
};

// Returns the x-axis ticks, skipping every other tick to avoid cluttering when there are 8 or more.
export const getXAxisTicks = (data: BarDatum[] | Datum[]): string[] => {
  const xAxisTicks = data.map(d => d.xAxisTick || d.x);

  if (data.length < 8) {
    return xAxisTicks;
  }

  const filteredTickValues = [];

  for (let i = 0; i < xAxisTicks.length; i++) {
    if (i % 2 === 0) {
      filteredTickValues.push(xAxisTicks[i]);
    }
  }

  return filteredTickValues;
};

export const getAxisBottomFormatter = (
  chartDataAggregationInterval: string,
) => {
  return (value: string) => {
    if (
      chartDataAggregationInterval === ChartDataAggregationInterval.MONTHLY ||
      chartDataAggregationInterval === ChartDataAggregationInterval.QUARTERLY
    ) {
      return value.split(" ")[0];
    }
    return value;
  };
};

interface DataPoint {
  [key: string]: string | number;
}

export function getBarChartTicks<T extends DataPoint>(
  data: T[],
  field1: keyof T,
  field2: keyof T,
  stacked: boolean | undefined = false,
) {
  // Calculate max for both field1 and field2
  const maxField1 = Math.max(...data.map(item => Number(item[field1])));
  const maxField2 = Math.max(...data.map(item => Number(item[field2])));
  const overallMax = stacked
    ? // when stacked, the max is the highest sum of field1 and field2
      Math.max(...data.map(item => Number(item[field1]) + Number(item[field2])))
    : Math.max(maxField1, maxField2);
  const midPoint = overallMax / 2;

  return [0, midPoint, overallMax];
}
