import React, { type Dispatch, type SetStateAction, useContext } from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { Heading } from "@jobber/components/Heading";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { PaymentPreviewDisplay } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentPreviewDisplay/PaymentPreviewDisplay";
import { PaymentDetailsDisplay } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsDisplay/PaymentDetailsDisplay";
import { PaymentDetailsEdit } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/components/PaymentDetailsEdit/PaymentDetailsEdit";
import type {
  StoredPaymentDetails,
  UpdatedPaymentDetailsType,
} from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/PaymentDetails.d";
import { messages } from "jobber/billing/components/EditBillingInfo/components/PaymentDetails/messages";
import styles from "./ExperimentalPaymentDetailsStyles.module.css";

export interface PaymentDetailsProps {
  isPurchasing: boolean;
  showPreview: boolean;
  shouldShowPaymentDetailsDisplay: boolean;
  storedPaymentDetails: StoredPaymentDetails;
  updatedPaymentDetails: UpdatedPaymentDetailsType;
  trackingDetails: TrackingDetails;
  setUpdatedPaymentDetails: React.Dispatch<
    React.SetStateAction<UpdatedPaymentDetailsType>
  >;
  setShouldShowPaymentDetailsDisplay: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setRecurlyFieldsInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  onValidationError(error: FieldErrorState): void;
}

export function ExperimentalPaymentDetails(props: PaymentDetailsProps) {
  const {
    shouldShowPaymentDetailsDisplay,
    isPurchasing,
    showPreview,
    storedPaymentDetails,
    updatedPaymentDetails,
    trackingDetails,
    setUpdatedPaymentDetails,
    setShouldShowPaymentDetailsDisplay,
    setRecurlyFieldsInitialized,
    onValidationError,
  } = props;

  if (showPreview && shouldShowPaymentDetailsDisplay) {
    return (
      <PaymentPreviewRow
        storedPaymentDetails={storedPaymentDetails}
        hasPaymentError={!!storedPaymentDetails.hasPaymentError}
        onClickAction={setShouldShowPaymentDetailsDisplay}
      />
    );
  }

  return (
    <Content>
      <PaymentDetailsHeader
        showButton={shouldShowPaymentDetailsDisplay}
        hasPaymentError={!!storedPaymentDetails.hasPaymentError}
        onClickAction={setShouldShowPaymentDetailsDisplay}
      />
      {shouldShowPaymentDetailsDisplay ? (
        <PaymentDetailsDisplay storedPaymentDetails={storedPaymentDetails} />
      ) : (
        <PaymentDetailsEdit
          isPurchasing={isPurchasing}
          updatedPaymentDetails={updatedPaymentDetails}
          trackingDetails={trackingDetails}
          setUpdatedPaymentDetails={setUpdatedPaymentDetails}
          setRecurlyFieldsInitialized={setRecurlyFieldsInitialized}
          onValidationError={onValidationError}
        />
      )}
    </Content>
  );
}

function PaymentDetailsHeader({
  showButton,
  hasPaymentError,
  onClickAction,
}: {
  showButton: boolean;
  hasPaymentError: boolean;
  onClickAction: Dispatch<SetStateAction<boolean>>;
}) {
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);

  return (
    <div className={styles.paymentDetailsHeaderContainer}>
      <Heading level={4}>{formatMessage(messages.cardTitle)}</Heading>

      {showButton && (
        <div className={styles.paymentDetailsErrorLabelAndEditButton}>
          {hasPaymentError && (
            <div className={styles.paymentDetailsErrorLabel}>
              <InlineLabel color="red">
                {formatMessage(messages.paymentDetailsError)}
              </InlineLabel>
            </div>
          )}
          <Button
            ariaLabel={"Edit payment details"}
            type="tertiary"
            label={formatMessage(messages.editButton)}
            icon="arrowRight"
            iconOnRight={true}
            size="small"
            disabled={submitting}
            onClick={() => onClickAction(false)}
          />
        </div>
      )}
    </div>
  );
}

function PaymentPreviewRow({
  storedPaymentDetails,
  hasPaymentError,
  onClickAction,
}: {
  storedPaymentDetails: StoredPaymentDetails;
  hasPaymentError: boolean;
  onClickAction: Dispatch<SetStateAction<boolean>>;
}) {
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);

  return (
    <div className={styles.paymentPreviewHeaderContainer}>
      <PaymentPreviewDisplay storedPaymentDetails={storedPaymentDetails} />

      <div className={styles.paymentDetailsErrorLabelAndEditButton}>
        {hasPaymentError && (
          <div className={styles.paymentDetailsErrorLabel}>
            <InlineLabel color="red">
              {formatMessage(messages.paymentDetailsError)}
            </InlineLabel>
          </div>
        )}
        <Button
          type="tertiary"
          label={formatMessage(messages.editPaymentButton)}
          size="small"
          disabled={submitting}
          onClick={() => onClickAction(false)}
        />
      </div>
    </div>
  );
}
