/* eslint-disable no-restricted-imports */
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { Tab, Tabs } from "@jobber/components/Tabs";
import { Heading } from "@jobber/components/Heading";
import currency from "currency.js";
import React, {
  type FormEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import type { PaymentRequest } from "@stripe/stripe-js";
import { IntlProvider } from "@translations/IntlProvider";
import { PaymentReducerContext } from "~/utilities/contexts/internal/PaymentReducerContext";
import { APIProvider } from "~/utilities/API/APIProvider";
import type { CollectPaymentDialogProps } from "./CollectPaymentDialogProps.d";
import { SuccessBanner } from "./SuccessBanner";
import {
  JOBBER_PAYMENTS_ACH_TYPE,
  JOBBER_PAYMENTS_TYPE,
  useDerivedStates,
} from "./useDerivedStates";
import { JobberPaymentsPaymentContent } from "./JobberPaymentsPaymentContent";
import styles from "./BaseCollectPaymentDialog.module.css";
import { SignaturePadContainer } from "./SignaturePadContainer";
import { PaymentButtonBar } from "../PaymentButtonBar";
import type { GroupedPaymentTypes } from "../PaymentMethodSelector";
import { paymentReducer } from "../paymentReducer";
import {
  type BankInfo,
  BankPaymentSecurityText,
} from "../BankPaymentSecurityText";

/* eslint-disable max-statements */
export function BaseCollectPaymentDialog(props: CollectPaymentDialogProps) {
  const {
    title = "Collect payment",
    clientId,
    clientName,
    attachedToId,
    clientDetails,
    attachedToType,
    superSaveItems,
    billingAddress,
    itemLabel,
    groupedPaymentTypes,
    defaultNoteValue,
    reloadAfterSuccess,
    disableAmountEdit,
    disablePaymentTypeEdit,
    signatureRequired,
    bankPaymentACHSettings,
    allowCreditCardPayments = true,
    showSavePaymentMethod = true,
    mandatoryCardOnFile = false,
    showSecurityAssurance,
    isDialogOpen = false,
    oauthStateId,
    companyName,
    defaultPaymentMethod,
    onDialogClose,
    onChargeButtonFormat = defaultChargeButtonFormat,
  } = props;

  /*
    This state object and method is passed down all the way to
    <PlaidLinkWrapper> to set & unset error messages on the dialog.
  */
  const initialAuthorizedBankingInfoState = {
    publicToken: "",
    metadata: {},
  };
  const [bankPaymentErrorMessage, setBankPaymentErrorMessage] = useState("");
  const [bankPaymentAuthorized, setBankPaymentAuthorized] = useState(false);
  const [bankPaymentInfo, setBankPaymentInfo] = useState<BankInfo | undefined>(
    undefined,
  );
  const [authorizedBankingInfo, setAuthorizedBankingInfo] = useState(
    initialAuthorizedBankingInfoState,
  );
  const [paymentRequest, setPaymentRequest] = useState<
    PaymentRequest | undefined
  >(undefined);

  const [paymentState, paymentDispatcher] = useReducer(paymentReducer, {
    status: "notInitialized",
    amount: currency(props.amount, { symbol: "" }).format(),
    paymentType: resolveDefaultPaymentType(
      groupedPaymentTypes,
      defaultPaymentMethod,
    ),
    attachedToId,
    attachedToType,
    jobberPaymentMethodId: undefined,
    billingDetails: { address: billingAddress, name: clientName },
    showSavePaymentMethod: showSavePaymentMethod,
    mandatoryCardOnFile: mandatoryCardOnFile,
  });

  const {
    reducerContext,
    isJobberPaymentsCreditCard,
    isPaymentInProgress,
    onNewAmount,
    onPaymentTypeChange,
    isJobberPaymentsAch,
  } = useDerivedStates(paymentState, paymentDispatcher);
  useEffect(
    () => onNewAmount(currency(props.amount, { symbol: "" }).format()),
    [props.amount],
  );

  const formElementRef = useRef<HTMLFormElement | null>(null);

  const onDialogCancel = () => {
    setBankPaymentInfo(undefined);
    setAuthorizedBankingInfo(initialAuthorizedBankingInfoState);
    if (onDialogClose) {
      onDialogClose();
    }
  };

  return (
    <PaymentReducerContext.Provider value={reducerContext}>
      <APIProvider>
        <IntlProvider>
          <SuccessBanner
            amount={paymentState.amount}
            closeDialog={onDialogCancel}
            reloadAfterSuccess={reloadAfterSuccess}
            itemLabel={itemLabel}
          />

          <Modal
            title={title}
            size="small"
            open={isDialogOpen}
            onRequestClose={onDialogCancel}
            dismissible={false}
          >
            <div className={styles.regularMargins}>
              <SignaturePadContainer
                required={signatureRequired}
                paymentDispatcher={paymentDispatcher}
              />
            </div>
            {renderPaymentMethodsContent(bankPaymentACHSettings?.showACH)}
          </Modal>
        </IntlProvider>
      </APIProvider>
    </PaymentReducerContext.Provider>
  );

  function renderPaymentMethodsContent(allowAchPayments = false) {
    if (allowCreditCardPayments && allowAchPayments) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        // If we are returning from the OAuth Plaid flow, we need to go to the Bank Payment tab
        if (oauthStateId) {
          const bankTab = Array.from(document.querySelectorAll("button")).find(
            el => el.textContent === "Bank Payment",
          );
          // eslint-disable-next-line no-unused-expressions
          bankTab?.click();
        }
      }, []);

      /*
        If renderPaymentBarButton accepts true, we will show the bank selection button.
        Similarly, when renderCreditCardDialog accepts false, we will hide the signature pad from within.
      */
      return (
        <>
          <div className={styles.regularMargins}>
            <Heading level={5}>Choose a payment method</Heading>
          </div>
          <div
            className={
              bankPaymentAuthorized
                ? styles.paymentTabsNotClickable
                : styles.paymentTabs
            }
          >
            <Tabs>
              <Tab label="Credit Card">
                {renderCreditCardDialog()}
                {renderPaymentBarButton(true)}
              </Tab>
              <Tab label="Bank Payment">
                {renderAchDialog()}
                {renderPaymentBarButton(true, true)}
              </Tab>
            </Tabs>
          </div>
        </>
      );
    } else if (allowCreditCardPayments) {
      return (
        <>
          {renderCreditCardDialog()}
          {renderPaymentBarButton(false)}
        </>
      );
    } else if (allowAchPayments) {
      return (
        <>
          {renderAchDialog()}
          {renderPaymentBarButton(false, true)}
        </>
      );
    }
  }

  function renderCreditCardDialog() {
    return (
      <JobberPaymentsPaymentContent
        clientDetails={clientDetails}
        clientId={clientId}
        defaultNoteValue={defaultNoteValue}
        disableAmountEdit={disableAmountEdit}
        disablePaymentTypeEdit={disablePaymentTypeEdit}
        formElementRef={formElementRef}
        groupedPaymentTypes={groupedPaymentTypes}
        isPaymentInProgress={isPaymentInProgress}
        paymentState={paymentState}
        onNewAmount={onNewAmount}
        onPaymentTypeChange={onPaymentTypeChange}
        onSubmit={onSubmit}
        isJobberPaymentsCreditCard={isJobberPaymentsCreditCard}
        isJobberPaymentsAch={isJobberPaymentsAch}
        parentProps={props}
        paymentRequest={paymentRequest}
        setPaymentRequest={setPaymentRequest}
      />
    );
  }

  function renderAchDialog() {
    return (
      <>
        {bankPaymentErrorMessage && (
          <div className={styles.achErrorMessageWrapper}>
            <Banner type="error">{bankPaymentErrorMessage}</Banner>
          </div>
        )}
        <BankPaymentSecurityText bankPaymentInfo={bankPaymentInfo} />
      </>
    );
  }

  function renderPaymentBarButton(
    inTabsContext: boolean,
    showSelectBankButton = false,
  ) {
    return (
      <PaymentButtonBar
        superSaveItems={
          isJobberPaymentsCreditCard || isJobberPaymentsAch
            ? undefined
            : superSaveItems
        }
        showSecurityAssurance={showSecurityAssurance}
        onCancel={onDialogCancel}
        onChargeButtonFormat={onChargeButtonFormat}
        onPaymentSubmit={onSubmit}
        onSuperSaveSubmit={onSuperSaveSubmit}
        bankPaymentACHSettings={bankPaymentACHSettings}
        showSelectBankButton={showSelectBankButton}
        setBankPaymentErrorMessage={setBankPaymentErrorMessage}
        attachedToId={attachedToId.toString()}
        attachedToType={attachedToType}
        isQuoteDepositPayment={!!signatureRequired}
        quoteSignatureData={paymentState.signature || ""}
        setBankPaymentAuthorized={setBankPaymentAuthorized}
        setBankPaymentInfoCallback={setBankPaymentInfo}
        inTabsContext={inTabsContext}
        authorizedBankingInfo={authorizedBankingInfo}
        setAuthorizedBankingInfo={setAuthorizedBankingInfo}
        oauthStateId={oauthStateId}
        companyName={companyName}
        isJobberPaymentsAch={isJobberPaymentsAch}
      />
    );
  }

  function onSuperSaveSubmit(superSaveValue: string) {
    onSubmit(undefined, superSaveValue);
  }

  function onSubmit(event?: FormEvent, superSaveValue?: string) {
    if (event && event.preventDefault) event.preventDefault();

    const { status } = paymentState;
    const isSuccessfulACHPayment = event?.type === "achPaymentAccepted";

    if (status === "notInitialized" || status === "inProgress") {
      return;
    }

    if (isJobberPaymentsCreditCard && !isSuccessfulACHPayment) {
      paymentDispatcher({ type: "requestingJobberPaymentCharge" });
    } else if (isJobberPaymentsCreditCard && isSuccessfulACHPayment) {
      paymentDispatcher({
        type: "completingCharge",
        response: { success: true },
      });
    } else {
      const formData = new FormData(formElementRef.current || undefined);

      if (superSaveValue) {
        formData.append("super_save_value", superSaveValue);
      }

      paymentDispatcher({
        type: "requestingRecordCharge",
        formData,
      });
    }
  }

  function defaultChargeButtonFormat(formattedAmount: string) {
    return isJobberPaymentsCreditCard || isJobberPaymentsAch
      ? `Charge ${formattedAmount}`
      : "Save";
  }
}

export function resolveDefaultPaymentType(
  groupedPaymentTypes: GroupedPaymentTypes,
  defaultPaymentMethod?: string,
): string {
  const [, firstPaymentTypeGroup] = groupedPaymentTypes[0];
  const [, firstPaymentTypeId] = firstPaymentTypeGroup[0];

  if (groupedPaymentTypes.length > 1 && defaultPaymentMethod) {
    if (defaultPaymentMethod === "card") {
      return JOBBER_PAYMENTS_TYPE.toString();
    } else if (defaultPaymentMethod === "bank_account") {
      return JOBBER_PAYMENTS_ACH_TYPE.toString();
    }
  }

  return firstPaymentTypeId.toString();
}
