import { useEffect, useRef } from "react";

const SCROLL_TO_COMPONENT_ANCHOR_TIMEOUT = 1200;

export function useScrollToComponentAnchor(
  urlAnchorTag: string,
  timeout: number = SCROLL_TO_COMPONENT_ANCHOR_TIMEOUT,
) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const hasAnchor = window.location.href.includes(urlAnchorTag);

  useEffect(() => {
    if (anchorRef.current && hasAnchor) {
      setTimeout(() => {
        anchorRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }, timeout);
    }
  }, [hasAnchor, timeout]);

  return anchorRef;
}
