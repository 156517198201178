import React from "react";
import { useIntl } from "react-intl";
import { messages } from "jobber/landingPages/messages";
import { LandingPagePlanCard } from "../LandingPagePlanCard";

export function GrowPlanCard() {
  const { formatMessage } = useIntl();
  const planFeatures = [
    formatMessage(messages.growPlanCardPlanFeatures1),
    formatMessage(messages.growPlanCardPlanFeatures2),
    formatMessage(messages.growPlanCardPlanFeatures3),
    formatMessage(messages.growPlanCardPlanFeatures4),
  ];

  return (
    <div
      data-testid="growPlanCard"
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <LandingPagePlanCard
        cardHeading={formatMessage(messages.growPlanCardHeading)}
        cardDescription={formatMessage(messages.growPlanCardDescription)}
        featuresHeading={formatMessage(messages.growPlanCardFeaturesHeading)}
        planFeatures={planFeatures}
      />
    </div>
  );
}
