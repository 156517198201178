import { gql } from "@apollo/client";

export const QUOTE_FOLLOW_UP_MAX_DAYS_QUERY = gql`
  query QuoteFollowUpMaxDays {
    feature(key: "Automations") {
      available
      enabled
    }
    clientNotification(id: QUOTE_FOLLOWUP, includeUpgradeFeatures: true) {
      enabled
      schedules {
        nodes {
          offset {
            value
          }
        }
      }
    }
    automationRules {
      nodes {
        trigger
        active
      }
    }
  }
`;
