import React, { useEffect, useState } from "react";
import { Banner, Page, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { getDefaultAutomationRule } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/defaultAutomationRule";
import { isAutomatedCampaignType } from "jobber/campaigns/utils";
import type {
  ClientSegmentFragment,
  CommsCampaignQuery,
} from "~/utilities/API/graphql";
import { Template } from "~/utilities/API/graphql";
import type {
  AddConditionAction,
  LoadConditionsAction,
  RemoveConditionAction,
  UpdateConditionAction,
} from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/RuleBuilderReducer";
import type { AllConditionTypes } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import { SetAutomationRules } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/SetAutomationRules";
import { useQuoteFollowUpMaxDaysCalculator } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useQuoteFollowUpMaxDaysCalculator/useQuoteFollowUpMaxDaysCalculator";
import { ClientSegmentInternals } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/ClientSegmentInternals";
import { messages } from "./messages";
import styles from "./SelectClientSegment.module.css";

interface SelectClientSegmentAutomationRuleProps {
  automationConditions: AllConditionTypes[];
  automationDispatch: React.Dispatch<
    | AddConditionAction
    | UpdateConditionAction
    | RemoveConditionAction
    | LoadConditionsAction
  >;
  triggerArguments: number;
  setTriggerArguments: (val: number) => void;
  automationRuleErrors: string[] | null;
  clearError: () => void;
}

interface SelectClientSegmentProps {
  clientSegment?: ClientSegmentFragment;
  campaignTemplateType?: Template;
  campaign?: CommsCampaignQuery["commsCampaign"];
  loading: boolean;
  setClientSegment: (clientSegment?: ClientSegmentFragment) => void;
  automationProps: SelectClientSegmentAutomationRuleProps;
}

export function SelectClientSegment({
  campaignTemplateType,
  campaign,
  loading,
  automationProps: {
    automationConditions,
    automationDispatch,
    triggerArguments,
    setTriggerArguments,
    automationRuleErrors,
    clearError,
  },
}: SelectClientSegmentProps): JSX.Element {
  const { formatMessage } = useIntl();

  // Quote Follow Up Banner -------
  const [showQuoteFollowUpBanner, setShowQuoteFollowUpBanner] = useState(false);
  const { maxQuoteFollowUpSentDays, redirectUrl } =
    useQuoteFollowUpMaxDaysCalculator();

  useEffect(() => {
    if (
      maxQuoteFollowUpSentDays &&
      redirectUrl &&
      campaignTemplateType === Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES
    ) {
      setShowQuoteFollowUpBanner(true);
    }
  }, [maxQuoteFollowUpSentDays, redirectUrl, campaignTemplateType]);
  // Quote Follow Up Banner -------

  const enableAutomation = isAutomatedCampaignType(
    campaignTemplateType as Template,
    campaign?.isAutomated,
  );

  const pageTitle = enableAutomation
    ? formatMessage(messages.automationsTitle)
    : formatMessage(messages.clientSegmentPageTitle);

  const defaultAutomationRule = getDefaultAutomationRule(
    campaignTemplateType as Template,
  );

  return (
    <Page title={pageTitle} width="narrow">
      {showQuoteFollowUpBanner && (
        <div className={styles.quoteFollowUpBannerContainer}>
          <Banner
            type="warning"
            primaryAction={{
              label: formatMessage(messages.quoteFollowUpBannerCTALabel),
              url: redirectUrl,
            }}
            dismissible={true}
            onDismiss={() => setShowQuoteFollowUpBanner(false)}
          >
            <Text>
              {formatMessage(messages.quoteFollowUpBannerMessage, {
                maxQuoteFollowUpSentDays,
              })}
            </Text>
          </Banner>
        </div>
      )}
      {enableAutomation && defaultAutomationRule ? (
        <SetAutomationRules
          automationRule={defaultAutomationRule}
          conditions={automationConditions}
          dispatch={automationDispatch}
          triggerArguments={triggerArguments}
          setTriggerArguments={setTriggerArguments}
          errors={automationRuleErrors}
          clearError={clearError}
        />
      ) : (
        <ClientSegmentInternals loading={loading} />
      )}
    </Page>
  );
}
