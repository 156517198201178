import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { useStatusOptions } from "jobber/reviews/views/ReviewsPage/hooks/useStatusOptions";
import { useFilterState } from "jobber/reviews/views/ReviewsPage/hooks/useFilterState";
import { useReviewsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useReviewsQuery";
import { ReviewsSettingsDrawerContextProvider } from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { ReviewsPage } from "./ReviewsPage";
import { ReviewQueryParams } from "./types";

export function ReviewsPageLoader() {
  return (
    <IntlProvider>
      <APIProvider>
        <ReviewsSettingsDrawerContextProvider>
          <ReviewsPageLoaderInternal />
        </ReviewsSettingsDrawerContextProvider>
      </APIProvider>
    </IntlProvider>
  );
}

function ReviewsPageLoaderInternal() {
  const filterQueryParam = new URLSearchParams(location.search).get(
    ReviewQueryParams.filter,
  );
  const { defaultStatus } = useStatusOptions(filterQueryParam);
  const {
    filterState: { status },
    setStatus,
    filtered,
  } = useFilterState({ defaultStatus });
  const { data, loading, error } = useReviewsQuery({
    statusFilter: status[0].id,
  });
  return (
    <ReviewsPage
      data={data}
      loading={loading}
      error={error}
      setStatus={setStatus}
      filtered={filtered}
      status={status}
      defaultStatus={defaultStatus}
    />
  );
}
