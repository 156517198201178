import type { ProviderProps } from "react";
import type { CallToActionContextType } from "./CallToActionContext";
import { CallToActionContext } from "./CallToActionContext";

export function CallToActionContextProvider({
  value,
  children,
}: ProviderProps<CallToActionContextType>) {
  return (
    <CallToActionContext.Provider
      value={{
        ctas: value?.ctas,
        updateCtaStatus: (key, updatedValue) =>
          (value.ctas[key] = updatedValue),
      }}
    >
      {children}
    </CallToActionContext.Provider>
  );
}
