import React, { useCallback } from "react";
import { Button } from "@jobber/components/Button";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import { SplitNames, useSplit } from "utilities/split";
import { useOpenSupportConversations } from "jobber/helpDrawer/hooks/useOpenSupportConversations";
import { Intercom } from "utilities/chat";
import { trackAmplitudeEvent } from "jobber/helpDrawer/amplitudeEvent";
import { OPEN_ASSISTANT_FROM_SUPPORT_CHAT } from "jobber/jobberAssistant/constants";

const showCopilot = () =>
  window.dispatchEvent(
    new CustomEvent(OPEN_ASSISTANT_FROM_SUPPORT_CHAT, {
      detail: { source: "support-chat" },
    }),
  );

export const ChatWithSupportButton = ({
  searchTerm,
  section,
}: {
  searchTerm: string;
  section: string;
}) => {
  const { getTreatmentValue, isReady } = useSplit({
    names: [
      SplitNames.JobberCopilotSupportBot,
      SplitNames.JobberCopilotNonAdminSupportBot,
    ],
  });
  const splitLoading = !isReady;

  const { currentUser, loading: currentUserLoading } = useCurrentUser([
    "isAdmin",
  ]);

  const shouldUseCopilot =
    (getTreatmentValue(SplitNames.JobberCopilotSupportBot) &&
      !!currentUser?.isAdmin) ||
    getTreatmentValue(SplitNames.JobberCopilotNonAdminSupportBot);

  const { loading: openSupportConversationsLoading, intercomConversationIds } =
    useOpenSupportConversations();

  const trackClickWithIntercom = useCallback(() => {
    trackAmplitudeEvent({
      eventName: "Clicked Start a Chat",
      eventProperties: {
        source: "global_chat_trigger",
      },
      searchTerm: searchTerm,
      section: section,
    });
  }, [searchTerm, section]);

  const onClick = useCallback(async () => {
    const conversationIds = await intercomConversationIds();

    if (conversationIds.length === 0 && shouldUseCopilot) {
      showCopilot();
      return;
    }

    trackClickWithIntercom();

    if (conversationIds.length === 0) {
      Intercom.EXECUTE("show");
    } else if (conversationIds.length === 1) {
      Intercom.EXECUTE("showConversation", conversationIds[0]);
    } else {
      Intercom.EXECUTE("showMessages");
    }
  }, [intercomConversationIds, shouldUseCopilot, trackClickWithIntercom]);

  return (
    <Button
      label="Chat With Us"
      icon="chat"
      type="primary"
      fullWidth={true}
      onClick={onClick}
      disabled={splitLoading || currentUserLoading}
      loading={openSupportConversationsLoading}
    />
  );
};
