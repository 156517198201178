import { IntervalUnits, Segment } from "~/utilities/API/graphql";
import type {
  AllClientsCriteriaInput,
  ClientTagCriteriaInput,
  ClientTypeCriteriaInput,
  Criteria,
  CriteriaEdge,
  GetCommsClientsSegmentQueryVariables,
  JobStatusCriteriaInput,
  JobTypeCriteriaInput,
  LineItemCriteriaInput,
  PastClientsCriteriaInput,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/types";
import {
  DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
} from "../constants";

export type ClientsCriteriaFromSegment =
  | PastClientsCriteriaInput
  | AllClientsCriteriaInput
  | UpcomingClientsCriteriaInput
  | undefined;

interface GetClientsCriteriaFromSegmentProps {
  clientSegmentType?: Segment;
  criteria?: Criteria[] | CriteriaEdge[];
  additionalCriteria?: AdditionalCriteriaUnion[];
}

export function getClientsCriteriaFromSegment({
  clientSegmentType,
  criteria,
  additionalCriteria,
}: GetClientsCriteriaFromSegmentProps): ClientsCriteriaFromSegment | undefined {
  if (!clientSegmentType) {
    return getPastOrUpcomingClientsCriteriaFromSegment([]);
  }

  const _additionalCriteria = parseAdditionalCriteria(additionalCriteria || []);

  if (clientSegmentType === Segment.ALL_CLIENTS) {
    return {
      ...getAllClientsCriteriaFromSegment(),
      clientTypeCriteria: _additionalCriteria.clientTypeCriteria,
      clientTagCriteria: _additionalCriteria.clientTagCriteria,
    };
  }

  if (clientSegmentType === Segment.PAST_CLIENTS) {
    return {
      ...getPastOrUpcomingClientsCriteriaFromSegment(criteria),
      clientTagCriteria: _additionalCriteria.clientTagCriteria,
      jobTypeCriteria: _additionalCriteria.jobTypeCriteria,
      lineItemCriteria: _additionalCriteria.lineItemCriteria,
    };
  }

  if (clientSegmentType === Segment.UPCOMING_CLIENTS) {
    return {
      ...getPastOrUpcomingClientsCriteriaFromSegment(
        criteria,
        DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
      ),
      jobStatusCriteria: _additionalCriteria.jobStatusCriteria,
      jobTypeCriteria: _additionalCriteria.jobTypeCriteria,
      clientTagCriteria: _additionalCriteria.clientTagCriteria,
      lineItemCriteria: _additionalCriteria.lineItemCriteria,
    };
  }
}

function getAllClientsCriteriaFromSegment(): AllClientsCriteriaInput {
  return {};
}

export function parseAdditionalCriteria(criteria: AdditionalCriteriaUnion[]) {
  const criteriaTypes: {
    clientTagCriteria: ClientTagCriteriaInput[];
    jobStatusCriteria: JobStatusCriteriaInput[];
    jobTypeCriteria: JobTypeCriteriaInput[];
    lineItemCriteria: LineItemCriteriaInput[];
    clientTypeCriteria: ClientTypeCriteriaInput[];
  } = {
    clientTagCriteria: [],
    jobStatusCriteria: [],
    jobTypeCriteria: [],
    lineItemCriteria: [],
    clientTypeCriteria: [],
  };
  // eslint-disable-next-line max-statements
  criteria.forEach(criterion => {
    switch (criterion.__typename) {
      case "ClientTagCriteria": {
        if (!criterion.clientTagValue) return;
        criteriaTypes.clientTagCriteria.push({
          index: criterion.index,
          operator: criterion.operator,
          value: criterion.clientTagValue,
        });
        break;
      }
      case "ClientTypeCriteria": {
        if (!criterion.clientTypeValue) return;
        criteriaTypes.clientTypeCriteria.push({
          index: criterion.index,
          value: criterion.clientTypeValue,
        });
        break;
      }
      case "JobStatusCriteria": {
        criteriaTypes.jobStatusCriteria.push({ index: criterion.index });
        break;
      }
      case "JobTypeCriteria": {
        if (!criterion.jobTypeValue) return;
        criteriaTypes.jobTypeCriteria.push({
          index: criterion.index,
          value: criterion.jobTypeValue,
        });
        break;
      }
      case "LineItemCriteria": {
        if (!criterion.lineItemValue?.id) return;
        criteriaTypes.lineItemCriteria.push({
          index: criterion.index,
          operator: criterion.operator,
          value: criterion.lineItemValue.id,
        });
        break;
      }
    }
  });
  return criteriaTypes;
}

export function getPastOrUpcomingClientsCriteriaFromSegment(
  criteria: Criteria[] | CriteriaEdge[] = [],
  defaultResponse = DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
): PastClientsCriteriaInput | UpcomingClientsCriteriaInput {
  if (criteria.length === 0) {
    return defaultResponse;
  }

  if (criteria[0].__typename === "CriteriaEdge") {
    return (criteria as CriteriaEdge[]).reduce<PastClientsCriteriaInput>(
      (acc: PastClientsCriteriaInput, c: CriteriaEdge) => {
        const node = c.node;
        const value =
          node.name === "unit"
            ? getCriteriaUnitFromNode(node)
            : parseInt(node.value || "0", 10);
        const name = node.name === "unit" ? "unit" : "interval";
        return {
          ...acc,
          [name]: value,
        };
      },
      {} as PastClientsCriteriaInput,
    );
  } else if (criteria[0].__typename === "Criteria") {
    return (criteria as Criteria[]).reduce(
      (acc: PastClientsCriteriaInput, node: Criteria) => {
        const value =
          node.name === "unit"
            ? getCriteriaUnitFromNode(node)
            : parseInt(node.value || "0", 10);
        const name = node.name === "unit" ? "unit" : "interval";

        return {
          ...acc,
          [name]: value,
        };
      },
      {} as PastClientsCriteriaInput,
    );
  }
  return defaultResponse;
}

function getCriteriaUnitFromNode(node: Criteria): IntervalUnits | undefined {
  switch (node.value) {
    case "days":
      return IntervalUnits.DAYS;
    case "weeks":
      return IntervalUnits.WEEKS;
    case "months":
      return IntervalUnits.MONTHS;
    case "years":
      return IntervalUnits.YEARS;
  }
}

export function getSegmentCriteriasFromSegments({
  segmentCriterias,
  segmentType,
}: {
  segmentCriterias?: {
    allClientsSegmentCriteria: ClientsCriteriaFromSegment;
    pastClientsSegmentCriteria: ClientsCriteriaFromSegment;
    upcomingClientsSegmentCriteria: UpcomingClientsCriteriaInput;
  };
  segmentType?: Segment;
}): GetCommsClientsSegmentQueryVariables {
  if (!segmentCriterias || !segmentType) {
    return {};
  }

  const pastClientLineItems = isPastClientCriteriaInput(
    segmentCriterias.pastClientsSegmentCriteria,
  )
    ? segmentCriterias.pastClientsSegmentCriteria.lineItemCriteria || []
    : [];

  const pastClientJobType = isPastClientCriteriaInput(
    segmentCriterias.pastClientsSegmentCriteria,
  )
    ? segmentCriterias.pastClientsSegmentCriteria.jobTypeCriteria || []
    : [];

  const upcomingClientJobType = isUpcomingClientCriteriaInput(
    segmentCriterias.upcomingClientsSegmentCriteria,
  )
    ? segmentCriterias.upcomingClientsSegmentCriteria.jobTypeCriteria || []
    : [];

  const upcomingClientJobStatus = isUpcomingClientCriteriaInput(
    segmentCriterias.upcomingClientsSegmentCriteria,
  )
    ? segmentCriterias.upcomingClientsSegmentCriteria.jobStatusCriteria || []
    : [];

  const allClientTypeCriteriaType = isAllClientsCriteriaInput(
    segmentCriterias.allClientsSegmentCriteria,
  )
    ? segmentCriterias.allClientsSegmentCriteria.clientTypeCriteria || []
    : [];

  const allClientLineItems = isAllClientsCriteriaInput(
    segmentCriterias.allClientsSegmentCriteria,
  )
    ? segmentCriterias.allClientsSegmentCriteria.lineItemCriteria || []
    : [];

  const allClientJobStatus = isAllClientsCriteriaInput(
    segmentCriterias.allClientsSegmentCriteria,
  )
    ? segmentCriterias.allClientsSegmentCriteria.jobStatusCriteria || []
    : [];

  const allClientJobType = isAllClientsCriteriaInput(
    segmentCriterias.allClientsSegmentCriteria,
  )
    ? segmentCriterias.allClientsSegmentCriteria.jobTypeCriteria || []
    : [];

  return {
    ...segmentCriterias.allClientsSegmentCriteria,
    ...segmentCriterias.pastClientsSegmentCriteria,
    allClientTagCriteria:
      segmentCriterias?.allClientsSegmentCriteria?.clientTagCriteria || [],
    allClientTypeCriteria: allClientTypeCriteriaType,
    allClientLineItemCriteria: allClientLineItems,
    allClientJobStatusCriteria: allClientJobStatus,
    allClientJobTypeCriteria: allClientJobType,
    pastClientTagCriteria:
      segmentCriterias.pastClientsSegmentCriteria?.clientTagCriteria || [],
    pastClientLineItemCriteria: pastClientLineItems,
    upcomingClientTagCriteria:
      segmentCriterias.upcomingClientsSegmentCriteria?.clientTagCriteria || [],
    upcomingClientLineItemCriteria:
      segmentCriterias.upcomingClientsSegmentCriteria?.lineItemCriteria || [],
    upcomingClientInterval:
      segmentCriterias.upcomingClientsSegmentCriteria?.interval,
    upcomingClientUnit: segmentCriterias.upcomingClientsSegmentCriteria?.unit,
    pastClientJobTypeCriteria: pastClientJobType,
    upcomingClientJobTypeCriteria: upcomingClientJobType,
    upcomingClientJobStatusCriteria: upcomingClientJobStatus,
  };
}

export function isPastClientCriteriaInput(
  criteria: ClientsCriteriaFromSegment,
): criteria is PastClientsCriteriaInput {
  return !!criteria && "interval" in criteria && "unit" in criteria;
}
// Because upcoming and past client criteria both use interval and
// unit I do this to make sure the function name makes sense.
// If the criteria for these types changes we can update the respective functions
export function isUpcomingClientCriteriaInput(
  criteria: ClientsCriteriaFromSegment,
): criteria is UpcomingClientsCriteriaInput {
  return isPastClientCriteriaInput(criteria);
}

export function isAllClientsCriteriaInput(
  criteria: ClientsCriteriaFromSegment,
): criteria is AllClientsCriteriaInput {
  return !!criteria;
}
