import { PAYMENT_DISPUTES_QUERY } from "./PaymentDisputes.graphql";

export const errorLoadQuery = {
  request: {
    query: PAYMENT_DISPUTES_QUERY,
    variables: {
      key: "STATUS",
      direction: "ASCENDING",
      first: 10,
      after: "MA==",
    },
  },
  error: new Error("Some error message"),
};

export const quoteLoadQuery = {
  request: {
    query: PAYMENT_DISPUTES_QUERY,
    variables: {
      key: "STATUS",
      direction: "ASCENDING",
      first: 10,
      after: "MA==",
    },
  },
  result: {
    data: {
      jobberPaymentsDisputes: {
        pageInfo: {
          endCursor: "MTI",
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: "MQ",
        },
        totalCount: 11,
        edges: [
          {
            node: {
              amount: 131,
              category: "UNKNOWN",
              disputedAt: "2023-05-15T19:05:25Z",
              evidenceDueBy: "2023-05-15T19:05:25Z",
              id: "OQ==",
              paymentRecord: {
                invoice: null,
                client: {
                  name: "John Smith",
                },
                quote: {
                  quoteNumber: "345",
                },
              },
              reason: "UNKNOWN_REASON",
              status: "UNKNOWN",
            },
            cursor: "MA",
          },
        ],
      },
    },
  },
};

export const noQuoteOrInvoiceLoadQuery = {
  request: {
    query: PAYMENT_DISPUTES_QUERY,
    variables: {
      key: "STATUS",
      direction: "ASCENDING",
      first: 10,
      after: "MA==",
    },
  },
  result: {
    data: {
      jobberPaymentsDisputes: {
        pageInfo: {
          endCursor: "MTI",
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: "MQ",
        },
        totalCount: 1,
        edges: [
          {
            node: {
              amount: 131,
              category: "UNKNOWN",
              disputedAt: "2023-05-15T19:05:25Z",
              evidenceDueBy: "2023-05-15T19:05:25Z",
              id: "OQ==",
              paymentRecord: {
                invoice: null,
                client: {
                  name: "John Smith",
                },
                quote: null,
              },
              reason: "UNKNOWN_REASON",
              status: "UNKNOWN",
            },
            cursor: "MA",
          },
        ],
      },
    },
  },
};
export const emptyLoadQuery = {
  request: {
    query: PAYMENT_DISPUTES_QUERY,
    variables: {
      key: "STATUS",
      direction: "ASCENDING",
      first: 10,
      after: "MA==",
    },
  },
  result: {
    data: {
      jobberPaymentsDisputes: {
        pageInfo: {
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: "null",
        },
        totalCount: 0,
        edges: [],
      },
    },
  },
};

export const firstLoadQuery = {
  request: {
    query: PAYMENT_DISPUTES_QUERY,
    variables: {
      key: "STATUS",
      direction: "ASCENDING",
      first: 10,
      after: "MA==",
    },
  },
  result: {
    data: {
      jobberPaymentsDisputes: {
        pageInfo: {
          endCursor: "MTI",
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: "MQ",
        },
        totalCount: 4,
        edges: [
          {
            node: {
              amount: 100,
              category: "INQUIRY",
              disputedAt: "2023-04-24T19:39:10Z",
              evidenceDueBy: "2023-05-01T19:39:00Z",
              id: "Mg==",
              paymentRecord: {
                invoice: {
                  invoiceNumber: "3",
                },
                client: {
                  name: "John Doe",
                },
                quote: null,
              },
              reason: "DUPLICATE",
              status: "NEEDS_RESPONSE",
            },
            cursor: "MQ",
          },
          {
            node: {
              amount: 429.76,
              category: "CHARGEBACK",
              disputedAt: "2023-05-15T19:02:38Z",
              evidenceDueBy: "2023-05-15T19:02:38Z",
              id: "Nw==",
              paymentRecord: {
                invoice: {
                  invoiceNumber: "622",
                },
                client: {
                  name: "Shayne Schowalter",
                },
                quote: null,
              },
              reason: "PRODUCT_UNACCEPTABLE",
              status: "NEEDS_RESPONSE",
            },
            cursor: "Mg",
          },
          {
            node: {
              amount: 23.45,
              category: "CHARGEBACK",
              disputedAt: "2023-05-15T19:02:38Z",
              evidenceDueBy: "2023-05-15T19:02:38Z",
              id: "OA==",
              paymentRecord: {
                invoice: {
                  invoiceNumber: "623",
                },
                client: {
                  name: "Marilyn Stiedemann",
                },
                quote: null,
              },
              reason: "PRODUCT_UNACCEPTABLE",
              status: "NEEDS_RESPONSE",
            },
            cursor: "Mw",
          },
          {
            node: {
              amount: 31.45,
              category: "INQUIRY",
              disputedAt: "2023-05-15T19:05:25Z",
              evidenceDueBy: "2023-05-15T19:05:25Z",
              id: "OQ==",
              paymentRecord: {
                invoice: {
                  invoiceNumber: "624",
                },
                client: {
                  name: "Earl Labadie",
                },
                quote: null,
              },
              reason: "FRAUDULENT",
              status: "NEEDS_RESPONSE",
            },
            cursor: "MA",
          },
        ],
      },
    },
  },
};

export const noClientQuery = {
  request: {
    query: PAYMENT_DISPUTES_QUERY,
    variables: {
      key: "STATUS",
      direction: "ASCENDING",
      first: 10,
      after: "MA==",
    },
  },
  result: {
    data: {
      jobberPaymentsDisputes: {
        pageInfo: {
          endCursor: "MTI",
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: "MQ",
        },
        totalCount: 1,
        edges: [
          {
            node: {
              amount: 131,
              category: "CHARGEBACK",
              disputedAt: "2023-05-15T19:05:25Z",
              evidenceDueBy: "2023-05-15T19:05:25Z",
              id: "OQ==",
              paymentRecord: {
                invoice: null,
                client: undefined,
                quote: null,
              },
              reason: "UNKNOWN_REASON",
              status: "UNKNOWN",
            },
            cursor: "MA",
          },
        ],
      },
    },
  },
};
