import {
  Card,
  Content,
  Flex,
  Glimmer,
  Heading,
  Icon,
  Text,
} from "@jobber/components";
import { useIntl } from "react-intl";
import { InsightWidgetsLoader } from "~/jobber/insightWidgets/InsightWidgetsLoader";
import type { IndustryComparison } from "~/utilities/API/graphql";
import {
  AiAssistantInsightWidgetCategory,
  Industry,
} from "~/utilities/API/graphql";
import styles from "./ReviewsEngagement.module.css";
import { messages } from "./messages";

interface ReviewsEngagementProps {
  accountNumReviews: number;
  industryComparison: IndustryComparison | undefined;
  isLoading: boolean;
}
export function ReviewsEngagement({
  accountNumReviews,
  industryComparison,
  isLoading,
}: ReviewsEngagementProps) {
  const { formatMessage } = useIntl();
  const renderComparison = (industryNumReviews: number) => {
    if (accountNumReviews === industryNumReviews) {
      return <>{formatMessage(messages.equalReviews)}</>;
    } else if (accountNumReviews > industryNumReviews) {
      return (
        <>
          <Icon name="longArrowUp" color="green" size="small" />
          {accountNumReviews - industryNumReviews} more
        </>
      );
    } else {
      return (
        <>
          <Icon name="longArrowDown" color="red" size="small" />
          {industryNumReviews - accountNumReviews} less
        </>
      );
    }
  };
  if (isLoading || !industryComparison) return <Glimmer />;
  const industry =
    industryComparison?.industry === Industry.OTHER
      ? "industry average"
      : `${industryComparison.industryName} industry average`;
  return (
    <div className={styles.cardOverride}>
      <Card>
        <Content>
          <Flex direction="row" template={["grow", "shrink"]}>
            <Flex
              direction="column"
              template={["grow", "grow"]}
              gap="small"
              align="center"
            >
              <Heading level={4}>
                {formatMessage(messages.titleStart)}
                {renderComparison(industryComparison?.industryAvgTotal)}
                {formatMessage(messages.titleEnd, {
                  equal:
                    accountNumReviews === industryComparison.industryAvgTotal
                      ? "as"
                      : "than",
                  industry: industry,
                })}
              </Heading>
              <Text size="small" variation="subdued">
                {renderComparison(industryComparison.industryLeaderTotal)}
                {formatMessage(messages.subtitle)}
              </Text>
            </Flex>
            <div className={styles.widget}>
              <InsightWidgetsLoader
                categories={[AiAssistantInsightWidgetCategory.MARKETING]}
                insideReviewsEngagement
              ></InsightWidgetsLoader>
            </div>
          </Flex>
        </Content>
      </Card>
    </div>
  );
}
