import { useCallback, useMemo, useState } from "react";
import { useCampaignCreateTemplatesMutation } from "jobber/campaigns/hooks/useCampaignCreateTemplatesMutation";
import { useCommsCampaignDefaultTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignDefaultTemplateQuery";
import { useEditAutomationRuleMutation } from "jobber/campaigns/hooks/useEditAutomationRuleMutation/useEditAutomationRuleMutation";
import type { CommsCampaignQuery } from "~/utilities/API/graphql";
import { CampaignTemplateCtaLinkTo, Template } from "~/utilities/API/graphql";
import { getDefaultAutomationRule } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/defaultAutomationRule";
import { parseConditions } from "jobber/campaigns/utils/automationRuleUtils";

// eslint-disable-next-line max-statements
export function useConfirmTemplateChange({
  campaign,
  selectedTemplate = Template.RE_ENGAGE,
  onNext,
}: {
  campaign?: CommsCampaignQuery["commsCampaign"];
  selectedTemplate?: Template;
  onNext: (nextSelectedTemplate?: Template) => void;
}) {
  const initialCampaignTemplate = useMemo(
    () => campaign?.templates?.nodes?.[0],
    [campaign],
  );
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [createTemplateError, setCreateTemplateError] =
    useState<boolean>(false);
  const openConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const closeConfirmationModal = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const shouldConfirmTemplateChange = useCallback(
    (nextSelectedTemplate: Template) =>
      initialCampaignTemplate &&
      initialCampaignTemplate.type !== nextSelectedTemplate,
    [initialCampaignTemplate],
  );

  const handleShowConfirmation = useCallback(
    (nextSelectedTemplate: Template) => {
      if (shouldConfirmTemplateChange(nextSelectedTemplate)) {
        openConfirmationModal();
      } else {
        onNext(nextSelectedTemplate);
      }
    },
    [shouldConfirmTemplateChange, openConfirmationModal, onNext],
  );

  const {
    refetch,
    loading: loadingFetchDefaultTemplate,
    error: defaultTemplateError,
  } = useCommsCampaignDefaultTemplateQuery({
    skip: true,
    defaultTemplateType: selectedTemplate,
  });
  const {
    campaignCreateTemplates,
    loading: loadingCreateTemplate,
    error: campaignCreateTemplatesError,
  } = useCampaignCreateTemplatesMutation();

  const { editAutomationRule } = useEditAutomationRuleMutation();

  // eslint-disable-next-line max-statements
  const handleOnConfirm = useCallback(async () => {
    closeConfirmationModal();
    try {
      const { data: defaultTemplate } = await refetch();

      const {
        body = "",
        subject = "",
        header = "",
        showLogo = true,
        ctaVisible = false,
        ctaLinkTo = "",
      } = defaultTemplate.commsCampaignDefaultTemplate;

      if (!campaign || !body || !subject || !initialCampaignTemplate) {
        setCreateTemplateError(true);
        return;
      }
      /**
       * Reset the automation rule to the default automation rule for the selected template type if campaign is automated.
       */
      const defaultAutomationRule = getDefaultAutomationRule(selectedTemplate);
      if (
        campaign &&
        campaign?.isAutomated &&
        campaign.automationRule &&
        defaultAutomationRule
      ) {
        await editAutomationRule({
          variables: {
            ruleId: campaign.automationRule.id, // this should never be undefined
            trigger: {
              task: defaultAutomationRule?.trigger.task,
              arguments: {
                ...defaultAutomationRule?.trigger.arguments,
                /* eslint-disable @typescript-eslint/naming-convention */
                model_id: campaign?.automationRule?.trigger.arguments.model_id,
                /* eslint-enable @typescript-eslint/naming-convention */
              },
            },
            condition: {
              task: "condition",
              conditions: parseConditions(
                defaultAutomationRule?.condition.conditions,
              ),
            },
            action: defaultAutomationRule?.action,
            name: defaultAutomationRule?.name,
          },
          onCompleted: mutationResult => {
            if (mutationResult?.automationRuleEdit.userErrors.length > 0) {
              setCreateTemplateError(true);
              return;
            }
          },
          onError: () => {
            setCreateTemplateError(true);
            return;
          },
        });
      }

      const results = await campaignCreateTemplates({
        input: {
          campaignId: campaign?.id,
          templates: [
            {
              body,
              subject,
              header,
              showLogo,
              // external links will not have a url when this save occurs. To avoid validation errors,
              // the visibility of the button is toggled off.
              ctaVisible:
                ctaLinkTo === CampaignTemplateCtaLinkTo.EXTERNAL_LINK
                  ? false
                  : ctaVisible,
              type: selectedTemplate,
            },
          ],
        },
      });
      if (results?.userErrors?.length > 0) {
        setCreateTemplateError(true);
      } else {
        onNext();
      }
    } catch {
      setCreateTemplateError(true);
    }
  }, [
    closeConfirmationModal,
    refetch,
    campaign,
    initialCampaignTemplate,
    selectedTemplate,
    campaignCreateTemplates,
    editAutomationRule,
    onNext,
  ]);
  return {
    loadingChangeTemplate: loadingFetchDefaultTemplate || loadingCreateTemplate,
    error:
      Boolean(defaultTemplateError) ||
      createTemplateError ||
      Boolean(campaignCreateTemplatesError),
    confirmationModalOpen,
    handleOnConfirm,
    handleShowConfirmation,
    openConfirmationModal,
    closeConfirmationModal,
  };
}
