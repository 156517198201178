// Jobber Feature Flagging flag names
// Add here the new feature flag names that will correspond to the split.io dashboard
// Alphabetical order, and please follow the format below for easy traceability
// label = "tag" // owner: Team name or channel, JOB-XXXX (for feature flag cleanup ticket)
export enum SplitNames {
  AchManualEntryEnabled = "ach_manual_entry_enabled", // owner: Invoicing
  AddonCancellation = "addon_cancellation", // owner: Subscription Infrastructure, BIZ-48187
  CommsDeleteSmsConversationOnline = "comms_delete_sms_conversation_online", // owner: Comms Systems
  CommsReassignSmsConversationOnline = "comms_reassign_sms_conversation_online", // owner: Comms Systems
  CommsEmailMarketingImageUpload = "comms_email_marketing_image_upload", // owner: Comms Systems
  CommsGBDedicatedPhoneNumber = "comms_gb_dedicated_phone_number", // owner: Comms Systems
  CustomLeadAttribution = "custom_lead_attribution", // owner: SC-Intake, JOB-110115
  DataVizDevJobs = "data_viz_dev_jobs", // owner: Workday
  GmpAchAutopay = "gmp_ach_autopay", // owner: Invoicing
  GoogleCompanyAutocomplete = "google_company_autocomplete", // owner: Onboarding
  JobsImportGCal = "jobs_import_gcal", // owner: Retention, JOB-103630
  MultipleReplyTo = "comms_reply_to_multiple_email", // owner: Comms Experience
  SetupWizardWebsiteField = "setup_wizard_website_field", // owner: Onboarding

  QuoteDepositShownOnJob = "quote_deposit_shown_on_job", // owner: Bookkeeping
  QuoteProposalsLab = "quote_proposals_lab", // owner: Quotemeal, JOB-97744
  SchedulingRecommendations = "scheduling_recommendations", // owner: Work Setup
  SolePropNew = "comms_sole_prop_new", // owner: Comms Experience
  SolePropUpgrade = "comms_sole_prop_upgrade", // owner: Comms Experience
  WalletAchManualEntry = "wallet_ach_manual_entry", // owner: Invoicing
  OnlinePaymentsDefaultUpdate = "online_payments_default_update", // owner: Invoicing
  AchExceededLimits = "ach_exceeded_limits", // owner: Invoicing
  MandatoryPaymentMethodOnFile = "mandatory_payment_method_on_file", // owner: Invoicing
  ProgressInvoiceCreate = "progress_invoice_create", // owner: Bookkeeping
  ExpansionHibernateCancelOnline = "expansion_hibernate_cancel_online", // owner: Expansion, JOB-106208
  ExpansionUpgradeWave = "expansion_upgrade_wave", // owner: Expansion, JOB-108879
  ExpansionSSDowngradesOnline = "expansion_ss_downgrades_online", // owner: Expansion, JOB-109850
  ExpansionPlusPlanOnline = "expansion_plus_plan_online", // owner: Expansion, JOB-113477
  ReactScheduleView = "react_schedule_view", // owner: Scheduling
  NewScheduleMapView = "new_schedule_map_view_online", // owner: Scheduling
  SchedulingAssistant = "scheduling_assistant_online", // owner: Scheduling

  AIRVoice = "virtual_receptionist_voice_modality", // owner: AI Receptionist
  AIROnlineBooking = "ai_receptionist_olb", // owner: AI Receptionist
  AIRSpace = "airspace_online", // owner: AI Receptionist
  MicroSurveyFeedback = "ia_micro_survey", // owner: Jobber IA (Money)
  PartialPayments = "partial_payments", // owner: Bookkeeping
  ReferralsDashboardWip = "referrals_dashboard_wip", // owner: SC Engagement
  JobberCopilotSupportBot = "jobber_copilot_support_bot", // owner: Jobber Copilot
  JobberCopilotNonAdminSupportBot = "jobber_copilot_non_admin_support_bot", // owner: Jobber Copilot
  WebsiteImageGallery = "website_image_gallery", // owner: Websites
  CheckoutBanner = "checkout_ms_experiment_desktop", // owner: CobraKai
  CheckoutCondensedJobberOnline = "checkout_condensed_jobber_online", // owner: CobraKai, JOB-110870
  ReviewAttributionsWIP = "review_attributions_wip", // owner: SC Engagement
  CreateCampaignFromQuickCreateExperiment = "create_campaign_from_quick_create_experiment", // owner: CobraKai
  ClientBalanceReport = "ia_client_balances_report", // owner: Jobber IA (Money), JOB-111362
  WebsiteCustomDomains = "website_custom_domains", // owner: Websites
  ClientBalanceReportAddons = "ia_client_balance_report_addons", // owner: Jobber IA (Money), JOB-111975
  JobberCapitalParafin = "jobber_capital_parafin", // owner: JobberPayments
}
