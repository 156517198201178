import React from "react";
import { useIntl } from "react-intl";
import {
  Content,
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
  Tooltip,
} from "@jobber/components";
import { useAverageDaysMetrics } from "~/jobber/invoices/views/InvoicesIndexPage/components/InvoicesKPISection/utils/useAverageDaysMetrics";
import { messages as invoiceMessages } from "~/jobber/invoices/views/InvoicesIndexPage/components/InvoicesKPISection/messages";
import { messages } from "../messages";
import { EventTypes, trackEventWithAmplitude } from "../../../utils";
import styles from "../Receivables.module.css";

export interface AverageDaysToGettingPaidProps {
  commercial?: number;
  residential?: number;
  previous?: {
    commercial?: number;
    residential?: number;
  };
}

export const AverageDaysToGettingPaid = (
  props: AverageDaysToGettingPaidProps,
) => {
  const { formatMessage: t } = useIntl();

  const averageDaysMetrics = useAverageDaysMetrics({
    comparativeAverageDaysToGettingPaid: props,
    tooltipEventCallback: () =>
      trackEventWithAmplitude({
        eventType: EventTypes.Tooltip,
        eventSource: "Average Days to Getting Paid",
      }),
  });

  const tooltipText =
    props.commercial != null && props.residential != null
      ? t(invoiceMessages.averageDaysToGettingPaidCommercialExplanationTooltip)
      : t(invoiceMessages.averageDaysToGettingPaidTooltip);

  return (
    <Content type={"section"} spacing={"small"}>
      <Flex template={["shrink", "shrink"]} gap="smallest">
        <Text size="small">{t(messages.averageDaysToGettingPaidHeading)}</Text>
        <Tooltip preferredPlacement="bottom" message={tooltipText}>
          <span
            onMouseEnter={() => {
              trackEventWithAmplitude({
                eventType: EventTypes.Tooltip,
                eventSource: t(messages.averageDaysToGettingPaidToolTip),
              });
            }}
          >
            <Icon name={"help"} size={"small"} />
          </span>
        </Tooltip>
      </Flex>

      <Grid alignItems="row">
        {averageDaysMetrics.map(metric => (
          <Grid.Cell size={{ xs: 6 }} key={metric.value}>
            <Content spacing="smallest">
              <div className={styles.trendWrapper}>
                <Heading level={2} element="span">
                  {metric.value}
                </Heading>
                {metric.trend && metric.trend}
              </div>
              {metric.valueDescription && (
                <Text variation="subdued" size="small">
                  {metric.valueDescription}
                </Text>
              )}
            </Content>
          </Grid.Cell>
        ))}
      </Grid>
    </Content>
  );
};
