import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Page } from "@jobber/components/Page";
import type { IconNames } from "@jobber/design";
import { useResizeObserver } from "@jobber/hooks/useResizeObserver";
import { Banner } from "@jobber/components/Banner";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  TodayCard,
  type TodayCardProps,
} from "jobber/dashboard/components/TodayCard";
import {
  PaymentsCard,
  type PaymentsCardProps,
} from "jobber/managed_accounts/PaymentsCard";
import { InsightsDrawer } from "jobber/dashboard/components/InsightsDrawer";
import { newEventDialog, newTaskDialog } from "jobber/bridges/todoDialog";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { RailsPropsAsContexts } from "~/utilities/contexts/internal/RailsPropsAsContexts";
import { APIProvider } from "~/utilities/API/APIProvider";
import { navigateTo } from "jobber/bridges/setBannerNavigation";
import {
  deleteLocalStorageItems,
  findLocalStorageItems,
  restoreLocalStorageItems,
  setLocalStorageItems,
  unlockEventDataStore,
} from "jobber/dashboard/components/Home/HomeClientDataStore";
import type { SetCaptureUserEventMutation } from "~/utilities/API/graphql";
import { SET_CAPTURE_USER_EVENT_MUTATION } from "jobber/dashboard/components/InsightCard/InsightCard.graphql";
import { LiteToCoreTrialPopovers } from "jobber/liteToCoreTrial/LiteToCoreTrialPopovers";
import { LiteToCoreTrialInTrialCard } from "jobber/liteToCoreTrial/LiteToCoreTrialInTrialCard";
import { CoreToConnectTrialInTrialModal } from "jobber/coreToConnectTrial/components/CoreToConnectTrialInTrialModal";
import {
  CoreToConnectTrialBeginModal,
  CoreToConnectTrialStartSource,
} from "~/jobber/coreToConnectTrial/components/CoreToConnectTrialBeginModal/CoreToConnectTrialBeginModal";
import { CoreToConnectTrialHomePopovers } from "jobber/coreToConnectTrial/components/CoreToConnectTrialHomePopovers";
import { IntlProvider } from "@translations/IntlProvider";
import {
  ConnectToGrowTrialBeginModal,
  ConnectToGrowTrialBeginSource,
} from "jobber/connectToGrowTrial/components/ConnectToGrowTrialBeginModal/ConnectToGrowTrialBeginModal";
import { AppMarketplaceCard } from "jobber/managed_accounts/AppMarketplaceCard/AppMarketplaceCard";
import style from "./Home.module.css";
import {
  type HomeWorkFlowCardsProps,
  WorkflowCardList,
} from "./WorkflowCardList";
import { HomeHeaderContainer } from "./HomeHeaderContainer";
import { messages } from "./messages";

interface ActionsProps {
  label: string;
  icon?: IconNames;
  url?: string;
  jaTrackingLink?: string;
  jaSourceLink?: string;
  onClick(): void;
}

interface MoreActionsProps {
  header?: string;
  actions: ActionsProps[];
}

interface InsightEventData {
  event: string;
  source?: string;
  timestamp: string;
}

interface ClientStoreData {
  [key: string]: { [event: string]: InsightEventData };
}

export interface HomeProps {
  moreActionsMenu: MoreActionsProps[];
  workFlowCards: HomeWorkFlowCardsProps[];
  todayCard: TodayCardProps;
  paymentCard: PaymentsCardProps;
  workRequestEnabled: boolean;
  shouldShowJobberPaymentCard: boolean;
  shouldShowDisputesRow: boolean;
  shouldShowCalculateRevenue: boolean;
  shouldShowAppMarketplaceCard: boolean;
  shouldShowAppMarketplaceCardV2: boolean;
  country: string;
  metricsApiUrl: string;
  currencySymbol: string;
  showDrawer: boolean;
  drawerDefaultOpen: boolean;
  primaryAction: ActionsProps;
  trackEvents: boolean;
  showCustomizeBrandingInsight?: boolean;
  firstName: string;
  companyName: string;
  workflowItemCounts: { [key: string]: number };
  accountSubscribedOrPaying: boolean;
  shouldShowLiteToCoreTrialHighlights: boolean;
  industry: string;
  coreToConnectTrialDeeplink: boolean;
  showCoreToConnectTrialInTrialModal: boolean;
  showCoreToConnectTrialPreTrialModal: boolean;
  showCoreToConnectTrialHomeOnboardingHighlights: boolean;
  growTrial: {
    active: boolean;
    firstName: string;
    optingBackIn?: boolean;
  };
  connectToGrowTrialDeeplink: boolean;
  showConnectToGrowTrialPreTrialModal: boolean;
}

const customWidths = {
  large: 890,
};

const helpDocs =
  "https://help.getjobber.com/hc/en-us/articles/1500001840841-Insights";

// eslint-disable-next-line max-statements
function Home({
  moreActionsMenu,
  workFlowCards,
  todayCard,
  paymentCard,
  workRequestEnabled,
  shouldShowJobberPaymentCard,
  shouldShowDisputesRow,
  shouldShowCalculateRevenue,
  shouldShowAppMarketplaceCard,
  shouldShowAppMarketplaceCardV2,
  country,
  metricsApiUrl,
  currencySymbol,
  showDrawer,
  drawerDefaultOpen,
  primaryAction,
  trackEvents,
  showCustomizeBrandingInsight,
  firstName,
  workflowItemCounts,
  accountSubscribedOrPaying,
  shouldShowLiteToCoreTrialHighlights,
  industry,
  coreToConnectTrialDeeplink,
  showCoreToConnectTrialInTrialModal,
  showCoreToConnectTrialPreTrialModal,
  showCoreToConnectTrialHomeOnboardingHighlights,
  connectToGrowTrialDeeplink,
  growTrial,
  showConnectToGrowTrialPreTrialModal,
}: HomeProps) {
  const { formatMessage } = useIntl();
  const [drawerOpen, setDrawerOpen] = useState(
    drawerDefaultOpen && !growTrial.active && window.innerWidth > 639
      ? true
      : false,
  );
  const [showAppMarketplaceCard, setShowAppMarketplaceCard] = useState(
    shouldShowAppMarketplaceCard,
  );

  const [showAppMarketplaceCardV2, setShowAppMarketplaceCardV2] = useState(
    shouldShowAppMarketplaceCardV2,
  );

  const [setCaptureUserEvent] = useMutation<SetCaptureUserEventMutation>(
    SET_CAPTURE_USER_EVENT_MUTATION,
  );
  const primaryLabel = drawerOpen
    ? formatMessage(messages.hideInsightsLabel)
    : formatMessage(messages.viewInsightsLabel);
  const [ref, { exactWidth = 880 }] = useResizeObserver<HTMLDivElement>({
    widths: customWidths,
  });
  const showJobberPaymentCard =
    shouldShowJobberPaymentCard ||
    (!shouldShowJobberPaymentCard && shouldShowDisputesRow);
  const todayCardRowClasses = classNames({
    [style.fourColumns]:
      exactWidth >= customWidths.large &&
      (showJobberPaymentCard ||
        showAppMarketplaceCard ||
        showAppMarketplaceCardV2),
  });
  const [
    shouldShowCoreToConnectTrialBeginModal,
    setShouldShowCoreToConnectTrialBeginModal,
  ] = useState(showCoreToConnectTrialPreTrialModal);
  const [
    shouldShowConnectToGrowTrialBeginModal,
    setShouldShowConnectToGrowTrialBeginModal,
  ] = useState(showConnectToGrowTrialPreTrialModal);

  setupMoreActionsMenu(moreActionsMenu);

  let sendRequestsTimer: number | undefined;

  useEffect(() => {
    // unlock data store on re-render to guarantee sending events
    unlockEventDataStore();

    if (!trackEvents) return;

    sendRequestsTimer = window.setTimeout(sendCapturedEvents, 10000); // Every 10 seconds
    // sets tab close/navigate out event
    window.addEventListener("beforeunload", sendCapturedEvents);

    return () => {
      clearTimeout(sendRequestsTimer);
      window.removeEventListener("beforeunload", sendCapturedEvents);
    }; // cleanup the timer and close events
  }, []);

  const greeting = () => {
    const time = new Date().getHours();
    const greetingValues = { formattedName: firstName ? `, ${firstName}` : "" };

    if (time >= 3 && time < 12) {
      return formatMessage(messages.goodMorningGreeting, greetingValues);
    } else if (time >= 12 && time < 18) {
      return formatMessage(messages.goodAfternoonGreeting, greetingValues);
    } else {
      return formatMessage(messages.goodEveningGreeting, greetingValues);
    }
  };

  return (
    <div className={style.container}>
      {!showDrawer && (
        <Banner
          type="warning"
          dismissible={false}
          primaryAction={{
            label: formatMessage(messages.learnMoreAboutInsightsLabel),
            onClick: () => {
              navigateTo(helpDocs);
            },
          }}
        >
          {formatMessage(messages.insightsUnavailableBannerMessage)}
        </Banner>
      )}
      {showCoreToConnectTrialPreTrialModal && (
        <CoreToConnectTrialBeginModal
          source={
            coreToConnectTrialDeeplink
              ? CoreToConnectTrialStartSource.DEEPLINKED
              : CoreToConnectTrialStartSource.AUTOTRIGGERED
          }
          showModal={shouldShowCoreToConnectTrialBeginModal}
          dismissModal={() => setShouldShowCoreToConnectTrialBeginModal(false)}
        />
      )}
      {showCoreToConnectTrialInTrialModal && <CoreToConnectTrialInTrialModal />}

      {showConnectToGrowTrialPreTrialModal && (
        <ConnectToGrowTrialBeginModal
          source={
            connectToGrowTrialDeeplink
              ? ConnectToGrowTrialBeginSource.DEEPLINKED
              : ConnectToGrowTrialBeginSource.AUTOTRIGGERED
          }
          showModal={shouldShowConnectToGrowTrialBeginModal}
          dismissModal={() => setShouldShowConnectToGrowTrialBeginModal(false)}
        />
      )}

      <div className={style.pageGrid}>
        <div className={style.pageWrap} ref={ref}>
          <Page
            title={greeting()}
            intro=""
            secondaryAction={getSecondaryAction()}
            moreActionsMenu={moreActionsMenu}
            width="fill"
          >
            {shouldShowLiteToCoreTrialHighlights && (
              <div className={style.liteToCoreTrialInTrialCardContainer}>
                <LiteToCoreTrialInTrialCard />
              </div>
            )}
            {showCoreToConnectTrialHomeOnboardingHighlights && (
              <CoreToConnectTrialHomePopovers />
            )}
            <HomeHeaderContainer
              growTrial={growTrial}
              drawerOpen={showDrawer && drawerOpen}
            />
            <WorkflowCardList
              workflowCards={workFlowCards}
              workRequestEnabled={workRequestEnabled}
              metricsApiUrl={metricsApiUrl}
              currencySymbol={currencySymbol}
              workflowItemCounts={workflowItemCounts}
              accountSubscribedOrPaying={accountSubscribedOrPaying}
              industry={industry}
            />
            <div className={todayCardRowClasses}>
              <div className={style.todayCard}>
                <TodayCard
                  {...todayCard}
                  title={formatMessage(messages.todaysAppointmentsLabel)}
                  date={new Date().toDateString()}
                  showCalculateRevenue={shouldShowCalculateRevenue}
                />
              </div>
              {showAppMarketplaceCard && !showAppMarketplaceCardV2 && (
                <AppMarketplaceCard
                  country={country}
                  experimentName={"integrate_your_tools_card"}
                  experimentCTA={"show_app_marketplace_card_in_home"}
                  onDismiss={() => setShowAppMarketplaceCard(false)}
                />
              )}

              {showAppMarketplaceCardV2 && (
                <AppMarketplaceCard
                  country={country}
                  experimentName={"integrate_your_tools_card_v2"}
                  experimentCTA={"private_app_marketplace_page_viewed"}
                  onDismiss={() => setShowAppMarketplaceCardV2(false)}
                />
              )}
              {showJobberPaymentCard && (
                <PaymentsCard
                  {...paymentCard}
                  shouldShowJobberPaymentCard={shouldShowJobberPaymentCard}
                />
              )}
            </div>
          </Page>
        </div>
        {showDrawer && (
          <InsightsDrawer
            open={drawerOpen}
            onDrawerClose={toggleDrawerOpen}
            showCustomizeBrandingInsight={showCustomizeBrandingInsight}
            trackEvents={trackEvents}
          />
        )}
        {shouldShowLiteToCoreTrialHighlights && (
          <div className={style.liteToCoreTrialPopoverContainer}>
            <LiteToCoreTrialPopovers
              isInsightsDrawerOpen={showDrawer && drawerOpen}
            />
          </div>
        )}
      </div>
    </div>
  );

  function getSecondaryAction() {
    return showDrawer
      ? {
          label: primaryLabel,
          onClick: toggleDrawerOpen,
        }
      : primaryAction;
  }

  function toggleDrawerOpen() {
    const eventProperty = {
      source: "Home",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state_when_toggled: "",
    };
    setDrawerOpen(prevState => {
      const newState = !prevState;
      eventProperty.state_when_toggled = newState ? "Open" : "Closed";
      Amplitude.TRACK_EVENT("Toggled Insight Drawer", eventProperty);

      if (trackEvents) {
        setLocalStorageItems({
          event: {
            event: `drawer ${eventProperty.state_when_toggled.toLowerCase()}`,
            source: eventProperty.source,
            timestamp: moment(Date.now()).format(),
          },
        });
      }

      return newState;
    });
  }

  async function sendCapturedEvents() {
    const trackingData = findLocalStorageItems();
    if (trackingData.length > 0) {
      deleteLocalStorageItems();
      const jsonTrackData = JSON.parse(trackingData) as ClientStoreData;
      const insightEventDataArray: InsightEventData[] = [];

      Object.keys(jsonTrackData).forEach(key => {
        //We check for T here to see if this the old timestamp format vs the new one with timezones
        //and convert it to the new format if needed
        if (jsonTrackData[key].event.timestamp.indexOf("T") < 0) {
          const date = parseInt(jsonTrackData[key].event.timestamp, 10);
          jsonTrackData[key].event.timestamp = moment(date).format();
        }

        insightEventDataArray.push(jsonTrackData[key].event);
      });

      await setCaptureUserEvent({
        variables: {
          event: insightEventDataArray,
        },
      }).catch(() => {
        restoreLocalStorageItems(trackingData);
      });
    }
    sendRequestsTimer = window.setTimeout(sendCapturedEvents, 10000); // Every 10 seconds
  }
}

export function setupMoreActionsMenu(menu: MoreActionsProps[]) {
  menu.forEach(sections => {
    sections.actions.forEach(action => {
      action.onClick = () => {
        switch (action.label) {
          case "Task": {
            newTaskDialog();
            break;
          }
          case "Event": {
            newEventDialog();
            break;
          }
          default: {
            if (action?.url) {
              window.location.href = action.url;
            }
          }
        }
        if (action?.jaTrackingLink) {
          Amplitude.TRACK_EVENT(action.jaTrackingLink, {
            source: action.jaSourceLink,
          });
        }
      };
    });
  });
}

export function EnhancedHome(props: HomeProps) {
  return (
    <IntlProvider>
      <RailsPropsAsContexts {...props}>
        <APIProvider>
          <Home {...props} />
        </APIProvider>
      </RailsPropsAsContexts>
    </IntlProvider>
  );
}

export { EnhancedHome as Home };
