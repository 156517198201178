import {
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Page } from "@jobber/components/Page";
import { Banner } from "@jobber/components/Banner";
import { Heading } from "@jobber/components/Heading";
import type { Option } from "@jobber/components/Autocomplete";
import type { SortingState } from "@jobber/components/DataTable";
import { useSetPageTitle } from "jobber/settings/users/hooks/useSetPageTitle";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { InfoPopover } from "~/jobber/features/Reporting/components/InfoPopover/InfoPopover";
import { ReportFilters } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/ReportFilters";
import type {
  FilterType,
  ReportFiltersProps,
} from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import { useReportColumns } from "~/jobber/features/Reporting/components/Report/hooks/columns/useReportColumns";
import { messages } from "./messages";
import { ReportTable } from "./components/ReportTable/ReportTable";
import { ReportExport } from "./components/ReportExport/ReportExport";
import { ReportColumnSelect } from "./components/ReportColumnSelect/ReportColumnSelect";
import type { ReportTableProps } from "./components/ReportTable/types";
import type { ReportExportProps } from "./components/ReportExport/types";
import type { ReportColumns } from "./types";
import styles from "./Report.module.css";

interface ReportProps<T> extends Omit<ReportTableProps<T>, "columns"> {
  children?: ReactNode;
  columns: ReportColumns<T>;
  title: string;
  loading: boolean;
  error: string | undefined;
  filterProps: ReportFiltersProps;
  exportProps: ReportExportProps;
  uniqueReportKey?: string;
  infoPopoverHeader?: string;
  infoPopoverContent?: ReactNode;
  stickyHeader?: boolean;
  pinFirstColumn?: boolean;
  asyncLoading?: boolean;
  customReportFilters?: ReactNode;
  summarySection?: ReactNode;
  hideExport?: boolean;
  overrideTableState?: React.ReactNode;
}

// eslint-disable-next-line max-statements
export function Report<T extends object>({
  children,
  data,
  columns,
  title,
  loading,
  asyncLoading,
  error,
  pagination,
  sorting,
  filterProps,
  exportProps,
  uniqueReportKey,
  infoPopoverHeader,
  infoPopoverContent,
  customReportFilters,
  summarySection,
  stickyHeader = false,
  pinFirstColumn = false,
  hideExport = false,
  overrideTableState,
}: ReportProps<T>) {
  const { formatMessage } = useIntl();
  const [reportIsExported, setReportIsExported] = useState<boolean>(false);

  useSetPageTitle(
    title ? formatMessage(messages.reportTabName, { reportName: title }) : "",
  );

  useEffect(() => {
    if (exportProps.reportIsExported || exportProps.franchiseReportIsExported) {
      setReportIsExported(true);
    }
  }, [exportProps.reportIsExported, exportProps.franchiseReportIsExported]);

  if (error) {
    Rollbar.EXECUTE(`Error Loading Report: ${title}`, new Error(error));
  }
  // prevent changing of filtering, sorting, or search until loading is completed
  const onSort = (value: SetStateAction<SortingState>) => {
    if (!loading) {
      sorting.onSortingChange(value);
    }
  };

  const onSearch = (value: Option | undefined) => {
    if (!loading) {
      filterProps.searchFilter?.onChange(value);
    }
  };

  const onFilterChange = (value: FilterType, status?: string) => {
    if (!loading) {
      filterProps.onFilterChange(value, status);
    }
  };

  const dismissBanner = () => {
    setReportIsExported(false);
  };

  const tableSearchFilter = filterProps.searchFilter
    ? {
        ...filterProps.searchFilter,
        heading: undefined,
        onChange: onSearch,
      }
    : undefined;

  const tableFilter = { ...filterProps, onFilterChange };

  const { columnSelectProps, dataTableColumns } = useReportColumns<T>({
    columns,
    uniqueReportKey,
  });

  return (
    <div
      className={reportIsExported ? styles.exportWrapper : styles.reportWrapper}
    >
      {reportIsExported && (
        <div
          className={styles.successReportBanner}
          data-testid="reportSuccessBanner"
        >
          {window.innerWidth <= 1024 ? (
            <Banner
              type="success"
              primaryAction={{
                label: formatMessage(messages.dismissBannerLabel),
                onClick: dismissBanner,
                ariaLabel: "dismiss-button",
              }}
              dismissible={false}
            >
              {formatMessage(messages.exportSuccessReportBanner)}
            </Banner>
          ) : (
            <Banner type="success" onDismiss={dismissBanner} dismissible={true}>
              {formatMessage(messages.exportSuccessReportBanner)}
            </Banner>
          )}
        </div>
      )}
      <Page title={""} width={"fill"}>
        <div className={styles.headerWrapper}>
          <Heading level={1}>{title}</Heading>
          {Boolean(infoPopoverContent) && (
            <InfoPopover
              header={
                infoPopoverHeader ||
                formatMessage(messages.defaultReportInfoPopoverHeader)
              }
              iconAriaLabel={formatMessage(messages.moreInfoIconAriaLabel)}
            >
              {infoPopoverContent}
            </InfoPopover>
          )}
        </div>
        {error && (
          <Banner type={"error"}>
            {formatMessage(messages.reportErrorMessage)}
          </Banner>
        )}
        {children}
        <div className={styles.reportOptions} data-testid="reportOptions">
          <ReportFilters {...tableFilter}>{customReportFilters}</ReportFilters>
          <div className={styles.reportControls}>
            {columnSelectProps && <ReportColumnSelect {...columnSelectProps} />}
            {!hideExport && (
              <ReportExport
                columnVisibilityConfig={
                  columnSelectProps?.columnVisibilityConfig
                }
                {...exportProps}
              />
            )}
          </div>
        </div>
        {summarySection}
        <div className={styles.tableWrap}>
          <ReportTable
            pagination={pagination}
            sorting={{ ...sorting, onSortingChange: onSort }}
            data={data}
            loading={loading}
            asyncLoading={asyncLoading}
            columns={dataTableColumns}
            searchFilter={tableSearchFilter}
            pinFirstColumn={pinFirstColumn}
            stickyHeader={stickyHeader}
            overrideTableState={overrideTableState}
          />
        </div>
      </Page>
    </div>
  );
}
