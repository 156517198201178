import { defineMessages } from "react-intl";

export const messages = defineMessages({
  inTrialPrimaryButton: {
    id: "expansion.upgradeWaveModal.inTrialPrimaryButton",
    defaultMessage: "Get Started",
  },
  inTrialSecondaryButton: {
    id: "expansion.upgradeWaveModal.inTrialSecondaryButton",
    defaultMessage: "Chat with us",
  },
  growTitle: {
    id: "featureTrialModal.getStarted.growTitle",
    defaultMessage: "Let's get started with the Grow plan",
  },
  growContent: {
    id: "featureTrialModal.getStarted.growContent",
    defaultMessage:
      "During your 14-day trial, you'll have access to a checklist of features to explore and a quick guide on how to use them.",
  },
  chatWithProductExpert: {
    id: "featureTrialModal.getStarted.chatWithProductExpert",
    defaultMessage:
      "Short on time? Chat with your account manager. They're product experts who will show you what you need to know.",
  },
  reminder: {
    id: "featureTrialModal.getStarted.reminder",
    defaultMessage:
      "You'll receive a reminder 5 days before your trial ends, and your original plan will automatically continue.",
  },
  imageAlt: {
    id: "featureTrialModal.getStarted.imageAlt",
    defaultMessage: "Woman pointing at computer screen",
  },
});
