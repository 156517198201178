import { Content, Modal, Text } from "@jobber/components";
import type { Dispatch, SetStateAction } from "react";
import React from "react";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";

export const MarketingToolAddToCartModal = ({
  addonName,
  isOpen,
  setModalOpen,
}: {
  addonName: string;
  isOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      title={formatMessage(messages.modalTitle, { addonName })}
      open={isOpen}
      onRequestClose={() => setModalOpen(false)}
      primaryAction={{
        label: formatMessage(messages.primaryModalLabel),
        onClick: () => {
          setModalOpen(false);
          window.location.href = "/accounts/billing_info/pricing";
          Amplitude.TRACK_EVENT("Interacted with Experiment", {
            experiment: "add_marketing_tool_to_cart",
            interaction: "go_to_checkout_cta_clicked",
            source: addonName,
          });
        },
      }}
      secondaryAction={{
        label: formatMessage(messages.secondaryModalLabel),
        onClick: () => {
          setModalOpen(false);
          Amplitude.TRACK_EVENT("Interacted with Experiment", {
            experiment: "add_marketing_tool_to_cart",
            interaction: "stay_on_page_cta_clicked",
            source: addonName,
          });
        },
      }}
    >
      <Content>
        <Text>{formatMessage(messages.description)}</Text>
      </Content>
    </Modal>
  );
};
