import React, { type Dispatch } from "react";
import { Text } from "@jobber/components/Text";
import { usePaymentSubmit } from "jobber/payments_react/hooks/usePaymentSubmit/usePaymentSubmit";
import { PaymentMethodListType } from "jobber/payments_react/BaseCollectPaymentDialog/CollectPaymentDialogProps.d";
import { useSubmitAchPayment } from "jobber/payments_react/hooks/useAchPaymentMutation/useAchPaymentMutation";
import { usePaymentReducer } from "~/utilities/contexts/internal/PaymentReducerContext";
import { VaultedPaymentMethodsSelector } from "./VaultedPaymentMethodsSelector";
import type { PaymentAction } from "./paymentReducer";

export interface JobberPaymentsAchFormProps {
  clientId: string;
}

export function JobberPaymentsAchForm({
  clientId,
}: JobberPaymentsAchFormProps) {
  const [paymentState, paymentDispatcher] = usePaymentReducer();
  paymentDispatcher({ type: "completingInitialization" });
  const { submitPayment } = useSubmitAchPayment();

  usePaymentSubmit(paymentState, paymentDispatcher, {
    submitWithPaymentState: submit,
  });

  const {
    jobberPaymentMethodId,
    paymentMethods,
    attachedToId,
    attachedToType,
  } = paymentState;
  const hasNoPaymentMethods = !jobberPaymentMethodId && paymentMethods;

  async function submit(dispatch: Dispatch<PaymentAction>) {
    if (!jobberPaymentMethodId) {
      dispatch({
        type: "completingCharge",
        response: {
          success: false,
          errorMessage: "Please select a valid payment method and try again.",
        },
      });

      return;
    }

    const result = await submitPayment({
      attachedToId: btoa(attachedToId),
      attachedToType,
      paymentMethodUuid: jobberPaymentMethodId,
      amount: paymentState.amount,
    });

    dispatch({
      type: "completingCharge",
      response: result.success
        ? { success: true }
        : {
            success: false,
            errorMessage:
              result.errorMessage ||
              "Something went wrong, payment could not be completed successfully.",
          },
    });
  }

  return (
    <>
      <VaultedPaymentMethodsSelector
        clientId={clientId}
        paymentMethodListType={PaymentMethodListType.BANK_ACCOUNT}
        includeNewPaymentMethodOption={false}
      />

      {hasNoPaymentMethods && (
        <Text variation="subdued">
          <em>
            There are no bank details saved on file for this client. You can
            request your client to add it from the client profile.
          </em>
        </Text>
      )}
    </>
  );
}
