import { IntervalUnits } from "~/utilities/API/graphql";
import type { BaseCriteriaProps } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/BaseCriteria/BaseCriteria";
import { messages } from "./messages";

export const INTERVAL_UNIT_OPTIONS: BaseCriteriaProps["intervalUnitOptions"] = [
  {
    label: messages.yearsLabel,
    value: IntervalUnits.YEARS,
  },
  {
    label: messages.monthsLabel,
    value: IntervalUnits.MONTHS,
  },
  {
    label: messages.weeksLabel,
    value: IntervalUnits.WEEKS,
  },
  {
    label: messages.daysLabel,
    value: IntervalUnits.DAYS,
  },
];
