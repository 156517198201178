import React from "react";
import classNames from "classnames";
import { Icon, type IconNames } from "@jobber/components/Icon";
import type { EventOnClick } from "~/jobber/actionBar/types";

export interface ItemProps {
  label: string;
  icon?: IconNames;
  url?: string;
  selected?: boolean;
  hasUpdates?: boolean;
  dialog?: boolean;
  external?: boolean;
  method?: "POST" | "PUT";
  confirm?: string;
  showLoadingOnClick?: boolean;
  jaTrackingLink?: string;
  jaSourceLink?: string;
  jaEmailType?: string;
  jaType?: string;
  jaRefundAvailableLink?: string;
  jaNameLink?: string;
  jaInteractionLink?: string;
  printButton?: string;
  clearIconColor?: boolean;
  eventOnClick?: EventOnClick;
  onClick?(): void;
  customActionName?: string;
  objectId?: string;
}

/**
 * React wrapper for Item
 */

export function Item(props: ItemProps) {
  const className = classNames("dropdown-item", {
    /* eslint-disable @typescript-eslint/naming-convention */
    "is-selected": props.selected,
    "has-updates": props.hasUpdates,
    "js-spinOnClick": !props.external && props.showLoadingOnClick,
    /* eslint-enable @typescript-eslint/naming-convention */
  });

  const attrs = {
    className: className,
    href: props.url,
    onClick: props.onClick,
    target: props.external ? "_blank" : "_self",
    /* eslint-disable @typescript-eslint/naming-convention */
    "data-confirm": props.confirm,
    "data-ja-track-link": props.jaTrackingLink,
    "data-ja-source": props.jaSourceLink,
    "data-ja-email-type": props.jaEmailType,
    "data-ja-type": props.jaType,
    "data-ja-name": props.jaNameLink,
    "data-ja-interaction": props.jaInteractionLink,
    "data-ja-refund-available": props.jaRefundAvailableLink,
    "data-print-button": props.printButton,

    /**
     * We should find a solution to get away from using rails magic. For now,
     * it looks like we can't get away with this because of how some controllers
     * are written to mix and match "remote" and "method".
     */
    "data-method": props.method,
    "data-remote": props.dialog,
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  const MenuItem = props.url === undefined ? "button" : "a";

  return (
    <MenuItem {...attrs}>
      {props.icon && (
        <div style={{ marginRight: "var(--space-base)" }}>
          <Icon name={props.icon} />
        </div>
      )}
      {props.label}
    </MenuItem>
  );
}
