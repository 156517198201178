import { Button } from "@jobber/components";
import type { Dispatch, SetStateAction } from "react";
import React from "react";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./RemoveAddonButton.module.css";
import { messages } from "./messages";
import type { SubscriptionAddonPreview } from "../../types";

export const RemoveAddonButton = ({
  subscriptionAddon,
  setSelectedAddons,
}: {
  subscriptionAddon: SubscriptionAddonPreview;
  setSelectedAddons: Dispatch<SetStateAction<string[]>>;
}) => {
  const { formatMessage } = useIntl();
  const INTERACTED_WITH_EXPERIMENT = "Interacted with Experiment";
  const updateCookie = (addonIdentifier: string) => {
    const existingCookie = window.Cookies.get("add_marketing_tool_to_cart");
    if (existingCookie) {
      const existingFeatures = atob(existingCookie).split(",");
      if (existingFeatures.includes(addonIdentifier)) {
        const newCookie = existingFeatures.filter(
          feature => feature !== addonIdentifier,
        );
        window.Cookies.set(
          "add_marketing_tool_to_cart",
          btoa(newCookie.join(",")),
          {
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7), // 1 week expiration date
            domain: `.${window.location.hostname}`,
          },
        );
      }
    }
  };
  const handleRemoveAddon = () => {
    Amplitude.TRACK_EVENT(INTERACTED_WITH_EXPERIMENT, {
      experiment: "checkout_remove_addon_button",
      interaction: "removed",
      source: subscriptionAddon.identifier,
    });
    setSelectedAddons((addons: string[]) => {
      return addons.filter(
        code => code !== subscriptionAddon.monthlyBillingCycle.addonCode,
      );
    });
    updateCookie(subscriptionAddon.identifier);
  };
  return (
    <div className={styles.container}>
      <Button
        type="tertiary"
        variation="subtle"
        onClick={handleRemoveAddon}
        ariaLabel={`Remove ${subscriptionAddon.name} from cart`}
        label={formatMessage(messages.removeAddonButton)}
      />
    </div>
  );
};
