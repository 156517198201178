import { useMutation } from "@apollo/client";
import type {
  AiAssistantSupportEscalationInteractionUpdateMutation,
  AiAssistantSupportEscalationInteractionUpdateMutationVariables,
} from "~/utilities/API/graphql";
import { AI_ASSISTANT_SUPPORT_ESCALATION_INTERACTION_UPDATE } from "../components/JobberAssistant.graphql";

interface UseSupportInteractionUpdateMutationProps {
  requestId: string;
}

interface UseSupportInteractionUpdateMutation {
  updateInteraction: (intercomConversationId: string) => Promise<void>;
}

export function useSupportInteractionUpdateMutation({
  requestId,
}: UseSupportInteractionUpdateMutationProps): UseSupportInteractionUpdateMutation {
  const [supportInteractionUpdate] = useMutation<
    AiAssistantSupportEscalationInteractionUpdateMutation,
    AiAssistantSupportEscalationInteractionUpdateMutationVariables
  >(AI_ASSISTANT_SUPPORT_ESCALATION_INTERACTION_UPDATE);

  const updateInteraction = async (intercomConversationId: string) => {
    await supportInteractionUpdate({
      variables: {
        requestId,
        input: { intercomConversationId },
      },
    });
  };
  return { updateInteraction };
}
