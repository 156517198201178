import React from "react";
import { Item } from "components/Dropdown/components/Item";
import type { ItemProps } from "components/Dropdown/components/Item";
import { useExportJobCostMutation } from "./hooks";

export const ExportJobCostActionButton = ({
  object,
  itemKey,
  toggleMenu,
}: {
  object: ItemProps;
  itemKey: number;
  toggleMenu: () => void;
}) => {
  const jobId = btoa(object.objectId ?? "");
  const { handleSubmit, loading } = useExportJobCostMutation({ jobId });

  const handleClick = async () => {
    await handleSubmit();
    toggleMenu();
  };

  return (
    <Item
      key={`${object.label}_${itemKey}`}
      label={object.label}
      icon={object.icon}
      url={object.url}
      selected={object.selected}
      external={object.external}
      hasUpdates={object.hasUpdates}
      method={object.method}
      confirm={object.confirm}
      jaTrackingLink={object.jaTrackingLink}
      jaSourceLink={object.jaSourceLink}
      jaType={object.jaType}
      jaEmailType={object.jaEmailType}
      dialog={object.dialog}
      printButton={object.printButton}
      clearIconColor={object.clearIconColor}
      showLoadingOnClick={loading}
      onClick={handleClick}
    />
  );
};
