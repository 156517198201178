import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { Box } from "@jobber/components/Box";
import { Flex } from "@jobber/components/Flex";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useUrls } from "~/utilities/contexts/internal/useUrls";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import { BankAccount, type BankAccountProps } from "./BankAccount";
import type { SuccessBank } from "./bankAccountReducer";
import { messages } from "./messages";

export interface BankDetailsProps {
  bank?: SuccessBank;
  bankAccountProps: BankAccountProps;
  payoutsErrorMessage?: string;
  receivedFirstPayout: boolean;
  manualPayoutsIsEnabled: boolean;
  payoutDelayDaysType: "business" | "calendar";
}

enum payoutScheduleState {
  PAYOUTS_UNAVAILABLE = "PAYOUTS_UNAVAILABLE",
  TEMPORARY_DELAY = "TEMPORARY_DELAY",
  HOURLY_PAYOUTS = "HOURLY_PAYOUTS",
  STANDARD_PAYOUTS = "STANDARD_PAYOUTS",
}

export function BankDetails(props: BankDetailsProps) {
  const {
    bank,
    bankAccountProps,
    payoutsErrorMessage,
    receivedFirstPayout,
    manualPayoutsIsEnabled,
    payoutDelayDaysType,
  } = props;
  const { payoutScheduleInDays, permissions } = useJobberPayments();
  const [learnMoreUrl] = useUrls("docsPayoutsUrl");
  const { formatMessage } = useIntl();

  const headingCopy =
    bank?.payoutsToJobberMoney && !manualPayoutsIsEnabled
      ? formatMessage(messages.hourlyPayoutScheduleTitle)
      : formatMessage(messages.standardPayoutScheduleTitle);

  function getPayoutScheduleState() {
    if (manualPayoutsIsEnabled) {
      return payoutScheduleState.PAYOUTS_UNAVAILABLE;
    } else if (!receivedFirstPayout) {
      return payoutScheduleState.TEMPORARY_DELAY;
    } else if (bank?.payoutsToJobberMoney) {
      return payoutScheduleState.HOURLY_PAYOUTS;
    } else {
      return payoutScheduleState.STANDARD_PAYOUTS;
    }
  }

  function getPayoutCopyText() {
    const currentPayoutScheduleState = getPayoutScheduleState();
    switch (currentPayoutScheduleState) {
      case payoutScheduleState.PAYOUTS_UNAVAILABLE:
        return formatMessage(messages.payoutsUnavailableDescription);

      case payoutScheduleState.TEMPORARY_DELAY:
        return formatMessage(messages.temporaryPayoutsDelayDescription, {
          payoutScheduleDelayDays: payoutScheduleInDays,
          delayDayType: payoutDelayDaysType,
        });

      case payoutScheduleState.HOURLY_PAYOUTS:
        return formatMessage(messages.hourlyPayoutsDescription);

      case payoutScheduleState.STANDARD_PAYOUTS:
        return formatMessage(messages.standardPayoutsDescription, {
          payoutScheduleDelayDays: payoutScheduleInDays,
          delayDayType: payoutDelayDaysType,
        });
    }
  }

  function learnMoreLink() {
    return (
      <Text variation="subdued">
        <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
          {formatMessage(messages.learnMore)}
        </a>
      </Text>
    );
  }

  return (
    <Content>
      <Heading level={3}>{headingCopy}</Heading>
      {payoutsErrorMessage && (
        <Banner type="warning" dismissible={false}>
          <Text>{payoutsErrorMessage}</Text>
        </Banner>
      )}
      {permissions.canToggleAchPayments &&
      getPayoutScheduleState() === payoutScheduleState.STANDARD_PAYOUTS ? (
        <Box
          direction="column"
          gap="small"
          alignItems="flex-start"
          alignSelf="stretch"
        >
          <Box direction="row">
            <Box width={210} margin={{ right: "small" }}>
              <Text>
                <strong>
                  {formatMessage(messages.standardPayoutCardTitle)}
                </strong>
              </Text>
            </Box>
            <Text>
              {formatMessage(messages.standardPayoutSchedule, {
                payoutScheduleDelayDays: payoutScheduleInDays,
                delayDayType: payoutDelayDaysType,
              })}
            </Text>
          </Box>
          <Box direction="row">
            <Box width={210} margin={{ right: "small" }}>
              <Text>
                <strong>
                  {formatMessage(messages.standardPayoutAchTitle)}
                </strong>
              </Text>
            </Box>
            <Text>
              {formatMessage(messages.standardPayoutSchedule, {
                payoutScheduleDelayDays: 4,
                delayDayType: "business",
              })}
            </Text>
          </Box>
          {learnMoreLink()}
        </Box>
      ) : (
        <Content spacing="smaller">
          <Text variation="subdued">{getPayoutCopyText()}</Text>
          {learnMoreLink()}
        </Content>
      )}
      <Content spacing="small">
        <Box padding={{ vertical: "small" }}>
          <Flex template={["grow", "shrink"]}>
            <Heading level={4}>
              {formatMessage(messages.bankDetailsTitle)}
            </Heading>
            <ChangeBankAccount
              bankAccountProps={bankAccountProps}
              payoutsToJobberMoney={!!bank?.payoutsToJobberMoney}
              changeBankAccountText={formatMessage(messages.changeBankAccount)}
            />
          </Flex>
        </Box>
        <Box background="surface--background" padding="base" radius="base">
          {bank && bank.bankName && (
            <Heading level={4}>{bank.bankName}</Heading>
          )}
          {bank && bank.bankLast4 && (
            <Text>
              {formatMessage(messages.accountEndingDigits, {
                bankLast4: bank.bankLast4,
              })}
            </Text>
          )}
        </Box>
      </Content>
    </Content>
  );
}

function ChangeBankAccount(props: {
  payoutsToJobberMoney: boolean;
  bankAccountProps: BankAccountProps;
  changeBankAccountText: string;
}) {
  const { payoutsToJobberMoney, bankAccountProps, changeBankAccountText } =
    props;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { permissions, enabled: jobberPaymentsEnabled } = useJobberPayments();
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const shouldShow = permissions.canChangeBankAccount;
  const shouldDisable = !jobberPaymentsEnabled;

  useEffect(() => {
    if (
      window.location.pathname === "/jobber_payments/oauth" &&
      window.location.search.includes("oauth_state_id")
    ) {
      openDialog();
    }
  }, []);

  return (
    <>
      {shouldShow && (
        <TextActionButton
          label={changeBankAccountText}
          disabled={shouldDisable}
          onClick={onChangeBankClick}
        />
      )}
      <Modal
        open={isDialogOpen}
        onRequestClose={closeDialog}
        title={changeBankAccountText.toUpperCase()}
      >
        <BankAccount
          {...bankAccountProps}
          payoutsToJobberMoney={payoutsToJobberMoney}
          embedded={true}
          completedCallback={onComplete}
        />
      </Modal>
    </>
  );

  function onChangeBankClick() {
    openDialog();
  }

  function onComplete(bank?: SuccessBank) {
    closeDialog();
    bankAccountProps.completedCallback(bank);
  }
}
