import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CampaignsTemplatePage } from "jobber/campaigns/views/CampaignsTemplatePage";
import {
  NoMatch,
  join,
  withParams,
} from "jobber/settings/users/utilities/routing";
import { CampaignsReviewPage } from "jobber/campaigns/views/CampaignsReviewPage";
import { CampaignsContentPage } from "jobber/campaigns/views/CampaignsContentPage";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_REVIEW_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_PATH,
  CAMPAIGNS_SENT_PATH,
  CAMPAIGNS_TEMPLATE_EDIT_PATH,
  CAMPAIGNS_TEMPLATE_PATH,
} from "jobber/campaigns/constants";
import { withEntryPointProviders } from "utilities/withEntryPointProviders";
import type { Template } from "~/utilities/API/graphql";
import { withSplitClient } from "utilities/split";
import type { AddressConfiguration } from "components/InputAddress/types";
import { IntlProvider } from "@translations/IntlProvider";
import { CampaignReportingDetailsPage } from "jobber/campaigns/views/CampaignReportingDetailsPage/CampaignReportingDetailsPage";
import { AccountSettingsProvider } from "~/jobber/account/AccountSettingsProvider";
import { CampaignRecipientsPage } from "jobber/campaigns/views/CampaignRecipientsPage/CampaignRecipientsPage";
import { CampaignsLandingPage } from "jobber/campaigns/views/CampaignsLandingPage/CampaignsLandingPage";
import { CampaignWizardProvider } from "./contexts";

interface CampaignsRouteProps {
  addressConfiguration: AddressConfiguration;
  recurlyPublicKey: string;
  salesforceTrackingId: string | null;
}

export function CampaignsRoute({
  addressConfiguration,
  recurlyPublicKey,
  salesforceTrackingId,
}: CampaignsRouteProps) {
  const { path } = useRouteMatch();

  return (
    <IntlProvider>
      <AccountSettingsProvider>
        <CampaignWizardProvider>
          <Switch>
            <Route
              exact
              path={join(path)}
              render={() => <CampaignsLandingPage />}
            />
            <Route
              exact
              path={[
                join(path, CAMPAIGNS_SEGMENT_EDIT_PATH),
                join(path, CAMPAIGNS_SEGMENT_PATH),
              ]}
              render={withParams<{ campaignId: string; templateType: string }>(
                props => (
                  <CampaignRecipientsPage {...props} />
                ),
              )}
            />
            <Route
              path={[join(path, CAMPAIGNS_CONTENT_EDIT_PATH)]}
              render={withParams<{
                campaignId: string;
                templateType: Template;
              }>(({ campaignId, templateType }) => (
                <CampaignsContentPage
                  campaignId={campaignId}
                  templateType={templateType}
                  addressConfiguration={addressConfiguration}
                />
              ))}
            />
            <Route
              path={join(path, CAMPAIGNS_REVIEW_PATH)}
              render={withParams<{ campaignId: string }>(({ campaignId }) => (
                <CampaignsReviewPage
                  campaignId={campaignId}
                  recurlyPublicKey={recurlyPublicKey}
                  salesforceTrackingId={salesforceTrackingId}
                />
              ))}
            />
            <Route
              path={join(path, CAMPAIGNS_SENT_PATH)}
              render={withParams<{ campaignId: string }>(({ campaignId }) => (
                <CampaignReportingDetailsPage campaignId={campaignId} />
              ))}
            />
            <Route
              path={[
                join(path, CAMPAIGNS_TEMPLATE_PATH),
                join(path, CAMPAIGNS_TEMPLATE_EDIT_PATH),
              ]}
              render={withParams<{ campaignId: string }>(({ campaignId }) => (
                <CampaignsTemplatePage campaignId={campaignId} />
              ))}
            />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </CampaignWizardProvider>
      </AccountSettingsProvider>
    </IntlProvider>
  );
}

export const CampaignsRouteWrapped = withSplitClient(
  withEntryPointProviders(CampaignsRoute),
);
