import React from "react";
import { v1 as uuidV1 } from "uuid";
import styles from "./ControlledColorPicker.module.css";

interface ColorPickerProps {
  value: string;
  placeholder: string;
  dataList: string[];
  onChange(newValue: string): void;
}

/**
 * This is a controlled version of the color picker component. It is preferred to use over
 * the uncontrolled version wherever because the internal state of this component can cause
 * issues when it is re-rendered. This should replace the uncontrolled version in the future.
 */
export function ControlledColorPicker({
  value,
  placeholder,
  dataList,
  onChange,
}: ColorPickerProps) {
  const dataListId = uuidV1();

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value);
  };

  return (
    <div className={`fieldAffix ${styles.wrapper}`}>
      <span className="fieldAffix-item">
        <input
          type="color"
          value={value}
          onChange={handleColorChange}
          list={dataListId}
        />
        <datalist id={dataListId}>
          {dataList.map(color => {
            const selected = color === value;

            return (
              <option key={color} aria-selected={selected} value={color} />
            );
          })}
        </datalist>
      </span>
      <placeholder-field class="" label={placeholder} auto-size={false}>
        <input
          type="text"
          maxLength={7}
          value={value}
          onChange={handleColorChange}
        />
      </placeholder-field>
    </div>
  );
}
