import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import type { DepositResult } from "jobber/managed_accounts/WithdrawFundsDialog";
import styles from "./PaymentsCard.module.css";
import { JobberMoneyBalance } from "./JobberMoneyBalance";
import { NextPayout, type Payout } from "./NextPayout";
import { InstantPayoutRow } from "./InstantPayoutRow";
import type { PaymentsCardProps } from "./PaymentsCard";
import { messages } from "./messages";

export function PayoutDetails(props: PaymentsCardProps) {
  const { canViewInstantPayouts, currencyUnit, jobberMoney } = props;

  const { formatMessage } = useIntl();
  const [nextPayouts, setNextPayouts] = useState(props.nextPayouts);
  const [instantAvailableAmount, setInstantAvailableAmount] = useState(
    props.instantAvailableAmount,
  );

  if (jobberMoney.payoutIsJobberMoneyAccount === true) {
    return (
      <JobberMoneyBalance
        balance={jobberMoney?.balance}
        lastPayoutAmount={jobberMoney?.lastPayoutAmount}
        currencyUnit={currencyUnit}
      />
    );
  } else {
    return (
      <Content>
        <h5 className={styles.headerText}>
          {formatMessage(messages.payoutOnItsWay)}
        </h5>
        {nextPayouts.map(payout => (
          <NextPayout
            key={payout.id}
            payout={payout}
            currencyUnit={currencyUnit}
          />
        ))}
        {nextPayouts.length === 0 && (
          <Text variation="subdued">
            {formatMessage(messages.noPayoutOnItsWay)}
          </Text>
        )}
        {canViewInstantPayouts && (
          <InstantPayoutRow
            {...props}
            instantAvailableAmount={instantAvailableAmount}
            onPayoutSucceed={onPayoutSucceed}
          />
        )}
      </Content>
    );
  }

  function onPayoutSucceed(deposit: DepositResult) {
    setInstantAvailableAmount(deposit.instantAvailableBalance);

    const payout: Payout = {
      id: deposit.id,
      amount: deposit.amount,
      type: "instant",
    };
    setNextPayouts(payouts => [...payouts, payout]);
  }
}
