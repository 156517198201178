import { useMutation } from "@apollo/client";
import type {
  AiAssistantActionFollowupsDeleteMutation,
  AiAssistantActionFollowupsDeleteMutationVariables,
} from "~/utilities/API/graphql";
import { AI_ASSISTANT_ACTION_FOLLOWUPS_DELETE } from "./actionFollowupsDelete.graphql";

interface UseActionFollowupsDeleteProps {
  requestId: string;
}

interface UseActionFollowupsDelete {
  deleteActionFollowups: () => Promise<void>;
  called: boolean;
}

export function useActionFollowupsDelete({
  requestId,
}: UseActionFollowupsDeleteProps): UseActionFollowupsDelete {
  const [actionFollowupsDelete, { called }] = useMutation<
    AiAssistantActionFollowupsDeleteMutation,
    AiAssistantActionFollowupsDeleteMutationVariables
  >(AI_ASSISTANT_ACTION_FOLLOWUPS_DELETE);

  const deleteActionFollowups = async () => {
    await actionFollowupsDelete({
      variables: {
        requestId,
      },
    });
  };
  return { deleteActionFollowups, called };
}
