import { gql } from "@apollo/client";

export const LANDING_PAGE_PLAN_QUERY = gql`
  query LandingPagePlanCardQuery($filter: PlanChangeInfoFilterInput) {
    accountPlanInfo {
      belugaPhaseOneSingleUserPlan
    }
    plansAvailable(filter: $filter) {
      planCostMonthly
      planCostAnnual
      belugaPhaseOneSingleUserPlan
      planIdentifier
      userLimit
      planTier
      annualBillingCycle {
        planCode
      }
    }
  }
`;

export interface LandingPagePlanCardQueryPlansAvailableType {
  plansAvailable: Array<LandingPagePlanDataType>;
  accountPlanInfo: LandingPagePlanCardQueryAccountPlanInfoType;
}

export interface LandingPagePlanDataType {
  planCostMonthly: number;
  planCostAnnual: number;
  belugaPhaseOneSingleUserPlan: boolean;
  planIdentifier: string;
  userLimit: number;
  planTier: string;
  annualBillingCycle: {
    planCode: string;
  };
}

export interface LandingPagePlanCardQueryAccountPlanInfoType {
  belugaPhaseOneSingleUserPlan: boolean;
}
