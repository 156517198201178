import React, {
  type ComponentType,
  type Context,
  type ProviderProps,
  useState,
} from "react";
import {
  CallToActionContext,
  type CallToActionContextType,
} from "./CallToActionContext";
import { AccountContext, type AccountContextType } from "./AccountContext";
import { AuthContext } from "./AuthContext";
import {
  CountryCodesToNameMapContext,
  type CountryCodesToNameMapContextType,
} from "./CountryCodesToNameMapContext";
import {
  JobberPaymentsContext,
  type JobberPaymentsContextInitType,
} from "./JobberPaymentsContext";
import { TranslationContext, type Translations } from "./TranslationContext";
import { UrlsContext, type UrlsContextType } from "./UrlsContext";
import {
  RailsRoutesContext,
  type RailsRoutesContextType,
} from "./RailsRoutesContext";
import { JobberPaymentsContextProvider } from "./JobberPaymentsContextProvider";
import { CallToActionContextProvider } from "./CallToActionContextProvider";

export interface RailsPropsAsContextsProps {
  authenticityToken?: string;
  jobberPaymentsContext?: JobberPaymentsContextInitType;
  accountContext?: AccountContextType;
  urlsContext?: UrlsContextType;
  translations?: Translations;
  callToActionsContext?: CallToActionContextType;
  railsRoutesContext?: RailsRoutesContextType;
  countryCodesToNameMapContext?: CountryCodesToNameMapContextType;
  children?: React.ReactNode;
}

const [
  OptionalJobberPaymentsContext,
  OptionalUrlsContext,
  OptionalAccountContext,
  OptionalAuthContext,
  OptionalTranslationContext,
  OptionalCallToActionContext,
  OptionalCountryCodesToNameMapContext,
  OptionalRailsRoutesContext,
] = [
  optionalProvider(JobberPaymentsContext, JobberPaymentsContextProvider),
  optionalProvider(UrlsContext),
  optionalProvider(AccountContext),
  optionalProvider(AuthContext),
  optionalProvider(TranslationContext),
  optionalProvider(CallToActionContext, CallToActionContextProvider),
  optionalProvider(CountryCodesToNameMapContext),
  optionalProvider(RailsRoutesContext),
] as const;

export const RailsPropsAsContexts: ComponentType<
  RailsPropsAsContextsProps
> = props => {
  const {
    countryCodesToNameMapContext,
    jobberPaymentsContext,
    callToActionsContext,
    railsRoutesContext,
    authenticityToken,
    accountContext,
    translations,
    urlsContext,
    children,
  } = props;

  const [authContext] = useState(
    authenticityToken ? { authenticityToken } : undefined,
  );
  const [translationContext] = useState(translations && { translations });

  return (
    <OptionalJobberPaymentsContext value={jobberPaymentsContext}>
      <OptionalUrlsContext value={urlsContext}>
        <OptionalAccountContext value={accountContext}>
          <OptionalAuthContext value={authContext}>
            <OptionalTranslationContext value={translationContext}>
              <OptionalCallToActionContext value={callToActionsContext}>
                <OptionalCountryCodesToNameMapContext
                  value={countryCodesToNameMapContext}
                >
                  <OptionalRailsRoutesContext value={railsRoutesContext}>
                    {children}
                  </OptionalRailsRoutesContext>
                </OptionalCountryCodesToNameMapContext>
              </OptionalCallToActionContext>
            </OptionalTranslationContext>
          </OptionalAuthContext>
        </OptionalAccountContext>
      </OptionalUrlsContext>
    </OptionalJobberPaymentsContext>
  );
};

function optionalProvider<ContextType, ContextInit>(
  context: Context<ContextType>,
  ProviderComponent?: ComponentType<ProviderProps<ContextInit>>,
) {
  const OptionalProvider = (
    props: Partial<
      ProviderProps<ContextInit extends object ? ContextInit : ContextType>
    >,
  ) => {
    const { value, children } = props;
    if (!value) return <>{children}</>;

    if (ProviderComponent) {
      return (
        <ProviderComponent value={value as ContextInit}>
          {children}
        </ProviderComponent>
      );
    }

    return (
      <context.Provider value={value as ContextType}>
        {children}
      </context.Provider>
    );
  };
  OptionalProvider.displayName = `OptionalProvider<${context.displayName}>`;

  return OptionalProvider;
}
