import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Content, Link, Popover } from "@jobber/components";
import { Icon } from "@jobber/components/Icon";
import { Switch } from "@jobber/components/Switch";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import styles from "./MandatoryPaymentMethodOnFile.module.css";
import { messages } from "./messages";

export interface MandatoryPaymentMethodOnFileProps {
  disableToggle?: boolean;
  mandatoryPaymentMethodOnFileEnabled?: boolean;
  setMandatoryPaymentMethodOnFileEnabled?: (value: boolean) => void;
}

export function MandatoryPaymentMethodOnFile({
  disableToggle,
  mandatoryPaymentMethodOnFileEnabled,
  setMandatoryPaymentMethodOnFileEnabled,
}: MandatoryPaymentMethodOnFileProps) {
  const { formatMessage } = useIntl();
  const [showPopover, setShowPopover] = useState(false);
  const newFeatureButton = useRef<HTMLButtonElement>(null);

  const variation = disableToggle ? "disabled" : undefined;
  const labelText = formatMessage(
    messages.onlinePaymentSettingsMandatoryPaymentMethodOnFileText,
  );

  return (
    <>
      <div className={`${styles.onlinePaymentOption}`}>
        <div>
          <div className={styles.containerLabelAndIcon}>
            <Text variation={variation}>{labelText}</Text>
            <button
              ref={newFeatureButton}
              type="button"
              onClick={() => setShowPopover(!showPopover)}
              aria-label={formatMessage(
                messages.mandatoryPaymentMethodOnFileHelpButtonAriaLabel,
              )}
            >
              <Icon name="help" size="small" color={variation} />
            </button>
          </div>
          {disableToggle && (
            <div className={styles.helpText}>
              <Text size={"small"}>
                <Emphasis variation="italic">
                  {formatMessage(
                    messages.onlinePaymentSettingsMandatoryPaymentMethodOnFileDisabledText,
                  )}
                </Emphasis>
              </Text>
            </div>
          )}
        </div>
        <div className={styles.rightSwitch}>
          <Switch
            onChange={setMandatoryPaymentMethodOnFileEnabled}
            value={mandatoryPaymentMethodOnFileEnabled && !disableToggle}
            ariaLabel={`Toggle ${labelText}`}
            disabled={disableToggle}
          />
        </div>
      </div>
      <input
        type="hidden"
        name={"quote[mandatory_payment_method_on_file]"}
        value={mandatoryPaymentMethodOnFileEnabled?.toString()}
        data-testid={`mandatory_payment_method_on_file_hidden_field`}
      />
      <Popover
        attachTo={newFeatureButton}
        open={showPopover}
        preferredPlacement="top"
        onRequestClose={() => setShowPopover(false)}
      >
        <Content>
          <Text variation="subdued">
            {formatMessage(messages.mandatoryPaymentMethodOnFilePopoverText)}
          </Text>
          <Link
            external={true}
            url={
              "https://help.getjobber.com/hc/en-us/articles/115009590727-Jobber-Payments-Management-and-Settings"
            }
          >
            {formatMessage(
              messages.mandatoryPaymentMethodOnFilePopoverLinkText,
            )}
          </Link>
        </Content>
      </Popover>
    </>
  );
}
