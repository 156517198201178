import React from "react";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { useIntl } from "react-intl";
import { generatePath, useHistory } from "react-router-dom";
import { useCampaignWizardContext } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignWizardProvider";
import { CampaignTransitionalStatus } from "~/utilities/API/graphql";
import { useCampaignStatusUpdateMutation } from "jobber/campaigns/hooks/useCampaignStatusUpdateMutation/useCampaignStatusUpdateMutation";
import { CAMPAIGNS_CONTENT_EDIT_PATH } from "jobber/campaigns/constants";
import { messages } from "./messages";

export function AutomatedPauseAndEditModal({
  setCampaignActionsError,
}: {
  setCampaignActionsError: (message: string | undefined) => void;
}) {
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  const {
    pauseAndEditAutomatedCampaign: { open, setOpen, campaignId, setCampaignId },
  } = useCampaignWizardContext();

  const { campaignStatusUpdate } = useCampaignStatusUpdateMutation();

  const closeModal = () => {
    setOpen(false);
    setCampaignId(null);
  };

  const onConfirm = async () => {
    if (!campaignId) return;

    await campaignStatusUpdate({
      input: {
        campaignId: campaignId,
        input: {
          status: CampaignTransitionalStatus.AUTOMATIONINACTIVE,
        },
      },
      onError: () => {
        setCampaignActionsError(formatMessage(messages.error));
      },
      onCompleted: data => {
        if (data.campaignStatusUpdate.userErrors.length > 0) {
          setCampaignActionsError(formatMessage(messages.error));
        } else {
          push(generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId }));
          setCampaignActionsError(undefined);
          closeModal();
        }
      },
    });
  };

  return (
    <ConfirmationModal
      size="small"
      title={formatMessage(messages.title)}
      message={formatMessage(messages.text)}
      open={open}
      confirmLabel={formatMessage(messages.buttonLabel)}
      onConfirm={onConfirm}
      onCancel={closeModal}
      onRequestClose={closeModal}
    />
  );
}
