import { Button, Content } from "@jobber/components";
import React from "react";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";

export const AddMarketingToolToCartButton = ({
  addonName,
  addonIdentifier,
  setMarketingToolModalOpen,
}: {
  addonName: string;
  addonIdentifier: string;
  setMarketingToolModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Content>
      <Button
        label={formatMessage(messages.buttonTitle, { addonName: addonName })}
        ariaLabel={formatMessage(messages.buttonTitle, {
          addonName: addonName,
        })}
        size={"base"}
        type={"primary"}
        onClick={() => {
          addToCart(addonIdentifier);
          setMarketingToolModalOpen(true);
          Amplitude.TRACK_EVENT("Interacted with Experiment", {
            experiment: "add_marketing_tool_to_cart",
            interaction: "add_to_cart_cta_clicked",
            source: addonName,
          });
        }}
      />
    </Content>
  );
};

const addToCart = (addonIdentifier: string) => {
  const existingCookie = window.Cookies.get("add_marketing_tool_to_cart");
  if (existingCookie) {
    const decodedCookie = atob(existingCookie);
    let existingFeatures = decodedCookie.split(",");
    if (addonIdentifier === "marketing_suite") {
      return window.Cookies.set(
        "add_marketing_tool_to_cart",
        btoa("marketing_suite"),
        attributes,
      );
    } else if (!existingFeatures.includes(addonIdentifier)) {
      existingFeatures.push(addonIdentifier);
      existingFeatures = existingFeatures.filter(
        feature => feature != "marketing_suite",
      );
      return window.Cookies.set(
        "add_marketing_tool_to_cart",
        btoa(existingFeatures.join(",")),
        attributes,
      );
    }
  } else {
    window.Cookies.set(
      "add_marketing_tool_to_cart",
      btoa(addonIdentifier),
      attributes,
    );
  }
};

const attributes = {
  expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7), // 1 week expiration date
  domain: `.${window.location.hostname}`,
};
