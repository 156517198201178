import { strFormatDate } from "@jobber/components";
import { parseISO } from "date-fns";

/**
  Parses an iso8601 Datetime that should be parsed in local time due to not being UTC.
  @note Useful when consuming Stripe webhook event times which are unix dates.

  @param {string} date - The ISO8601 date string to be parsed.
**/
export function parseISO8601AsLocalDate(date: string): string {
  const datePart = date.replace(/T.*/, "");
  const parsedDate = parseISO(datePart);

  return strFormatDate(parsedDate);
}
