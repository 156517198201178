import { useIntl } from "react-intl";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { MutableRefObject } from "react";
import { Button } from "@jobber/components/Button";
import { Page } from "@jobber/components/Page";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { DataList } from "@jobber/components/DataList";
import { Glimmer } from "@jobber/components/Glimmer";
import { Banner } from "@jobber/components/Banner";
import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@jobber/components/Grid";
import type { ComboboxOption } from "@jobber/components/Combobox";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import type {
  ExperimentStatusMutation,
  IndustryComparison,
  MutationReviewReplyEditArgs,
  ReviewEdge,
  ReviewReplyEditInput,
  ReviewReplyEditMutation,
  ReviewsCommsSummaryQuery,
  ReviewsQuery,
} from "~/utilities/API/graphql";
import {
  MonitoringName,
  useSubscriptionMonitoring,
} from "utilities/useSubscriptionMonitoring";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import type { CallToActionRef } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import {
  CallToAction,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { ReviewsEngagement } from "~/jobber/marketingSuiteExpansion/ReviewsEngagement/ReviewsEngagement";
import { ReviewSummaryCard } from "./components/ReviewSummaryCard";
import styles from "./ReviewsPage.module.css";
import { messages } from "./messages";
import {
  REVIEWS_COMMS_SUMMARY_QUERY,
  REVIEW_REPLY_EDIT_MUTATION,
} from "./ReviewsPage.graphql";
import { ReviewCard } from "./components/ReviewCard/ReviewCard";
import { ReviewFilter } from "./components/ReviewFilter/ReviewFilter";
import { ReviewsSettings } from "./components/ReviewsSettings/ReviewsSettings";
import type { ReviewsError } from "./types";
import { EXPERIMENT_STATUS } from "./components/ReviewsSettings/ReviewsSettings.graphql";
import { Resources } from "./components/Resources";

interface ReviewsPageArgs {
  data: ReviewsQuery | undefined;
  loading: boolean;
  error: ReviewsError | undefined;
  setStatus: (status: ComboboxOption[]) => void;
  filtered: boolean;
  status: ComboboxOption[];
  defaultStatus?: ComboboxOption;
}

export function ReviewsPage({
  data,
  loading,
  error,
  setStatus,
  filtered,
  status,
  defaultStatus,
}: ReviewsPageArgs): JSX.Element {
  const { formatMessage } = useIntl();
  const [reviewReplyEdit] = useMutation<
    ReviewReplyEditMutation,
    MutationReviewReplyEditArgs
  >(REVIEW_REPLY_EDIT_MUTATION);

  useSubscriptionMonitoring({
    name: MonitoringName.GOOGLE_REVIEWS,
    isLoading: loading,
  });

  const { currentView, drawerActions } = useContext(
    ReviewsSettingsDrawerContext,
  );
  const errorRef = useRef<ReviewsError | undefined>();
  const handleError = useCallback((givenError: ReviewsError) => {
    errorRef.current = givenError;
  }, []);

  const { reviews, showGlimmer } = useMemo(() => {
    function sendReviewReply({ reviewId, comment }: ReviewReplyEditInput) {
      return reviewReplyEdit({ variables: { input: { reviewId, comment } } });
    }
    const reviewList =
      data?.reviews.reviews.edges?.map((edge: ReviewEdge) => ({
        id: edge.node.id,
        render: (
          <ReviewCard review={edge.node} sendReviewReply={sendReviewReply} />
        ),
      })) ?? [];

    if (error) {
      handleError(error);
    }

    return {
      reviews: reviewList,
      showGlimmer: loading || error !== undefined,
    };
  }, [
    data?.reviews.reviews.edges,
    error,
    loading,
    reviewReplyEdit,
    handleError,
  ]);
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const [modalOpen, setModalOpen] = useState(true);
  const hasDPN = !!data?.communicationSettings?.phoneNumber;

  return (
    <>
      <CallToAction
        ref={ctaRef}
        ctaName={"reviews_message_customization_modal"}
      >
        <Modal
          title={formatMessage(messages.CTAModalHeader)}
          open={modalOpen}
          onRequestClose={() => {
            dismissCTA(ctaRef)();
            setModalOpen(false);
            drawerActions?.closeSideDrawer();
          }}
          primaryAction={{
            variation: "learning",
            label: formatMessage(messages.CTAModalButton),
            onClick: () => {
              drawerActions?.goTo(DrawerView.MessageSettings);
              convertCTA(ctaRef)();
              setModalOpen(false);
            },
            loading: showGlimmer,
          }}
          dismissible
        >
          <Content>
            <Text>{formatMessage(messages.CTAModalBody)}</Text>
          </Content>
        </Modal>
      </CallToAction>

      {errorRef?.current?.credentialError && (
        <Banner
          type={"error"}
          primaryAction={{
            label: "Login",
            ariaLabel: "Refresh Google business account credentials",
            onClick: () => {
              window.location.href =
                "/automated_reviews/google_business/reconnect?post_oauth_redirect=%2Freviews";
            },
          }}
          dismissible={false}
        >
          {formatMessage(messages.credentialError)}
        </Banner>
      )}

      {errorRef?.current?.generalError && (
        <Banner type={"error"}>{formatMessage(messages.generalError)}</Banner>
      )}
      <div className={styles.pageContainer}>
        <Page
          title={formatMessage(messages.reviewsHeader)}
          width={"standard"}
          primaryAction={{
            label: formatMessage(messages.settingsActionLabel),
            type: "secondary",
            onClick: () => {
              drawerActions?.goTo(DrawerView.ManageSettings);
            },
          }}
        >
          {currentView && <ReviewsSettings showCustomizations={hasDPN} />}

          <Overview
            onError={handleError}
            averageRating={data?.reviews.averageRating}
            totalReviewCount={data?.reviews.totalReviewCount}
            industryComparison={data?.reviews.industryComparison}
          />

          <Grid gap>
            <Grid.Cell size={{ xs: 12, md: 8, lg: 9 }}>
              <DataList
                data={reviews}
                headers={{}}
                headerVisibility={{ xs: false }}
                title={formatMessage(messages.latestReviewsHeader)}
                loadingState={showGlimmer ? "initial" : "none"}
                filtered={filtered}
              >
                <DataList.Filters>
                  <ReviewFilter selected={status} setSelected={setStatus} />
                </DataList.Filters>
                <DataList.Layout size="xs">
                  {showGlimmer ? ReviewsLoadingLayout : ReviewLayout}
                </DataList.Layout>
                <DataList.EmptyState
                  type="empty"
                  message={formatMessage(messages.noReviews)}
                />
                <DataList.EmptyState
                  type="filtered"
                  message={formatMessage(messages.noUnansweredReviews)}
                  action={
                    <Button
                      label={formatMessage(messages.showAll)}
                      onClick={() =>
                        setStatus(defaultStatus ? [defaultStatus] : [])
                      }
                    />
                  }
                />
              </DataList>
            </Grid.Cell>
            <Grid.Cell size={{ xs: 12, md: 4, lg: 3 }}>
              {showGlimmer ? (
                <ReviewsLoadingLayout />
              ) : (
                <Resources showCTA={hasDPN} />
              )}
            </Grid.Cell>
          </Grid>
        </Page>
      </div>
    </>
  );

  function ReviewLayout({ id, render }: { id: string; render: JSX.Element }) {
    return <div key={id}>{render}</div>;
  }
}

function ReviewsLoadingLayout() {
  return (
    <div>
      <Content type={"section"} spacing={"smaller"}>
        <Glimmer size={"base"} width={100} />
        <Glimmer size={"base"} width={200} />
        <Glimmer size={"base"} />
        <Glimmer size={"base"} />
      </Content>
    </div>
  );
}

function Overview({
  onError,
  averageRating,
  totalReviewCount,
  industryComparison,
}: {
  onError?: (error: ReviewsError) => void;
  averageRating: number | undefined;
  totalReviewCount: number | undefined;
  industryComparison: IndustryComparison | undefined;
}): JSX.Element {
  const { data, loading, error } = useQuery<ReviewsCommsSummaryQuery>(
    REVIEWS_COMMS_SUMMARY_QUERY,
  );
  const { formatMessage } = useIntl();

  const { totalSmsSent = 0 } = data?.reviewsCommsSummary ?? {};

  const hasReviews = (totalReviewCount && totalReviewCount > 0) || false;
  const isLoading = loading || !!error || !totalReviewCount || !averageRating;

  if (error && onError) {
    onError({ generalError: true });
  }

  return (
    <>
      <div className={styles.heading}>
        <Heading level={3}>{formatMessage(messages.overviewHeader)}</Heading>
      </div>
      <div className={styles.row}>
        <ReviewSummaryCard
          header={formatMessage(messages.ratingsHeader)}
          showStarGroup={hasReviews}
          summaryValue={averageRating || 0}
          isLoading={isLoading}
        />
        <ReviewSummaryCard
          header={formatMessage(messages.reviewsTotalHeader)}
          showStarGroup={false}
          summaryValue={totalReviewCount || 0}
          isLoading={isLoading}
        />
        <ReviewSummaryCard
          header={formatMessage(messages.smsHeader)}
          showStarGroup={false}
          summaryValue={totalSmsSent}
          isLoading={isLoading}
        />
      </div>
      <ReviewsEngagement
        accountNumReviews={totalReviewCount || 0}
        industryComparison={industryComparison}
        isLoading={isLoading}
      />
    </>
  );
}

export function useExperiment(experimentName: string) {
  const [checkExperiment, { data, loading }] =
    useMutation<ExperimentStatusMutation>(EXPERIMENT_STATUS, {
      variables: { name: experimentName },
    });

  useEffect(() => {
    void checkExperiment();
  }, [checkExperiment]);

  return {
    loading: loading,
    inExperiment:
      !!data?.assignAndGetExperimentStatus?.experiment?.inExperiment,
  };
}
