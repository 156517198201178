import { gql } from "@apollo/client";

export const MANDATORY_CARD_ON_FILE_QUERY = gql`
  query jobberPaymentsSetting($id: JobberPaymentsSettingsName!) {
    jobberPaymentsSetting(id: $id) {
      enabled
    }
  }
`;

export const GET_CURRENT_USER_QUERY = gql`
  query getCurrentUser {
    user {
      id
      isAccountOwner
      isAdmin
    }
  }
`;
