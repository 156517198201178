import React, { useEffect, useState } from "react";
import { Banner } from "@jobber/components/Banner";
import { Content } from "@jobber/components/Content";
import { Spinner } from "@jobber/components/Spinner";
import { useIntl } from "react-intl";
import { TwilioComplianceEmbed } from "@twilio/twilio-compliance-embed";
import { UkKycComplianceRegistrationStatus } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration/components/UkKycComplianceRegistrationStatus/UkKycComplianceRegistrationStatus";
import { useUkKycRegistrationCreateOrContinue } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration/hooks/useUkKycRegistrationCreateOrContinue";
import { useUkKycRegistrationUpdate } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration/hooks/useUkKycRegistrationUpdate/useUkKycRegistrationUpdate";
import { redirectToTwoWayTextMessagingPage } from "jobber/settings/dedicatedPhoneNumber/utilities/redirectToTwoWayTextMessagingPage";
import type {
  UkKycRegistration,
  UkKycRegistrationEndUserTypes,
  UkKycRegistrationInquiry,
} from "~/utilities/API/graphql";
import { UkKycRegistrationStatuses } from "~/utilities/API/graphql";
import { UkKycComplianceRegistrationStart } from "./UkKycComplianceRegistrationStart";
import styles from "./UkKycComplianceRegistrationMain.module.css";
import { messages } from "../messages";

// eslint-disable-next-line max-statements
export function UkKycComplianceRegistrationMain(props: {
  startingRegistration?: UkKycRegistration;
}) {
  const [kycEndUserType, setEndUserType] = useState<
    UkKycRegistrationEndUserTypes | undefined
  >();
  const [kycRegistration, setRegistration] = useState<
    UkKycRegistration | undefined
  >();
  const { formatMessage } = useIntl();

  const { inquiry, registration, loading, error } =
    useUkKycRegistrationCreateOrContinue(kycEndUserType, kycRegistration);

  const submitRegistration = async (submittedEndUserType: string) => {
    const registrationEndUserType =
      submittedEndUserType.toUpperCase() as UkKycRegistrationEndUserTypes;

    // Whenever we have an end user type set, the create or continue hook will be called
    setEndUserType(registrationEndUserType);

    // Clear the current registration
    setRegistration(undefined);
  };

  // set end user type and kycRegistration when starting registration
  // is passed in from the loader
  useEffect(() => {
    if (props.startingRegistration?.endUserType) {
      setEndUserType(props.startingRegistration.endUserType);
      setRegistration(props.startingRegistration);
    }
  }, [props.startingRegistration]);

  if (error) {
    return (
      <Content>
        <Banner type="error">
          {formatMessage(messages.registrationCreateError)}
        </Banner>
      </Content>
    );
  }

  // If we start with rejected registration, we will render the rejected component
  // We must do this to allow the user to select the correct registration type or
  // view any existing errors if they do not change the registration type.
  if (kycRegistration?.status === UkKycRegistrationStatuses.TWILIO_REJECTED) {
    return (
      <UkKycRegistrationRejected
        submitRegistration={submitRegistration}
        promptMessage={formatMessage(
          messages.registrationRejectedPromptMessage,
        )}
        endUserType={kycRegistration.endUserType}
        loading={loading}
      />
    );
  }

  // When called without a registration, we will start the registration process
  if (kycRegistration == null && registration == null) {
    return (
      <UkKycComplianceRegistrationStart
        onClick={submitRegistration}
        loading={loading}
      />
    );
  }

  // When we have a registration from create or continue, we will render the appropriate component
  if (registration) {
    switch (registration.status) {
      case UkKycRegistrationStatuses.TWILIO_APPROVED:
        return <UkKycRegistrationApproved />;
      case UkKycRegistrationStatuses.TWILIO_REJECTED:
      case UkKycRegistrationStatuses.DRAFT:
        return inquiry?.inquiryId && inquiry?.inquirySessionToken ? (
          <UkKycTwilioEmbed twilioInquiry={inquiry} />
        ) : (
          <Content>
            <Banner type="error">
              {formatMessage(messages.fetchInquiryError)}
            </Banner>
          </Content>
        );
      case UkKycRegistrationStatuses.IN_REVIEW:
      case UkKycRegistrationStatuses.PENDING_REVIEW:
        return (
          <UkKycComplianceRegistrationStatus status={registration.status} />
        );
      default:
        return (
          <UkKycComplianceRegistrationStart
            onClick={submitRegistration}
            loading={loading}
          />
        );
    }
  } else {
    return <Spinner />;
  }
}

function UkKycRegistrationRejected({
  submitRegistration,
  promptMessage,
  endUserType,
  loading,
}: {
  submitRegistration: (endUserType: string) => void;
  promptMessage: string;
  endUserType?: string;
  loading: boolean;
}) {
  return (
    <Content>
      <Banner type="error">{promptMessage}</Banner>
      <UkKycStartRegistration
        submitRegistration={submitRegistration}
        endUserType={endUserType}
        loading={loading}
      />
    </Content>
  );
}

function UkKycStartRegistration({
  submitRegistration,
  endUserType,
  loading,
}: {
  submitRegistration: (endUserType: string) => void;
  endUserType?: string;
  loading: boolean;
}) {
  return (
    <Content>
      <UkKycComplianceRegistrationStart
        onClick={submitRegistration}
        endUserType={endUserType}
        loading={loading}
      />
    </Content>
  );
}

function UkKycRegistrationApproved() {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}

function UkKycTwilioEmbed(props: { twilioInquiry: UkKycRegistrationInquiry }) {
  const { ukKycRegistrationUpdate } = useUkKycRegistrationUpdate();

  async function handleOnCompleteEvent() {
    const payload = {
      input: {
        status: UkKycRegistrationStatuses.PENDING_REVIEW,
      },
    };
    await ukKycRegistrationUpdate(payload);
    redirectToTwoWayTextMessagingPage();
  }
  const { inquiryId, inquirySessionToken } = props.twilioInquiry;

  return (
    <div className={styles.twilioEmbed}>
      <TwilioComplianceEmbed
        inquiryId={inquiryId as string}
        inquirySessionToken={inquirySessionToken as string}
        onInquirySubmitted={handleOnCompleteEvent}
      />
    </div>
  );
}
