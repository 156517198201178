import React, { type ReactNode, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Breakpoints } from "@jobber/hooks/useResizeObserver";
import { Context } from "jobber/setupWizard/context/SetupWizardContext";
import styles from "./TwoColumn.module.css";
import { Routes } from "../../types";
import { determineThemeToDisplay } from "../../components/ThemePage/utils";

export interface TwoColumnProps {
  leftComponent: ReactNode;
  rightComponent: ReactNode;
  showColumnDivider?: boolean;
}

export function TwoColumn({
  leftComponent,
  rightComponent,
  showColumnDivider = true,
}: TwoColumnProps) {
  const { wizardData } = useContext(Context);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const isSmallScreen = window.innerWidth <= Breakpoints.small;

  const leftColumnDividerStyles = () => {
    if (params.has("back")) {
      return showColumnDivider && !isSmallScreen
        ? [styles.leftColumn, styles.backward].join(" ")
        : [styles.leftColumn, styles.hiddenDivider].join(" ");
    }
    return showColumnDivider && !isSmallScreen
      ? [styles.leftColumn, styles.forward].join(" ")
      : [styles.leftColumn, styles.hiddenDivider].join(" ");
  };

  const customRightColumnStyles = () => {
    if (location.pathname !== Routes.theme) {
      return "";
    }
    const theme = determineThemeToDisplay(wizardData);
    return theme === "dark" ? styles.themeStepDark : styles.themeStepLight;
  };

  const rightColumnDividerStyles = () => {
    const customStyles = customRightColumnStyles();

    return showColumnDivider
      ? [styles.rightColumn, customStyles].join(" ")
      : [styles.rightColumn, customStyles, styles.hiddenDivider].join(" ");
  };

  return (
    <div className={styles.twoColumnGrid}>
      <div data-testid="left-column" className={leftColumnDividerStyles()}>
        {leftComponent}
      </div>
      <div data-testid="right-column" className={rightColumnDividerStyles()}>
        {rightComponent}
      </div>
    </div>
  );
}
