import React from "react";
import { Button } from "@jobber/components/Button";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Link } from "@jobber/components/Link";
import AppMarketPlaceHeader from "@images/AppMarketPlaceHeader.svg";
import { MarketplaceNavDrawer } from "jobber/marketplace/components/MarketplaceNavDrawer";
import { decodeId } from "~/utilities/decodeId/decodeId";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import styles from "./MarketplaceTopNav.module.css";
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from "../../ANALYTICS_EVENTS";

interface MarketplaceTopNavProps {
  title: string;
  appId: string;
}

export function MarketplaceTopNav({ title, appId }: MarketplaceTopNavProps) {
  const { mediumAndUp, largeAndUp } = useBreakpoints();
  const attributionValue = (title || "app_marketplace").replace(/\s+/g, "-");
  const urlParams = `signup_attribution_name=app_marketplace&signup_attribution_value=${attributionValue}`;
  const privateMarketplaceURL = appId
    ? `/marketplace/${decodeId(appId)}`
    : "/marketplace";

  function handleStartTrialClick() {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.clickedFreeTrialCTA, {
      source: title
        ? ANALYTICS_PROPERTIES.appListingPageSource
        : ANALYTICS_PROPERTIES.marketplaceHomePageSource,
      title,
    });

    window.location.href = `/sign-up?${urlParams}`;
  }

  return (
    <div className={styles.marketplaceTopNav}>
      <div className={styles.marketplaceTopNavInterior}>
        <div className={styles.topNavGroup}>
          <a href="/app_marketplace">
            {largeAndUp ? (
              <img
                src={
                  "https://cdn.jobber.com/yr/logos/v1/logo_jobber_full-navy.svg"
                }
                alt={"Jobber logo"}
                className={styles.logo}
              />
            ) : (
              <img
                src={
                  "https://cdn.jobber.com/yr/logos/v1/logo_jobber_bug-navy.svg"
                }
                alt={"Jobber small logo"}
                className={styles.smallLogo}
              />
            )}
          </a>
          <div className={styles.marketPlaceImgContainer}>
            <a href="/app_marketplace">
              <img src={AppMarketPlaceHeader} alt={"App Marketplace"} />
            </a>
          </div>
        </div>
        {mediumAndUp ? (
          <div className={styles.topNavGroup}>
            <div className={styles.topNavLinksContainer}>
              <Link external url="/">
                Jobber.com Home
              </Link>

              <Link external url="https://getjobber.com/pricing/">
                Jobber Pricing
              </Link>
            </div>

            <div className={styles.topNavButtonsContainer}>
              <Button
                label={"Log In"}
                type={"secondary"}
                url={privateMarketplaceURL}
              />
              <Button
                label={"Start Free Trial"}
                onClick={handleStartTrialClick}
              />
            </div>
          </div>
        ) : (
          <MarketplaceNavDrawer
            privateMarketplaceURL={privateMarketplaceURL}
            onFreeTrialClick={handleStartTrialClick}
          />
        )}
      </div>
    </div>
  );
}
