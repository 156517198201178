import React from "react";
import {
  type Datum,
  type Point,
  type PointTooltip,
  ResponsiveLine,
} from "@nivo/line";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import { formatTick } from "jobber/dataVisualizations/utils/dataVisualizationsUtils";
import { HoverPoint } from "jobber/dataVisualizations/LineChart/HoverPoint";

interface LineChartProps {
  xTickValues?: string[];
  yTickValues: number | number[];
  data: Datum[];
  id?: string;
  tooltip?: PointTooltip;
  clickHandler?: (data: Point) => void;
  enableGridY?: boolean;
  lineColor?: string;
  xLegend?: string;
  yLegend?: string;
  yTickSuffix?: string;
  axisBottomFormatter?: (value: string) => string;
}

export function LineChart(props: LineChartProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalLineChart {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalLineChart({
  xTickValues,
  yTickValues,
  data,
  tooltip,
  id,
  clickHandler,
  enableGridY = false,
  lineColor,
  xLegend,
  yLegend,
  yTickSuffix,
  axisBottomFormatter,
}: LineChartProps) {
  const { theme, tokens } = useAtlantisTheme();
  const defaultChartColor =
    theme === "dark"
      ? tokens["color-base-purple--500"]
      : tokens["color-base-purple--700"];

  const lineChartData = [
    {
      id: "lineChartData",
      data,
    },
  ];

  return (
    <ResponsiveLine
      onClick={datum => {
        if (clickHandler) {
          clickHandler(datum);
        }
      }}
      data={lineChartData}
      colors={lineColor || defaultChartColor}
      lineWidth={2}
      enableCrosshair={false}
      enableGridX={false}
      enableGridY={enableGridY}
      enablePoints={false}
      gridYValues={yTickValues}
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "slices",
        "points",
        HoverPoint,
        "mesh",
      ]}
      defs={[
        {
          id: id ?? "gradient",
          type: "linearGradient",
          colors: [
            { offset: 0, color: defaultChartColor },
            { offset: 100, color: tokens["color-surface"] },
          ],
        },
      ]}
      fill={[{ match: "*", id: id ?? "gradient" }]}
      enableArea={true}
      margin={{
        top: xLegend ? 0 : tokens["space-larger"],
        right: tokens["space-base"],
        bottom: enableGridY ? tokens["space-large"] : tokens["space-largest"],
        left:
          tokens["space-larger"] +
          (!yTickSuffix ? tokens["space-smaller"] : tokens["space-small"]),
      }}
      theme={{
        axis: {
          legend: {
            text: {
              fill: "var(--color-text)",
              fontSize: tokens["typography--fontSize-small"],
            },
          },
          ticks: {
            line: {
              stroke: "rgb(221, 221, 221)",
            },
            text: {
              fill: "var(--color-text--secondary)",
              fontSize: tokens["typography--fontSize-small"],
            },
          },
        },
      }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: xLegend ? 4 : 0,
        tickPadding: tokens["space-small"],
        tickRotation: 0,
        tickValues: xTickValues,
        format: value =>
          axisBottomFormatter ? axisBottomFormatter(value) : value,
        legend: xLegend,
        legendOffset: xLegend ? 35 : 0,
        legendPosition: xLegend ? "middle" : undefined,
      }}
      axisLeft={{
        format: value => formatTick(value, { yTickSuffix }),
        tickValues: yTickValues,
        tickSize: yLegend ? 4 : 0,
        tickPadding: yLegend ? tokens["space-smallest"] : tokens["space-small"],
        tickRotation: 0,
        legend: yLegend,
        legendOffset: yLegend ? -32 : 0,
        legendPosition: yLegend ? "middle" : undefined,
      }}
      pointSize={8}
      pointColor={tokens["color-white"]}
      useMesh={true}
      tooltip={tooltip}
      role="application"
    />
  );
}
