import React, { useEffect } from "react";
import { Content } from "@jobber/components/Content";
import { FormatFile } from "@jobber/components/FormatFile";
import { type FileUpload, InputFile } from "@jobber/components/InputFile";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { UploadDestinationsEnum } from "~/utilities/API/graphql";
import { fetchInternalUploadParams } from "utilities/fetchUploadParams";
import type { HeaderImageFile } from "jobber/campaigns/types";
import { messages } from "./messages";

const MAX_IMAGE_SIZE = 5 * 1000 * 1000; // 5 MB

export enum HeaderImageUploadProgress {
  STARTED = 0,
  IN_PROGRESS = 0.5,
  COMPLETE = 1,
}

export const fileToFileUpload = (
  file?: HeaderImageFile,
): FileUpload | undefined => {
  if (!file) return;

  return {
    key: "",
    name: file.fileName || "",
    size: file.fileSize,
    type: file.contentType || "",
    progress: HeaderImageUploadProgress.COMPLETE,
    src: () => Promise.resolve(file.thumbnailUrl || file.url || ""),
  };
};

export function HeaderImageUpload({
  headerImage,
  setHeaderImage,
}: {
  headerImage?: HeaderImageFile;
  setHeaderImage: (file: HeaderImageFile | undefined, isDirty: boolean) => void;
}) {
  const { formatMessage } = useIntl();
  const headerImageUpload = fileToFileUpload(headerImage);
  const [headerImageUploadInProgress, setHeaderImageUploadInProgress] =
    React.useState<FileUpload | undefined>(undefined);

  useEffect(() => {
    if (!headerImageUpload) {
      setHeaderImageUploadInProgress(undefined);
    }
  }, [headerImageUpload]);

  /*
    If there is an upload in progress, we want to display the FileUpload with the
    progress amount. If there is no upload in progress, we want to display the file
    without the progress amount.
  */
  const headerImageToDisplay = headerImageUpload || headerImageUploadInProgress;

  const handleClearHeaderImage = () => {
    setHeaderImage(undefined, true);
  };

  const handleUploadProgress = (upload: FileUpload) => {
    setHeaderImageUploadInProgress(upload);
  };

  const handleUploadComplete = async (upload: FileUpload) => {
    setHeaderImage(
      {
        contentType: upload.type,
        fileName: upload.name,
        fileSize: upload.size,
        url: await upload.src(),
        uploadUrl: upload.uploadUrl + "/" + upload.key,
      },
      true,
    );
  };

  const fileValidator = (file: globalThis.File) => {
    if (file.size > MAX_IMAGE_SIZE) {
      return {
        code: "MAX_IMAGE_SIZE_ERROR",
        message: formatMessage(messages.uploadSizeError),
      };
    }
    return null;
  };

  return (
    <Content spacing="small" data-testid={"CampaignHeaderImageUpload"}>
      <Heading level={4}>{formatMessage(messages.uploadHeading)}</Heading>

      <Text size="small" variation="subdued">
        {formatMessage(messages.uploadSubheading)}
      </Text>

      {headerImageToDisplay ? (
        <FormatFile
          file={headerImageToDisplay}
          onDelete={handleClearHeaderImage}
        />
      ) : (
        <InputFile
          getUploadParams={fetchInternalUploadParams(
            UploadDestinationsEnum.UPLOADS,
          )}
          allowedTypes={"basicImages"}
          allowMultiple={false}
          onUploadStart={handleClearHeaderImage}
          onUploadComplete={handleUploadComplete}
          onUploadProgress={handleUploadProgress}
          validator={fileValidator}
          description={formatMessage(messages.uploadDescription)}
          buttonLabel={formatMessage(messages.uploadButton)}
        />
      )}
    </Content>
  );
}
