import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Chip,
  Chips,
  Content,
  Form,
  Heading,
  InputValidation,
} from "@jobber/components";
import {
  companyAgeArray,
  companySizeArray,
} from "jobber/setupWizard/components/constants";
import { Routes, type SetupWizardStepProps } from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import {
  generateLastSubmittedStepQandA,
  safelyExtractAnswer,
} from "jobber/setupWizard/utils";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";

export function BusinessPageForm({
  navigation,
  isSmallScreen,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [companyAge, setCompanyAge] = useState<string>(() => {
    return safelyExtractAnswer(
      "years_in_business",
      wizardData.questionsAndAnswers,
    );
  });
  const [companySize, setCompanySize] = useState<string>(
    wizardData.companySize ?? "",
  );
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const handleSubmit = async () => {
    if (!companySize || !companyAge) {
      if (!showValidationErrors) {
        setShowValidationErrors(true);
      }
      return;
    }

    const dataToSend = {
      companySize,
      questionsAndAnswers: [
        {
          question: "years_in_business",
          answer: companyAge,
        },
        generateLastSubmittedStepQandA(Routes.business),
      ],
    };

    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  function handleCompanySizeChange(size: string) {
    setCompanySize(size);
  }

  function handleCompanyAgeChange(age: string) {
    setCompanyAge(age);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Content spacing="large">
        <Content>
          <Heading level={5}>
            {formatMessage(messages.companySizePlaceholder)}
          </Heading>
          <div className={styles.chipsContainer}>
            <Content spacing="minuscule">
              <Chips
                onChange={handleCompanySizeChange}
                selected={companySize}
                type={"singleselect"}
                showSelectedSuffix={false}
              >
                {companySizeArray.map(option => {
                  return (
                    <Chip
                      key={option.value}
                      label={formatMessage(option.label)}
                      value={option.value}
                    />
                  );
                })}
              </Chips>
              {showValidationErrors && !companySize && (
                <InputValidation
                  message={formatMessage(messages.companySizeError)}
                />
              )}
            </Content>
          </div>
        </Content>
        <Content>
          <Heading level={5}>
            {formatMessage(messages.companyAgePlaceholder)}
          </Heading>
          <div className={styles.chipsContainer}>
            <Content spacing="minuscule">
              <Chips
                onChange={handleCompanyAgeChange}
                selected={companyAge}
                type={"singleselect"}
                showSelectedSuffix={false}
              >
                {companyAgeArray.map(option => {
                  return (
                    <Chip
                      key={option.value}
                      label={formatMessage(option.label)}
                      value={option.value}
                    />
                  );
                })}
              </Chips>
              {showValidationErrors && !companyAge && (
                <InputValidation
                  message={formatMessage(messages.companyAgeError)}
                />
              )}
            </Content>
          </div>
        </Content>
        <div className={styles.button}>
          <Button
            id={"businessPageSubmit"}
            fullWidth={isSmallScreen}
            label={navigation.nextCtaCopy}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}
