import React from "react";
import {
  InlineLabel,
  type InlineLabelColors,
} from "@jobber/components/InlineLabel";

type CompareResult = "positive" | "neutral" | "negative";

interface TrendLabelProps {
  value: number;
  /*
   * A custom compare function if you need to change the threshold or invert the scale
   * returns "positive", "neutral", or "negative"
   */
  compare?: (value: number) => CompareResult;
  /*
   * If true, inverts the color and indicator mapping (e.g., "negative" maps to green and down)
   */
  invertTrend?: boolean;
}

interface TrendStatusStyle {
  color: InlineLabelColors;
  indicator: string;
}

const upIndicator = "\u2191";
const downIndicator = "\u2193";

export const defaultTrendStatusMap: Record<CompareResult, TrendStatusStyle> = {
  positive: {
    color: "green",
    indicator: upIndicator,
  },
  neutral: {
    color: "greyBlue",
    indicator: "",
  },
  negative: {
    color: "red",
    indicator: downIndicator,
  },
};

export const invertedTrendStatusMap: Record<CompareResult, TrendStatusStyle> = {
  positive: {
    color: "red",
    indicator: upIndicator,
  },
  neutral: {
    color: "greyBlue",
    indicator: "",
  },
  negative: {
    color: "green",
    indicator: downIndicator,
  },
};

const defaultCompare = (value: number) => {
  const sign = Math.sign(value);

  return sign === 0 ? "neutral" : sign === 1 ? "positive" : "negative";
};

const TrendLabel = ({
  value,
  compare = defaultCompare,
  invertTrend = false,
}: TrendLabelProps) => {
  const trendStatusMap = invertTrend
    ? invertedTrendStatusMap
    : defaultTrendStatusMap;

  const trendStatus = trendStatusMap[compare(value)];

  const displayValue = new Intl.NumberFormat("en", { style: "percent" }).format(
    Math.abs(value) / 100,
  );

  return (
    <InlineLabel color={trendStatus.color}>
      {trendStatus.indicator
        ? trendStatus.indicator + "\u00A0" + displayValue
        : displayValue}
    </InlineLabel>
  );
};

export { TrendLabel };
