import { defineMessages } from "react-intl";

export const messages = defineMessages({
  appDisconnectModalTitle: {
    id: "marketplace.appDisconnectModalTitle",
    defaultMessage: "Disconnect {appName}",
    description: "Title for the app disconnect modal",
  },
  appDisconnectModalMessage: {
    id: "marketplace.appDisconnectModalMessage",
    defaultMessage:
      "Disconnecting will remove {appName} from your Jobber account. Data and settings stored in the app may be permanently deleted.",
    description: "Message for the app disconnect modal",
  },
  appDisconnectModalConfirmLabel: {
    id: "marketplace.appDisconnectModalConfirmLabel",
    defaultMessage: "Disconnect",
    description: "Label for the app disconnect modal confirm button",
  },
  appDisconnectedModalManageCustomFieldsTitle: {
    id: "marketplace.appDisconnectedModalManageCustomFieldsTitle",
    defaultMessage: "{appName} disconnected",
    description: "Label for the disconnected app",
  },
  appDisconnectedModalManageCustomFieldsDisconnectedMessage: {
    id: "marketplace.appDisconnectedModalManageCustomFieldsDisconnectedMessage",
    defaultMessage:
      "{appName} was successfully disconnected from your Jobber account.",
    description: "Message for managing custom fields after app disconnection",
  },
  appDisconnectedModalManageCustomFieldsInfoMessage: {
    id: "marketplace.appDisconnectedModalManageCustomFieldsInfoMessage",
    defaultMessage:
      "created by this app were automatically archived. If you no longer need them, you can delete them by managing custom fields.",
    description:
      "Message for managing custom fields info after app disconnection",
  },
  appDisconnectedModalManageCustomFieldsLabel: {
    id: "marketplace.appDisconnectedModalManageCustomFieldsLabel",
    defaultMessage: "Manage Custom Fields",
    description: "Label for managing custom fields",
  },
  appDisconnectedModalManageCustomFieldsCancelLabel: {
    id: "marketplace.appDisconnectedModalManageCustomFieldsCancelLabel",
    defaultMessage: "Got it",
    description: "Cancel label for managing custom fields",
  },
  appDisconnectedModalCustomFields: {
    id: "marketplace.appDisconnectedModalCustomFields",
    defaultMessage: "Custom Fields",
    description: "Custom Fields title",
  },
  sectionOurPicksTitle: {
    id: "marketplace.integrationList.sectionOurPicksTitle",
    defaultMessage: "Featured favorites",
    description: "List of suggested Apps",
  },
  sectionAllAppsTitle: {
    id: "marketplace.integrationList.sectionAllAppsTitle",
    defaultMessage: "All apps",
    description: "List of all Apps",
  },
  sectionConnectedAppsTitle: {
    id: "marketplace.integrationList.sectionConnectedAppsTitle",
    defaultMessage: "Connected apps",
    description: "List of connected Apps",
  },
  sectionResultsForTitle: {
    id: "marketplace.integrationList.sectionResultsForTitle",
    defaultMessage: "Results for",
    description: "List of apps based on search term",
  },
  sectionNoResultsTitle: {
    id: "marketplace.integrationList.sectionNoResultsTitle",
    defaultMessage:
      "Try searching for app names or features (e.g. Zapier, leads, reviews).",
    description: "No results found for search term",
  },
  sectionErrorMessage: {
    id: "marketplace.integrationList.sectionErrorMessage",
    defaultMessage: "Something went wrong. Try again later.",
    description: "Error message on API error",
  },
  nonAdminConnectMessage: {
    id: "marketplace.integrationList.nonAdminConnectMessage",
    defaultMessage: "Only account administrators can connect apps.",
    description: "Message for non admin users when app is not connected",
  },
  nonAdminConnectContactAdministrator: {
    id: "marketplace.integrationList.nonAdminConnectContactAdministrator",
    defaultMessage:
      "Share this page with your administrator or contact them directly to connect this app.",
    description: "Contact Administrator to connect this app",
  },
  nonAdminConnectPopoverMessage: {
    id: "marketplace.integrationList.nonAdminConnectPopoverMessage",
    defaultMessage:
      "Only account administrators can connect apps. Share this page with your administrator or contact them directly to connect this app.",
    description: "Popover Message for non admin users when app is connected",
  },
  nonAdminManageAppMessage: {
    id: "marketplace.integrationList.nonAdminManageAppMessage",
    defaultMessage: "Only account administrators can manage connected apps.",
    description: "Message for non admin users when app is connected",
  },
  changePlanModalTitle: {
    id: "marketplace.changePlanModal.title",
    defaultMessage: "Upgrade to connect {appName}",
    description: "Title for the change plan modal",
  },
  changePlanModalConfirmLabel: {
    id: "marketplace.changePlanModal.confirmLabel",
    defaultMessage: "See Plans",
    description: "Label for the change plan modal confirm button",
  },
  changePlanModalUpgradeMessage: {
    id: "marketplace.changePlanModal.upgradeMessage",
    defaultMessage:
      "Take your business to the next level and unlock even more powerful apps and integrations like {appName}.",
    description: "Message for the change plan modal",
  },
  integrationPageRequiredPlanLearnMore: {
    id: "marketplace.integrationPage.requiredPlanLearnMore",
    defaultMessage: "Learn more about Jobber Plans",
    description: "Learn more text for the integration page required plan link",
  },
  changePlanModalRequiredPlan: {
    id: "marketplace.changePlanModal.requiredPlan",
    defaultMessage:
      "Upgrade to a <b>Jobber {requiredPlan} plan</b> or higher to get started.",
    description: "Required Plan text for the change plan modal",
  },
  integrationPageRequiredPlan: {
    id: "marketplace.integrationPage.requiredPlan",
    defaultMessage:
      "A Jobber <b>{requiredPlan} or higher</b> plan is required to connect this app.",
    description: "Required Plan text for the integration page",
  },
});
