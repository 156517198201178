import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancelButton: {
    id: "campaigns.bottombar.cancel",
    defaultMessage: "Cancel",
    description: "Cancel Button",
  },
  backButton: {
    id: "campaigns.bottombar.back",
    defaultMessage: "Back",
    description: "Back Button",
  },
  continueToContent: {
    id: "campaigns.bottombar.continueToContent",
    defaultMessage: "Continue to Content",
    description: "Continue to Content Button",
  },
  continue: {
    id: "campaigns.bottombar.continue",
    defaultMessage: "Continue",
    description: "Continue Button for mobile",
  },
  reviewCampaign: {
    id: "campaigns.bottombar.reviewcampaign",
    defaultMessage: "Review Campaign",
    description: "Review Campaign Button",
  },
  sendCampaign: {
    id: "campaigns.bottombar.sendCampaign",
    defaultMessage: "Send",
    description: "Send campaign Button",
  },
  saveDraft: {
    id: "campaigns.bottombar.saveDraft",
    defaultMessage: "Save Draft",
    description: "Save Draft Button",
  },
  savedDraftSuccessToast: {
    id: "campaigns.bottombar.saveDraftSuccessToast",
    defaultMessage: "Saved draft",
    description: "Saved Draft Success Toast",
  },
  verifyFromEmail: {
    id: "campaigns.bottombar.verifyFromEmail",
    defaultMessage: "Verify Email",
    description: "Verify From Email Button",
  },
  sendATestLabel: {
    id: "campaigns.campaignsbreadcrumb.sendATestLabel",
    defaultMessage: "Send a Test",
    description: "Send a Test label",
  },
});
