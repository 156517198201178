import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/messages";
import { SupportedCriteriaTypes } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/types";
import type { IntlSelectOption } from "jobber/campaigns/views/CampaignRecipientsPage/components/IntlSelect/IntlSelect";

export const DEFAULT_OPTION: IntlSelectOption = {
  label: messages.pleaseSelectCriterion,
  value: "",
};
export const LINE_ITEMS_OPTION: IntlSelectOption = {
  label: messages.lineItemsCriterion,
  value: SupportedCriteriaTypes.LINE_ITEMS,
};
export const CLIENT_TAGS_OPTION: IntlSelectOption = {
  label: messages.clientTagsCriterion,
  value: SupportedCriteriaTypes.CLIENT_TAGS,
};

export const JOB_TYPE_RECURRING_OPTION: IntlSelectOption = {
  label: messages.jobTypeRecurringCriterion,
  value: "JobTypeRecurringCriteria",
};

export const JOB_TYPE_ONE_OFF_OPTION: IntlSelectOption = {
  label: messages.jobTypeOneOffCriterion,
  value: "JobTypeOneOffCriteria",
};

export const JOB_STATUS_OPTION: IntlSelectOption = {
  label: messages.jobStatusCriterion,
  value: SupportedCriteriaTypes.JOB_STATUS,
};

export const CLIENT_TYPE_OPTION: IntlSelectOption = {
  label: messages.clientTypeCriterion,
  value: SupportedCriteriaTypes.CLIENT_TYPE,
};
