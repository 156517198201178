export enum DISPUTE_CATEGORIES {
  CHARGEBACK = "Chargeback",
  INQUIRY = "Inquiry",
  UNKNOWN = "Unknown",
}

export enum DISPUTE_REASONS {
  BANK_CANNOT_PROCESS = "Bank cannot process",
  CHECK_RETURNED = "Check returned",
  CREDIT_NOT_PROCESSED = "Credit not processed",
  CUSTOMER_INITIATED = "Customer initiated",
  DEBIT_NOT_AUTHORIZED = "Debit not authorized",
  DUPLICATE = "Duplicate",
  FRAUDULENT = "Fraudulent",
  GENERAL = "General",
  INCORRECT_ACCOUNT_DETAILS = "Incorrect account details",
  INSUFFICIENT_FUNDS = "Insufficient funds",
  PRODUCT_NOT_RECEIVED = "Product not received",
  PRODUCT_UNACCEPTABLE = "Product unacceptable",
  SUBSCRIPTION_CANCELED = "Subscription canceled",
  UNKNOWN_REASON = "Unknown reason",
  UNRECOGNIZED = "Unrecognized",
}

export enum DISPUTE_STATUSES {
  CHARGE_REFUNDED = "Charge refunded",
  LOST = "Lost",
  NEEDS_RESPONSE = "Needs response",
  UNDER_REVIEW = "Under review",
  UNKNOWN = "Unknown",
  WARNING_CLOSED = "Closed",
  WARNING_NEEDS_RESPONSE = "Needs response",
  WARNING_UNDER_REVIEW = "Under review",
  WON = "Won",
}
