/* eslint-disable max-statements */
import { generatePath, useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_REVIEW_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
} from "jobber/campaigns/constants";
import { CampaignStatus } from "~/utilities/API/graphql";
import type { CommsCampaignReviewQuery } from "~/utilities/API/graphql";
import { useHandleSend } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useHandleSend/useHandleSend";
import { useActivateAutomatedCampaign } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useActivateAutomatedCampaign/useActivateAutomatedCampaign";
import { useHandleMarkDemoCampaignAutomationActive } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useHandleMarkDemoCampaignAutomationActive/useHandleMarkDemoCampaignAutomationActive";
import { useHandleMarkDemoCampaignSent } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useHandleMarkDemoCampaignSent/useHandleMarkDemoCampaignSent";
import { useSendTestCampaignActions } from "jobber/campaigns/hooks/useSendTestCampaignActions/useSendTestCampaignActions";

export enum CampaignReviewErrorState {
  AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR",
  EMPTY_CLIENT_SEGMENT = "EMPTY_CLIENT_SEGMENT",
  GENERIC_ERROR = "GENERIC_ERROR",
  SCHEDULE_INVALID = "SCHEDULE_INVALID",
}
interface useCampaignsReviewActionsArgs {
  campaignId: string;
  campaign: CommsCampaignReviewQuery["commsCampaign"];
  totalCount: number;
  toggleEmailVerificationModal: () => void;
  loading: boolean;
  checkIsFormValid: () => boolean;
  timeoutError: boolean;
  hasDemoExperience?: boolean;
  userEmail: string;
  setIsSendATestModalOpen: (newValue: boolean) => void;
  setIsSendATestModalInErrorState: (newValue: boolean) => void;
}

export function useCampaignsReviewActions({
  campaignId,
  campaign,
  totalCount,
  toggleEmailVerificationModal,
  loading,
  checkIsFormValid,
  timeoutError,
  hasDemoExperience,
  userEmail,
  setIsSendATestModalOpen,
  setIsSendATestModalInErrorState,
}: useCampaignsReviewActionsArgs) {
  const history = useHistory();
  const [errorState, setErrorState] = useState<CampaignReviewErrorState | null>(
    null,
  );
  const [actionLoading, setActionLoading] = useState(false);

  const handleTestEmailError = useCallback(() => {
    setIsSendATestModalOpen(true);
    setIsSendATestModalInErrorState(true);
  }, [setIsSendATestModalOpen, setIsSendATestModalInErrorState]);

  function redirectToEdit(): void {
    const contentPath = generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
      campaignId: campaignId,
    });

    history.replace(contentPath);
  }

  function validateCampaignForSending(): boolean {
    const isFromEmailVerified = campaign?.fromEmailAddress?.isValidatedEmail;

    if (
      !timeoutError &&
      !campaign?.isAutomated &&
      (totalCount <= 0 ||
        !campaignId ||
        campaign?.status !== CampaignStatus.DRAFT)
    ) {
      setErrorState(CampaignReviewErrorState.EMPTY_CLIENT_SEGMENT);
      return false;
    }

    if (!isFromEmailVerified) {
      toggleEmailVerificationModal();
      return false;
    }

    return checkIsFormValid();
  }

  const { handleSend } = useHandleSend({
    campaignId,
    totalCount,
  });

  const { activateAutomatedCampaign } = useActivateAutomatedCampaign(
    campaignId,
    campaign?.status,
  );

  const { handleMarkDemoCampaignAutomationActive } =
    useHandleMarkDemoCampaignAutomationActive(campaignId, campaign?.status);

  const { handleMarkDemoCampaignSent } = useHandleMarkDemoCampaignSent(
    campaignId,
    campaign?.status,
  );

  async function onNext(scheduledAt?: Date) {
    if (loading || actionLoading) {
      return;
    }
    setActionLoading(true);

    if (!validateCampaignForSending()) {
      setActionLoading(false);
      return;
    }

    try {
      if (hasDemoExperience) {
        if (campaign?.isAutomated) {
          await handleMarkDemoCampaignAutomationActive();
        } else {
          await handleMarkDemoCampaignSent(scheduledAt);
        }
      } else if (campaign?.isAutomated) {
        await activateAutomatedCampaign();
      } else {
        await handleSend(scheduledAt);
      }
    } catch (error) {
      if (scheduledAt) {
        // The schedule modal handles its own error state
        throw error;
      }
      setErrorState(error.message as CampaignReviewErrorState);
    } finally {
      setActionLoading(false);
    }
  }

  function onCancel() {
    const contentPath = generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
      campaignId: campaignId,
    });

    history.replace(contentPath);
  }

  const { onSendTestEmail, onSendTestEmailForDemo } =
    useSendTestCampaignActions({
      userEmail,
      saveCampaign: async () => {
        // Skip saving the campaign because this review step does not have any opportunity to edit it.
        return campaignId;
      },
      loading,
      setIsErrorState: isError => {
        // This state is used when the user tries to send a test email while the campaign is still loading.
        if (isError) {
          setErrorState(CampaignReviewErrorState.GENERIC_ERROR);
          return;
        }
        setErrorState(null);
      },
      setActionLoading,
      handleError: handleTestEmailError,
      navigationPath: CAMPAIGNS_REVIEW_PATH,
    });

  return {
    onCancel,
    onNext,
    validateCampaignForSending,
    redirectToEdit,
    setErrorState,
    errorState,
    actionLoading,
    onSendTestEmail,
    onSendTestEmailForDemo,
  };
}
