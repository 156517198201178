import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "campaigns.validationEmailModal.title",
    defaultMessage: "Check your inbox",
    description: "Validation Email Modal Title",
  },
  mainText: {
    id: "campaigns.validationEmailModal.mainText",
    defaultMessage:
      "Enter the verification code we sent to <b>{email}</b>. This step is to make sure this email address belongs to you.",
    description: "Validation Email Modal Main Text",
  },
  resendValidationEmailFullText: {
    id: "campaigns.validationEmailModal.resendVerificationEmailFull",
    defaultMessage:
      "Don't see it in your email? Check your spam folder or <button>resend the verification email</button>.",
    description: "Validation Email Modal Resend Full Text",
  },
  validationCodePlaceholder: {
    id: "campaigns.validationEmailModal.verificationCodePlaceholder",
    defaultMessage: "Verification code",
    description: "Validation Email Modal Verification Code Placeholder",
  },
  resendValidationEmailPartialText: {
    id: "campaigns.validationEmailModal.resendVerificationEmailPartialText",
    defaultMessage: "resend the verification email",
    description: "Verification Email Modal Resend Partial Text",
  },
  resentVerificationEmailSuccess: {
    id: "campaigns.resentVerificationEmail.success",
    defaultMessage: "Re-sent verification email",
    description: "Resent Verification Email Success",
  },
});
