import React, { type Dispatch, type SetStateAction, useContext } from "react";
import { Button } from "@jobber/components/Button";
import { Card } from "@jobber/components/Card";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { InlineLabel } from "@jobber/components/InlineLabel";
import type { IconNames } from "@jobber/design";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { PurchaseFormContext } from "jobber/billing/hooks/PurchaseFormContext";
import { hasStoredPaymentDetails } from "jobber/billing/components/EditBillingInfo/utils";
import { PaymentPreviewDisplay } from "./components/PaymentPreviewDisplay/PaymentPreviewDisplay";
import { PaymentDetailsDisplay } from "./components/PaymentDetailsDisplay";
import { PaymentDetailsEdit } from "./components/PaymentDetailsEdit";
import styles from "./PaymentDetails.module.css";
import type {
  StoredPaymentDetails,
  UpdatedPaymentDetailsType,
} from "./PaymentDetails.d";
import { messages } from "./messages";

export interface PaymentDetailsProps {
  isPurchasing: boolean;
  showPreview: boolean;
  shouldShowPaymentDetailsDisplay: boolean;
  storedPaymentDetails: StoredPaymentDetails;
  updatedPaymentDetails: UpdatedPaymentDetailsType;
  trackingDetails: TrackingDetails;
  setUpdatedPaymentDetails: React.Dispatch<
    React.SetStateAction<UpdatedPaymentDetailsType>
  >;
  setShouldShowPaymentDetailsDisplay: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setRecurlyFieldsInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  onValidationError(error: FieldErrorState): void;
  clearValidationErrors(fieldNames: string[]): void;
}

export function PaymentDetails(props: PaymentDetailsProps) {
  const {
    shouldShowPaymentDetailsDisplay,
    isPurchasing,
    showPreview,
    storedPaymentDetails,
    updatedPaymentDetails,
    trackingDetails,
    setUpdatedPaymentDetails,
    setShouldShowPaymentDetailsDisplay,
    setRecurlyFieldsInitialized,
    onValidationError,
    clearValidationErrors,
  } = props;

  if (showPreview && shouldShowPaymentDetailsDisplay) {
    return (
      <PaymentPreviewRow
        storedPaymentDetails={storedPaymentDetails}
        hasPaymentError={!!storedPaymentDetails.hasPaymentError}
        onClickAction={setShouldShowPaymentDetailsDisplay}
      />
    );
  }
  return (
    <Content>
      <Card
        header={
          <PaymentDetailsHeader
            isViewing={shouldShowPaymentDetailsDisplay}
            storedPaymentDetails={storedPaymentDetails}
            onClickAction={setShouldShowPaymentDetailsDisplay}
            clearValidationErrors={clearValidationErrors}
            setRecurlyFieldsInitialized={setRecurlyFieldsInitialized}
          />
        }
      >
        {shouldShowPaymentDetailsDisplay ? (
          <PaymentDetailsDisplay storedPaymentDetails={storedPaymentDetails} />
        ) : (
          <PaymentDetailsEdit
            updatedPaymentDetails={updatedPaymentDetails}
            trackingDetails={trackingDetails}
            setUpdatedPaymentDetails={setUpdatedPaymentDetails}
            setRecurlyFieldsInitialized={setRecurlyFieldsInitialized}
            onValidationError={onValidationError}
            isPurchasing={isPurchasing}
          />
        )}
      </Card>
    </Content>
  );
}

function PaymentDetailsHeader({
  storedPaymentDetails,
  isViewing,
  onClickAction,
  clearValidationErrors,
  setRecurlyFieldsInitialized,
}: {
  storedPaymentDetails: StoredPaymentDetails;
  isViewing: boolean;
  onClickAction: Dispatch<SetStateAction<boolean>>;
  clearValidationErrors(fieldNames: string[]): void;
  setRecurlyFieldsInitialized: Dispatch<SetStateAction<boolean>>;
}) {
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);
  const { buttonLabel, buttonAriaLabel, buttonIcon } =
    getPaymentUpdateButtonInfo();

  return (
    <div className={styles.paymentDetailsHeaderContainer}>
      <Heading level={4}>{formatMessage(messages.cardTitle)}</Heading>

      {isViewing && !!storedPaymentDetails.hasPaymentError && (
        <div className={styles.paymentDetailsErrorLabelAndEditButton}>
          <div className={styles.paymentDetailsErrorLabel}>
            <InlineLabel color="red">
              {formatMessage(messages.paymentDetailsError)}
            </InlineLabel>
          </div>
        </div>
      )}

      {hasStoredPaymentDetails(storedPaymentDetails) && !submitting && (
        <Button
          ariaLabel={buttonAriaLabel}
          type="tertiary"
          label={buttonLabel}
          icon={buttonIcon}
          iconOnRight={isViewing}
          size="small"
          disabled={submitting}
          onClick={onPaymentUpdateButtonClick}
        />
      )}
    </div>
  );

  function getPaymentUpdateButtonInfo(): {
    buttonLabel: string;
    buttonAriaLabel: string;
    buttonIcon: IconNames;
  } {
    if (isViewing) {
      return {
        buttonLabel: formatMessage(messages.editButton),
        buttonIcon: "arrowRight",
        buttonAriaLabel: formatMessage(messages.editButtonAriaLabel),
      };
    }

    return {
      buttonLabel: formatMessage(messages.cancelButton),
      buttonIcon: "arrowLeft",
      buttonAriaLabel: formatMessage(messages.cancelButtonAriaLabel),
    };
  }

  function onPaymentUpdateButtonClick() {
    if (!isViewing) {
      clearValidationErrors(["cardNumber", "month", "year", "cvc"]);
      setRecurlyFieldsInitialized(false);
    }

    onClickAction(!isViewing);
  }
}

function PaymentPreviewRow({
  storedPaymentDetails,
  hasPaymentError,
  onClickAction,
}: {
  storedPaymentDetails: StoredPaymentDetails;
  hasPaymentError: boolean;
  onClickAction: Dispatch<SetStateAction<boolean>>;
}) {
  const { formatMessage } = useIntl();
  const { submitting } = useContext(PurchaseFormContext);

  return (
    <div className={styles.paymentPreviewHeaderContainer}>
      <PaymentPreviewDisplay storedPaymentDetails={storedPaymentDetails} />

      <div className={styles.paymentDetailsErrorLabelAndEditButton}>
        {hasPaymentError && (
          <div className={styles.paymentDetailsErrorLabel}>
            <InlineLabel color="red">
              {formatMessage(messages.paymentDetailsError)}
            </InlineLabel>
          </div>
        )}
        <Button
          type="tertiary"
          label={formatMessage(messages.editPaymentButton)}
          size="small"
          disabled={submitting}
          onClick={() => onClickAction(false)}
        />
      </div>
    </div>
  );
}
