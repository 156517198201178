import React from "react";
import type { AiAssistantSimplePlot } from "~/utilities/API/graphql";
import { LineChart } from "jobber/dataVisualizations/LineChart";
import { CopilotChartTooltip } from "./CopilotChartTooltip";
import { computeAbbreviatedTimePeriod } from "../../utils";

interface CopilotLineChartProps {
  parameters: AiAssistantSimplePlot;
}

export function CopilotLineChart({ parameters }: CopilotLineChartProps) {
  const { dataPoints, xAxisLabel, xCategories, yAxisLabel } = parameters;

  const xTickValues = xCategories.map(computeAbbreviatedTimePeriod);
  const data = xTickValues.map((x, index) => ({
    x,
    y: dataPoints[index] ?? 0,
  }));
  const valuesToShow = data.map((value, idx) =>
    idx === 0 || idx === data.length - 1 ? value.x : "",
  );

  return (
    <LineChart
      axisBottomFormatter={value =>
        valuesToShow.includes(value) ? computeAbbreviatedTimePeriod(value) : ""
      }
      data={data}
      xLegend={xAxisLabel}
      id="copilot-line-chart"
      xTickValues={xTickValues}
      enableGridY
      yTickValues={6}
      yLegend={yAxisLabel}
      tooltip={({ point }) => (
        <CopilotChartTooltip
          xValue={point.data.x}
          yValue={point.data.y}
          xAxisLabel={xAxisLabel}
          yAxisLabel={yAxisLabel}
        />
      )}
    />
  );
}
