import React, { forwardRef, useState } from "react";
import { Content } from "@jobber/components/Content";
import { FormatFile } from "@jobber/components/FormatFile";
import {
  type FileUpload,
  InputFile,
  updateFiles,
} from "@jobber/components/InputFile";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { DirectUploadDestinations } from "~/utilities/API/graphql";
import {
  completeActiveStorageFileDirectUpload,
  fetchActiveStorageUploadParams,
} from "utilities/fetchUploadParams";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import type { RichTextContentEditorRef } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor";
import { messages } from "./messages";

const MAX_IMAGE_SIZE = 5 * 1000 * 1000; // 5 MB

interface ImageUploaderProps {
  setIsImageUploadModalOpen?: (isOpen: boolean) => void;
  isHeaderImage: boolean;
}

export function ImageUploaderInternal(
  { setIsImageUploadModalOpen, isHeaderImage }: ImageUploaderProps,
  ref: React.Ref<RichTextContentEditorRef>,
) {
  const { formatMessage } = useIntl();
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [completedUploads, setCompletedUploads] = useState(0);
  const [headerImageUploaded, setHeaderImageUploaded] = useState(false);
  const { campaignContent } = useCampaignWizardContext();
  const headerImageKey = "header-image";

  function handleUpload(file: FileUpload) {
    setFiles(oldFiles => updateFiles(file, oldFiles));
  }

  const handleUploadComplete = async (upload: FileUpload) => {
    setCompletedUploads(prevCount => prevCount + 1);

    if (completedUploads === files.length && setIsImageUploadModalOpen) {
      setIsImageUploadModalOpen(false);
    }

    const data = await completeActiveStorageFileDirectUpload({
      key: upload.key,
    });

    if (ref && "current" in ref) {
      ref.current?.insertImage(
        data?.directUploadComplete?.directUpload?.url || "",
        isHeaderImage,
      );
    }

    campaignContent.setImageIds([
      ...(campaignContent.imageIds || []),
      data?.directUploadComplete?.directUpload?.id || "",
    ]);

    if (isHeaderImage) {
      setHeaderImageUploaded(true);
      const updatedFile = { ...upload, key: headerImageKey };
      setFiles(oldFiles => updateFiles(updatedFile, oldFiles));
    }
  };

  const fileValidator = (file: globalThis.File) => {
    if (file.size > MAX_IMAGE_SIZE) {
      return {
        code: "MAX_IMAGE_SIZE_ERROR",
        message: formatMessage(messages.uploadSizeError),
      };
    }
    return null;
  };

  return (
    <Content spacing="small" data-testid={`$CampaignImageUpload`}>
      {isHeaderImage && (
        <Heading level={4}>{formatMessage(messages.uploadHeading)}</Heading>
      )}

      {isHeaderImage && (
        <Text size="small" variation="subdued">
          {formatMessage(messages.uploadSubheading)}
        </Text>
      )}

      <InputFile
        getUploadParams={fetchActiveStorageUploadParams(
          DirectUploadDestinations.PUBLIC_IMAGES,
        )}
        allowedTypes={"basicImages"}
        allowMultiple={!isHeaderImage}
        onUploadStart={handleUpload}
        onUploadComplete={handleUploadComplete}
        onUploadProgress={handleUpload}
        validator={fileValidator}
        description={formatMessage(messages.uploadDescription)}
        buttonLabel={formatMessage(messages.uploadButton)}
      />

      {files.map(file => {
        if (!isHeaderImage || !headerImageUploaded) {
          return <FormatFile file={file} key={file.key} />;
        }
      })}
    </Content>
  );
}

export const ImageUploader = forwardRef(ImageUploaderInternal);
