import { defineMessages } from "react-intl";

export const messages = defineMessages({
  onlinePaymentSettingsTitle: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.title",
    defaultMessage: "{object} Payment Settings",
    description: "Title for the online payments settings component",
  },

  onlinePaymentSettingsSecondaryText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.secondaryText",
    defaultMessage:
      "Updating payment options on this {object} won't change your {link}",
    description: "Secondary Text for the online payments settings component",
  },

  onlinePaymentSettingsLinkText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.linkText",
    defaultMessage: "default payment preferences",
    description: "link Text for the online payments settings component",
  },

  onlinePaymentSettingsNotEnabledText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.notEnabledText",
    defaultMessage:
      "Client Hub {object} are currently disabled in {link} Turn {object} ON in settings to accept payments based on the settings above.",
    description:
      "Text for the online payments settings component notEnabled text",
  },

  onlinePaymentSettingsNotEnabledLinkText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.notEnabledLinkText",
    defaultMessage: "Jobber Payments Settings.",
    description:
      "link Text for the online payments settings component notEnabled text",
  },

  onlinePaymentSettingsPartialPaymentToggleText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.partialPaymentToggleText",
    defaultMessage: "Accept partial payments",
    description: "text of Partial Payment Toggle",
  },

  onlinePaymentSettingsCardPaymentToggleText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.cardPaymentToggleText",
    defaultMessage: "Accept card payments",
    description: "text of Card Payment Toggle",
  },

  onlinePaymentSettingsAchPaymentToggleText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.achPaymentToggleText",
    defaultMessage: "Accept bank payments (ACH)",
    description: "text of ACH bank Payment Toggle",
  },

  onlinePaymentSettingsAchPaymentDisabledText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.achPaymentDisabledText",
    defaultMessage:
      "Bank payments are not available when you require a card saved on file",
    description: "text of ACH bank Payment message if disabled",
  },
  onlinePaymentSettingsMandatoryPaymentMethodOnFileText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.mandatoryPaymentMethodOnFileText",
    defaultMessage: "Require a payment method to be saved on file",
    description: "text of Require a payment method to be saved on file",
  },
  mandatoryPaymentMethodOnFilePopoverText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.mandatoryPaymentMethodOnFilePopoverText",
    defaultMessage:
      "By making it mandatory to save a payment method on file, you save time on billing, streamline the payment process, and minimize delays.",
    description: "Popover text for mandatory payment method on file",
  },
  mandatoryPaymentMethodOnFilePopoverLinkText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.mandatoryPaymentMethodOnFilePopoverLinkText",
    defaultMessage: "Learn more in Help Center",
    description: "Link text for mandatory payment method on file popover",
  },
  mandatoryPaymentMethodOnFileHelpButtonAriaLabel: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.mandatoryPaymentMethodOnFileHelpButtonAriaLabel",
    defaultMessage: "Learn more about mandatory payment method on file",
    description: "Aria label for the help button",
  },
  onlinePaymentSettingsMandatoryPaymentMethodOnFileDisabledText: {
    id: "onlinePaymentsToggle.onlinePaymentSettings.mandatoryPaymentMethodOnFileDisabledText",
    defaultMessage: "You must enable a payment method above to use this option",
    description: "text of mandatory payment method on file message if disabled",
  },
});
