import type { AutomationRuleWithParsedConditions } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import {
  ActionTask,
  CreateRuleConditionTypes,
  DateField,
  ModelType,
  TriggerTask,
  TriggerTime,
  TriggerTimeForQuotes,
} from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import { Template } from "~/utilities/API/graphql";

/* eslint-disable @typescript-eslint/naming-convention */
export const pastClientsAutomationRule: AutomationRuleWithParsedConditions = {
  action: {
    task: ActionTask.CAMPAIGN_DELIVERY,
    arguments: {},
  },
  actionedCount: 0,
  active: true,
  condition: {
    task: "condition",
    conditions: [
      {
        task: "condition",
        arguments: {
          type: CreateRuleConditionTypes.TextOnly,
          fields: { text: "and only if client has no upcoming work scheduled" },
        },
        isNonDeletable: true,
      },
    ],
  },
  id: "",
  name: "CampaignPastClientsAutomation",
  trigger: {
    task: TriggerTask.TIME_SINCE_LAST_JOB,
    arguments: {
      time: TriggerTime.SIX_MONTHS_IN_HOURS,
      model_type: ModelType.PAST_CLIENTS_CAMPAIGN,
    },
  },
};

export const quoteSentLeadsAutomationRule: AutomationRuleWithParsedConditions =
  {
    action: {
      task: ActionTask.CAMPAIGN_DELIVERY,
      arguments: {},
    },
    actionedCount: 0,
    active: true,
    condition: {
      task: "condition",
      conditions: [
        {
          task: "condition",
          arguments: {
            type: CreateRuleConditionTypes.ClientLeadStatus,
            fields: { isLead: true },
          },
          isNonDeletable: true,
        },
        {
          task: "condition",
          arguments: {
            type: CreateRuleConditionTypes.QuoteStatus,
            fields: {
              status: "archived",
              contains: true,
            },
          },
          isNonDeletable: true,
        },
      ],
    },
    id: "",
    name: "CampaignLostLeadsAutomation",
    trigger: {
      task: TriggerTask.AFTER_DATE,
      arguments: {
        time: TriggerTime.SIX_MONTHS_IN_HOURS,
        date_field: DateField.SENT_AT,
        model_type: ModelType.QUOTE,
      },
    },
  };

export const closeOnPendingQuotesAutomationRule: AutomationRuleWithParsedConditions =
  {
    action: {
      task: ActionTask.CAMPAIGN_DELIVERY,
      arguments: {},
    },
    actionedCount: 0,
    active: true,
    condition: {
      task: "condition",
      conditions: [
        {
          task: "condition",
          arguments: {
            type: CreateRuleConditionTypes.CloseOnPendingQuotesQuoteStatus,
            fields: {
              status: "awaiting_response",
              contains: true,
            },
          },
          isNonDeletable: true,
        },
      ],
    },
    id: "",
    name: "CampaignPendingQuotesAutomation",
    trigger: {
      task: TriggerTask.AFTER_DATE,
      arguments: {
        time: TriggerTimeForQuotes.NINE_DAYS_IN_HOURS,
        date_field: DateField.SENT_AT,
        model_type: ModelType.QUOTE,
      },
    },
  };

export const getDefaultAutomationRule = (templateType: Template) => {
  switch (templateType) {
    case Template.AUTOMATED_RE_ENGAGE:
      return pastClientsAutomationRule;
    case Template.AUTOMATED_WIN_BACK_LOST_LEADS:
      return quoteSentLeadsAutomationRule;
    case Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES:
      return closeOnPendingQuotesAutomationRule;
    default:
      return undefined;
  }
};
