import React from "react";
import { SideDrawer } from "components/SideDrawer";
import type { ClientSegmentFragment, Segment } from "~/utilities/API/graphql";
import type { ClientSegmentsData } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentData/useClientSegmentData";
import { ClientSegmentView } from "./ClientSegmentView";

export interface ClientSegmentData {
  rawData: ClientSegmentFragment | undefined;
  clientSegments: ClientSegmentsData;
}

export interface LoadingState {
  loadingMore: boolean;
  fullReload: boolean;
}

interface ClientSegmentDrawerProps {
  isOpen: boolean;
  closeSideDrawer(): void;
  segmentName: string;
  selectedSegment: Segment;
}

export function ClientSegmentDrawer({
  isOpen,
  closeSideDrawer,
  segmentName,
  selectedSegment,
}: ClientSegmentDrawerProps) {
  return (
    <SideDrawer
      open={isOpen}
      requestDrawerClose={closeSideDrawer}
      mobileFullWidth={true}
    >
      <ClientSegmentView
        closeSideDrawer={closeSideDrawer}
        selectedSegment={selectedSegment}
        segmentName={segmentName}
      />
    </SideDrawer>
  );
}
