import React from "react";
import {
  type BarDatum,
  type BarTooltipProps,
  type ComputedDatum,
  ResponsiveBar,
} from "@nivo/bar";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import { formatTick } from "jobber/dataVisualizations/utils/dataVisualizationsUtils";

export interface BarChartProps {
  keys: string[];
  xTickValues?: string[];
  yTickValues: number | number[];
  data: BarDatum[];
  tooltip?: React.FC<BarTooltipProps<BarDatum>>;
  groupMode?: "stacked" | "grouped";
  innerPadding?: number;
  colors?: string[];
  shouldShowTickValues?: boolean;
  yTickPrefix?: string;
  clickHandler?: (data: ComputedDatum<BarDatum>) => void;
  axisBottomFormatter?: (value: string) => string;
  xLegend?: string;
  yLegend?: string;
}

export function BarChart(props: BarChartProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalBarChart {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalBarChart({
  keys, // keys are used to determine the order of the bars
  xTickValues,
  yTickValues,
  data,
  tooltip,
  groupMode = "stacked",
  innerPadding = 0,
  colors,
  shouldShowTickValues,
  yTickPrefix,
  clickHandler,
  axisBottomFormatter,
  xLegend,
  yLegend,
}: BarChartProps) {
  const { theme, tokens } = useAtlantisTheme();
  const defaultColors =
    theme === "dark"
      ? [tokens["color-base-purple--600"], tokens["color-base-purple--300"]]
      : [tokens["color-base-purple--500"], tokens["color-base-purple--800"]];

  return (
    <ResponsiveBar
      onClick={datum => {
        if (clickHandler) {
          clickHandler(datum);
        }
      }}
      data={data}
      keys={keys}
      indexBy="xAxisTick"
      margin={{
        bottom: shouldShowTickValues
          ? tokens["space-large"]
          : tokens["space-largest"],
        left: tokens["space-larger"] + tokens["space-smaller"],
        right: tokens["space-base"],
        top: xLegend ? 0 : tokens["space-larger"],
      }}
      innerPadding={innerPadding}
      padding={keys.length > 1 ? 0.4 : 0.65}
      valueScale={{
        type: "linear",
      }}
      indexScale={{ type: "band", round: true }}
      colors={colors || defaultColors}
      colorBy="id"
      groupMode={groupMode}
      enableLabel={false}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      theme={{
        axis: {
          legend: {
            text: {
              fill: "var(--color-text)",
              fontSize: tokens["typography--fontSize-small"],
            },
          },
          ticks: {
            text: {
              fill: "var(--color-text--secondary)",
              fontSize: tokens["typography--fontSize-small"],
            },
          },
        },
      }}
      borderRadius={2}
      enableGridY={true}
      gridYValues={shouldShowTickValues ? yTickValues : [0]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: tokens["space-small"],
        tickRotation: 0,
        tickValues: xTickValues,
        format: value =>
          axisBottomFormatter ? axisBottomFormatter(value) : value,
        legend: xLegend,
        legendOffset: xLegend ? 35 : 0,
        legendPosition: xLegend ? "middle" : undefined,
      }}
      axisLeft={{
        format: value => formatTick(value, { yTickPrefix }),
        tickValues: yTickValues,
        tickSize: 0,
        tickPadding: yLegend ? tokens["space-smallest"] : tokens["space-small"],
        legend: yLegend,
        legendOffset: yLegend ? -32 : 0,
        legendPosition: "middle",
        tickRotation: 0,
      }}
      role="application"
      tooltip={tooltip}
    />
  );
}
