import { useQuery } from "@apollo/client";
import React from "react";
import { Banner, Content } from "@jobber/components";
import type { CurrencyType } from "@jobber/formatters";
import type { AccountSettingsQuery } from "~/utilities/API/graphql";
import { AccountContext } from "~/utilities/contexts/internal/AccountContext";
import { ACCOUNT_SETTINGS_QUERY } from "./account.graphql";
import styles from "./AccountSettingsProvider.module.css";

/**
 * This provider is a bridge between the legacy account settings provided by
 * rails erb templates and `withRailsPropsAsContext` and newer components that
 * get their settings from GraphQL.
 *
 * This provider should be used when you have components that leverage the
 * `useAccount` hook that exist in _both_ the legacy (withRailsPropsAsContext)
 * and new (decoupled from Rails) worlds.
 *
 * If you aren't in this situation (i.e. you don't have components depending
 * on `useAccount`), you probably shouldn't use this provider.
 */
export const AccountSettingsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { data, loading, error } = useQuery<AccountSettingsQuery>(
    ACCOUNT_SETTINGS_QUERY,
    { fetchPolicy: "no-cache" },
  );

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <main className={styles.pageWrapper}>
        <Content>
          <Banner type="error">{error?.message}</Banner>
        </Content>
      </main>
    );
  }

  return (
    <AccountContext.Provider
      value={{
        currencyCode: (data?.account?.settings?.localization.currencyCode ||
          "USD") as CurrencyType,
        countryCode: data?.account?.settings?.localization.countryCode || "US",
        currencySymbol:
          data?.account?.settings?.localization.currencySymbol || "$",
        dateFormat: data?.account?.settings?.localization.dateFormat || "",
        timeFormat: data?.account?.settings?.localization.timeFormat || "",
        timezone: "",
        calendarFirstDay: 0,
        companyName: data?.account?.name || "",
        features: {
          quoteMargins: data?.quoteMarginsFeature || disabledFeature,
          jobCosting: data?.jobCostingFeature || disabledFeature,
          timeSheets: data?.timeSheetsFeature || disabledFeature,
          expenses: data?.expensesFeature || disabledFeature,
          selfServeBookings: data?.selfServeBookingsFeature || disabledFeature,
          franchiseReporting:
            data?.franchiseReportingFeature || disabledFeature,
          arrivalWindows: data?.arrivalWindowsFeature || disabledFeature,
          leadOrganization: data?.leadOrganizationFeature || disabledFeature,
          salespersonAttribution:
            data?.salespersonAttributionFeature || disabledFeature,
          leadAttribution: data?.leadAttributionFeature || disabledFeature,
          marketingClientReferrals:
            data?.marketingClientReferralsFeature || disabledFeature,
          advancedQuoting: data?.advancedQuotingFeature || disabledFeature,
          schedulingJobsNotes:
            data?.schedulingJobsNotesFeature || disabledFeature,
          quoteTemplates: data?.quoteTemplatesFeature || disabledFeature,
        },

        // These are not yet implemented in this provider. But to maintain
        // compatibility with the AccountContext, we need to provide values.
        // If you are reading this comment and you need these values, you
        // will need to implement these _as permissions_. Generally, we do not
        // want to expose concepts like 'franchise' in our main graphql schema.
        isFranchiseAdmin: false,
        hasAliasAccounts: false,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const disabledFeature = { available: false, enabled: false };
