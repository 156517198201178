import { generatePath, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import { parseConditions } from "jobber/campaigns/utils/automationRuleUtils";
import type { AllConditionTypes } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import { CAMPAIGNS_CONTENT_EDIT_PATH } from "jobber/campaigns/constants";
import { updatedCampaignAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useAutomationControls/messages";
import type {
  AutomationRule,
  CampaignCreateTemplateAttributes,
  CreateAutomatedCampaignMutation,
  CreateAutomatedCampaignMutationVariables,
  Template,
} from "~/utilities/API/graphql";
import { CREATE_AUTOMATED_CAMPAIGN } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useAutomationControls/createAutomatedCampaign.graphql";
import { useCommsCampaignDefaultTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignDefaultTemplateQuery/useCommsCampaignDefaultTemplateQuery";

export interface useAutomationControlsCreateCampaignProps {
  defaultAutomationRule: AutomationRule | undefined;
  trigger: number;
  conditions: (AllConditionTypes | undefined)[];
  savedRule?: AutomationRule;
  templateType?: Template;
  isAutomated?: boolean;
  setErrors: (error: string[] | null) => void;
}

export interface useAutomationControlsCreateCampaignResponse {
  createCampaign: () => void;
  loadingCreateAutomation: boolean;
}

export function useAutomationControlsCreateCampaign({
  defaultAutomationRule,
  trigger,
  conditions,
  savedRule,
  templateType,
  isAutomated,
  setErrors,
}: useAutomationControlsCreateCampaignProps): useAutomationControlsCreateCampaignResponse {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const { data: templateData } = useCommsCampaignDefaultTemplateQuery({
    skip: !!savedRule,
    defaultTemplateType: templateType as Template,
  });

  const defaultTemplate = useMemo(
    () => ({
      body: templateData?.commsCampaignDefaultTemplate?.body || "",
      subject: templateData?.commsCampaignDefaultTemplate?.subject || "",
      header: templateData?.commsCampaignDefaultTemplate?.header || "",
      type: templateType,
      ctaVisible:
        templateData?.commsCampaignDefaultTemplate?.ctaVisible || false,
      showLogo: templateData?.commsCampaignDefaultTemplate?.showLogo || true,
    }),
    [templateData, templateType],
  ) as CampaignCreateTemplateAttributes;

  const [
    createAutomatedCampaignMutation,
    { loading: loadingCreateAutomation },
  ] = useMutation<
    CreateAutomatedCampaignMutation,
    CreateAutomatedCampaignMutationVariables
  >(CREATE_AUTOMATED_CAMPAIGN);

  const createCampaign = async () => {
    await createAutomatedCampaignMutation({
      variables: {
        input: {
          rule: {
            trigger: {
              ...defaultAutomationRule?.trigger,
              arguments: {
                ...defaultAutomationRule?.trigger.arguments,
                time: trigger,
              },
            },
            condition: {
              ...defaultAutomationRule?.condition,
              conditions: parseConditions(conditions as AllConditionTypes[]),
            },
            action: defaultAutomationRule?.action,
            name: defaultAutomationRule?.name,
          },
          template: defaultTemplate,
        },
      },
      onCompleted: data => {
        const userErrors = data.createAutomatedCampaign.userErrors ?? [];
        if (userErrors.length > 0) {
          // there was a user error
          setErrors([userErrors[0].message]);
        } else {
          history.push({
            pathname: generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
              campaignId: data.createAutomatedCampaign.campaign?.id || "",
            }),
          });
        }
        updatedCampaignAmplitudeEvent({
          interaction: "Conditions saved",
          /* eslint-disable @typescript-eslint/naming-convention */
          is_automated: isAutomated,
          template_type: templateType,
          /* eslint-enable @typescript-eslint/naming-convention */
        });
      },
      onError: () => {
        // there was a server error
        setErrors([formatMessage(messages.createErrorMessage)]);
      },
    });
  };

  return { createCampaign, loadingCreateAutomation };
}
