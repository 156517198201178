import { Button } from "@jobber/components/Button";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Form } from "@jobber/components/Form";
import { InputText } from "@jobber/components/InputText";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Emphasis } from "@jobber/components/Emphasis";
import { Autocomplete } from "@jobber/components";
import type { Option } from "@jobber/components/Autocomplete";
import type { Industry } from "~/utilities/API/graphql";
import type {
  IndustryCategory,
  SetupWizardStepProps,
} from "jobber/setupWizard/types";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { messages } from "./messages";
import styles from "../../SetupWizardForms.module.css";

// eslint-disable-next-line max-statements
export function CommunityPageForm({
  navigation,
  industryMap,
  isSmallScreen,
}: SetupWizardStepProps) {
  const { formatMessage } = useIntl();
  const { wizardData, updateWizardData, saving } =
    useContext(SetupWizardContext);
  const [accountOwnerName, setAccountOwnerName] = useState<string>(
    wizardData?.accountOwnerName ?? "",
  );

  const [companyName, setCompanyName] = useState<string>(
    wizardData?.companyName ?? "",
  );

  const [industry, setIndustry] = useState<Option | undefined>(
    getHeadingOptions(industryMap)
      .flatMap(option => option.options)
      .find(option => option.value === wizardData?.industry),
  );

  const handleSubmit = async () => {
    if (!industry) return;

    const dataToSend = {
      accountOwnerName,
      companyName: companyName || `${accountOwnerName}'s Company`,
      industry: industry?.value as Industry | undefined,
    };
    updateWizardData(dataToSend, navigation.isFinalStep, navigation.onNext);
  };

  function handleAccountOwnerNameChange(name: string) {
    setAccountOwnerName(name);
  }

  function handleCompanyNameChange(name: string) {
    setCompanyName(name);
  }

  function handleIndustryChange(selectedIndustry: Option) {
    setIndustry(selectedIndustry);
    updateWizardData(
      { industry: selectedIndustry?.value as Industry | undefined },
      false,
      undefined,
      true,
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Content>
        <InputText
          name="accountOwnerName"
          placeholder={formatMessage(messages.fullNamePlaceholder)}
          value={accountOwnerName}
          validations={{
            required: {
              message: formatMessage(messages.fullNameError),
              value: true,
            },
            pattern: {
              value:
                /^(?!.*(([\da-zA-Z.-]+)\.([a-zA-Z.]{1,6})([/\w .-]*)*\/?$)).*$/i,
              message: formatMessage(messages.invalidNameError),
            },
          }}
          onChange={handleAccountOwnerNameChange}
          size="large"
        />
        <InputText
          name="companyName"
          placeholder={formatMessage(messages.companyNamePlaceholder)}
          value={companyName}
          onChange={handleCompanyNameChange}
          size="large"
        />
        <Autocomplete
          allowFreeForm={false}
          clearable={"while-editing"}
          initialOptions={getHeadingOptions(industryMap)}
          value={industry}
          name="industry"
          placeholder={formatMessage(messages.industryPlaceholder)}
          onChange={handleIndustryChange}
          validations={{
            required: {
              message: formatMessage(messages.industryError),
              value: true,
            },
          }}
          size="large"
          getOptions={text => getOptions(text, industryMap)}
        />
        <Text>
          <Emphasis variation="bold">
            {formatMessage(messages.communityLoginPrimeMessage)}
          </Emphasis>
        </Text>
        <div className={styles.button}>
          <Button
            id={"pageSubmit"}
            fullWidth={isSmallScreen}
            label={messages.communitySubmissionButton.defaultMessage}
            submit={true}
            loading={saving}
            size="large"
          />
        </div>
      </Content>
    </Form>
  );
}

const getHeadingOptions = (industryMap: IndustryCategory[]) => {
  return industryMap.map(obj => {
    return {
      label: obj.category,
      options: obj.industries.map(industry => ({
        value: industry.industryEnum,
        label: industry.displayName,
      })),
    };
  });
};

function getOptions(text: string, industryMap: IndustryCategory[]): Option[] {
  const headingOptions = getHeadingOptions(industryMap);

  if (text === "") {
    return headingOptions;
  }

  const filterRegex = new RegExp(text, "i");

  return headingOptions.map(section => ({
    ...section,
    options: section.options.filter(option => option.label.match(filterRegex)),
  }));
}
