import React, { useState } from "react";
import {
  useStartChatWithSuccess,
  useSupportInteractionUpdateMutation,
} from "jobber/jobberAssistant/hooks";
import type { AiAssistantActionFollowup } from "~/utilities/API/graphql";
import { IntercomConversationRoute } from "~/utilities/API/graphql";
import { ConversationPills } from "./ConversationPills";
import type { Followup } from "./ConversationListItem";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

interface SupportEscalationOptionsProps {
  requestId: string;
  supportEscalationPrompt: string;
  followups: AiAssistantActionFollowup[];
}

export const SupportEscalationOptions = ({
  requestId,
  supportEscalationPrompt,
  followups,
}: SupportEscalationOptionsProps) => {
  const { startChatWithSuccess, loading } = useStartChatWithSuccess(requestId);
  const [selectedOption, setSelectedOption] = useState<string>();
  const supportOptions: Followup[] = followups.map(item => ({
    displayText: item.displayText,
    prompt: item.prompt,
    loading: loading && selectedOption === item.displayText,
    disabled: loading && selectedOption !== item.displayText,
  }));
  const { updateInteraction } = useSupportInteractionUpdateMutation({
    requestId,
  });

  return (
    <ConversationPills
      prompts={supportOptions}
      onClick={handleClick}
      fullWidth
    />
  );

  async function handleClick(prompt: PromptType) {
    const selectedFollowup = followups.find(
      f => f.displayText === prompt.message,
    );
    const routing =
      (selectedFollowup?.metadata as IntercomConversationRoute | undefined) ??
      IntercomConversationRoute.SUPPORT;
    setSelectedOption(prompt.message);
    const conversationId = await startChatWithSuccess(
      supportEscalationPrompt,
      routing,
    );
    if (conversationId) {
      await updateInteraction(conversationId);
    }
  }
};
