import { gql, useQuery } from "@apollo/client";
import type { ReceivablesQuery } from "~/utilities/API/graphql";

export const RECEIVABLES_QUERY = gql`
  query Receivables {
    topClientsBalance {
      totalBalance
      numberOfClientsWithBalances
    }
    insightsAndAccountingReceivables {
      percentageOfUpcomingJobsOnAutopay
      invoiceReceivables {
        commercial {
          avgDaysToGettingPaid
        }
        previous {
          commercial {
            avgDaysToGettingPaid
          }
          residential {
            avgDaysToGettingPaid
          }
        }
        residential {
          avgDaysToGettingPaid
        }
      }
    }
    feature(key: "automatic_payments") {
      available
    }
  }
`;

export function useReceivables() {
  const { data, loading } = useQuery<ReceivablesQuery>(RECEIVABLES_QUERY, {
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
  };
}
