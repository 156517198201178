import React, { forwardRef } from "react";
import { useQuery } from "@apollo/client";
import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import { LoadingError } from "jobber/billing/components/LoadingError";
import type {
  AccountBillingInformation,
  AvailableBillingCountriesQuery,
  EditBillingInfoDataQuery,
  MutationErrors,
} from "~/utilities/API/graphql";
import type { TrackingDetails } from "jobber/billing/utils/trackInteractions";
import type { FieldErrorState } from "jobber/billing/components/EditBillingInfo/EditBillingInfo.d";
import { SplitNames, useSplit } from "utilities/split";
import { ExperimentalEditBillingInfo } from "jobber/billing/features/Checkout/components/CheckoutExperiment/ExperimentalEditBillingInfo";
import { EditBillingInfo, type EditBillingInfoRef } from "./EditBillingInfo";
import { EDIT_BILLING_INFO_DATA } from "./EditBillingInfo.graphql";
import { getStoredBillingAddress, getStoredPaymentDetails } from "./utils";
import { LoadingGlimmer } from "./LoadingGlimmer";
import { AVAILABLE_BILLING_COUNTRIES_QUERY } from "./components/BillingAddress/components/BillingAddressEdit/BillingAddressEdit.graphql";

interface EditBillingInfoLoaderProps {
  recurlyPublicKey: string;
  isPurchasing?: boolean;
  showPreview?: boolean;
  trackingDetails: TrackingDetails;
  setSaveEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowParentActions?: React.Dispatch<React.SetStateAction<boolean>>;
  onValidationError(error: FieldErrorState): void;
  onSubmitSuccess(): void;
  onSubmitError(errors: MutationErrors[]): void;
  clearValidationErrors(fieldNames: string[]): void;
}

export const EditBillingInfoLoader = forwardRef(InternalEditBillingInfoLoader);

export function InternalEditBillingInfoLoader(
  props: EditBillingInfoLoaderProps,
  ref: React.MutableRefObject<EditBillingInfoRef>,
) {
  const {
    recurlyPublicKey,
    isPurchasing,
    showPreview,
    trackingDetails,
    setSaveEnabled,
    setShowParentActions,
    onSubmitSuccess,
    onSubmitError,
    onValidationError,
    clearValidationErrors,
  } = props;

  const { data, error, loading } = useQuery<EditBillingInfoDataQuery>(
    EDIT_BILLING_INFO_DATA,
  );

  const { loading: loadingCountries } =
    useQuery<AvailableBillingCountriesQuery>(AVAILABLE_BILLING_COUNTRIES_QUERY);

  // Cobra Kai Experiment - Ticket to remove: https://jobber.atlassian.net/browse/JOB-111284
  const experimentalCondensedView = useSplit({
    names: [SplitNames.CheckoutCondensedJobberOnline],
  }).getTreatmentValue(SplitNames.CheckoutCondensedJobberOnline);

  if (loading || loadingCountries) {
    return <LoadingGlimmer showPreview={showPreview} />;
  }

  const billingInformation: Partial<AccountBillingInformation> | undefined =
    data?.account?.billingInformation;

  if (error || !billingInformation) {
    return (
      <LoadingError
        source="EditBillingInfoLoader"
        logMessage={`Could not load billing info: ${error?.message}`}
      />
    );
  }

  if (experimentalCondensedView) {
    return (
      <RecurlyProvider publicKey={recurlyPublicKey}>
        <Elements>
          <ExperimentalEditBillingInfo
            ref={ref}
            isPurchasing={isPurchasing}
            shouldShowDisplay={true}
            trackingDetails={trackingDetails}
            showPreview={showPreview}
            storedPaymentDetails={getStoredPaymentDetails(billingInformation)}
            storedBillingAddress={getStoredBillingAddress(billingInformation)}
            setSaveEnabled={setSaveEnabled}
            setShowParentActions={setShowParentActions}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
            onValidationError={onValidationError}
            clearValidationErrors={clearValidationErrors}
          />
        </Elements>
      </RecurlyProvider>
    );
  }

  return (
    <RecurlyProvider publicKey={recurlyPublicKey}>
      <Elements>
        <EditBillingInfo
          ref={ref}
          isPurchasing={isPurchasing}
          shouldShowDisplay={true}
          trackingDetails={trackingDetails}
          showPreview={showPreview}
          storedPaymentDetails={getStoredPaymentDetails(billingInformation)}
          storedBillingAddress={getStoredBillingAddress(billingInformation)}
          setSaveEnabled={setSaveEnabled}
          setShowParentActions={setShowParentActions}
          onSubmitSuccess={onSubmitSuccess}
          onSubmitError={onSubmitError}
          onValidationError={onValidationError}
          clearValidationErrors={clearValidationErrors}
        />
      </Elements>
    </RecurlyProvider>
  );
}
