import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { InputText } from "@jobber/components/InputText";
import { InputValidation } from "@jobber/components/InputValidation";
import { Menu } from "@jobber/components/Menu";
import { Switch } from "@jobber/components/Switch";
import { Tooltip } from "@jobber/components/Tooltip";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "jobber/campaigns/views/CampaignsContentPage/messages";
import {
  AiAssistantTextReviseContentType,
  AiAssistantTextReviseIntent,
  Template,
} from "~/utilities/API/graphql";
import type { File, TemplateVariablesFragment } from "~/utilities/API/graphql";
import {
  AvailableEditors,
  type CampaignContentRefs,
} from "jobber/campaigns/views/CampaignsContentPage/types";
import { TextRewrite, TextRewriteErrorBanner } from "components/TextRewrite";
import { SplitNames, useFeatureFlag } from "utilities/split";
import type { TextRewriteError } from "components/TextRewrite/errors";
import { useCampaignCompanyInformation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignCompanyInformation";
import { ImageUploader } from "jobber/campaigns/views/CampaignsContentPage/components/RichTextContentEditor/components/ImageUploader/ImageUploader";
import styles from "./EditContent.module.css";
import { CtaButtonInput } from "../CtaButtonInput";
import { RichTextContentEditor } from "../RichTextContentEditor";
import { HeaderImageUpload } from "../HeaderImageUpload";

const CAMPAIGNS_TRACKING_SOURCE = "campaigns_content_page";

interface EditContentProps {
  subject: string;
  header: string;
  body: string;
  showLogo?: boolean;
  setSubject: (newSubject: string) => void;
  setHeader: (newHeader: string) => void;
  setBody: (newBody: string, isDirty: boolean) => void;
  setShowLogo: (showLogo: boolean) => void;
  variables: TemplateVariablesFragment[];
  templateType: Template;
  loading: boolean;
  refs: CampaignContentRefs;
  textRewriteLoading: boolean;
  setTextRewriteLoading: (loading: boolean) => void;
  headerImage?: File;
  setHeaderImage: (newHeaderImage: File | undefined, isDirty: boolean) => void;
}

export function EditContent(props: EditContentProps) {
  return (
    <IntlProvider>
      <EditContentInternal {...props} />
    </IntlProvider>
  );
}

function EditContentInternal({
  subject,
  header,
  body,
  showLogo,
  setSubject,
  setHeader,
  setBody,
  setShowLogo,
  variables,
  templateType,
  loading,
  refs,
  setTextRewriteLoading,
  textRewriteLoading,
  headerImage,
  setHeaderImage,
}: EditContentProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [textRewriteError, setTextRewriteError] = useState<TextRewriteError>();

  const [activeEditor, setActiveEditor] = useState<AvailableEditors>(
    AvailableEditors.body,
  );

  const showHeaderImageUpload = useFeatureFlag(
    SplitNames.CommsEmailMarketingImageUpload,
  );

  const { logoUrl } = useCampaignCompanyInformation();

  function setActiveField(field: AvailableEditors) {
    return () => {
      setActiveEditor(field);
    };
  }

  function insertVariable(variable: string) {
    const active = refs[activeEditor];
    if (active.current) {
      active.current.insert(variable);
    }
  }

  const menuItems = variables.map(section => ({
    header: section.label,
    actions: section.variables.nodes.map(variable => ({
      label: variable.label,
      onClick: () => {
        insertVariable(variable.name);
      },
    })),
  }));

  return (
    <div className={styles.container}>
      <Heading level={1}>
        {formatMessage(messages.campaignContentPageTitle)}
      </Heading>
      <div className={styles.campaignTemplateInputs}>
        <div className={styles.headingWrapper}>
          <Heading level={4}>
            {formatMessage(messages.emailSubjectLineInput)}
          </Heading>
        </div>
        <Content>
          <InputText
            name="subject"
            placeholder={formatMessage(messages.campaignSubjectLine)}
            value={subject}
            onChange={setSubject}
            loading={loading}
            disabled={loading}
            ref={refs[AvailableEditors.subject]}
            onFocus={setActiveField(AvailableEditors.subject)}
            validations={{
              required: {
                value: true,
                message: formatMessage(messages.errorForEmptySubject),
              },
              maxLength: {
                value: 150,
                message: formatMessage(messages.subjectMaxLengthError),
              },
            }}
          />
          <div className={styles.logoWrapper}>
            <Heading level={4}>{formatMessage(messages.showLogoInput)}</Heading>
            <div style={{ marginLeft: "auto" }}>
              {logoUrl && (
                <Switch
                  value={logoUrl ? showLogo : false}
                  onChange={setShowLogo}
                  disabled={loading}
                />
              )}
              {!logoUrl && (
                <Tooltip message={formatMessage(messages.logoDisabledTooltip)}>
                  <Switch
                    value={false}
                    onChange={setShowLogo}
                    disabled={true}
                  />
                </Tooltip>
              )}
            </div>
          </div>
          {showHeaderImageUpload && headerImage && (
            <HeaderImageUpload
              headerImage={headerImage}
              setHeaderImage={setHeaderImage}
            />
          )}

          <div className={styles.headingWrapper}>
            <Heading level={4}>
              {formatMessage(messages.emailHeadingInput)}
            </Heading>
          </div>
          <div>
            <InputText
              name="heading"
              placeholder={formatMessage(messages.campaignHeading)}
              value={header}
              disabled={loading}
              onChange={setHeader}
              loading={loading}
              ref={refs[AvailableEditors.header]}
              onFocus={setActiveField(AvailableEditors.header)}
            />
          </div>

          {!headerImage && (
            <ImageUploader
              ref={refs[AvailableEditors.body]}
              isHeaderImage={true}
            />
          )}

          {textRewriteError?.type === "BANNER" && (
            <TextRewriteErrorBanner
              onDismiss={() => setTextRewriteError(undefined)}
              errorMessage={textRewriteError.message}
            />
          )}

          <div className={styles.headingWrapper}>
            <Heading level={4}>
              {formatMessage(messages.emailRichTextEditorHeading)}{" "}
            </Heading>
          </div>
          <RichTextContentEditor
            placeholder={formatMessage(messages.campaignBody)}
            content={body}
            ref={refs[AvailableEditors.body]}
            onChange={(newBody, isDirty) => {
              setBody(newBody, isDirty);
              setTextRewriteError(undefined);
            }}
            textRewriteError={textRewriteError}
            minRows={12}
            loading={loading}
            textRewriteLoading={textRewriteLoading}
            onFocus={setActiveField(AvailableEditors.body)}
          />
          {textRewriteError?.type === "INLINE" && (
            <InputValidation message={textRewriteError.message} />
          )}
          <div className={styles.rewriteAndVariablesContainer}>
            <TextRewrite
              loading={textRewriteLoading || loading}
              setLoading={setTextRewriteLoading}
              setError={setTextRewriteError}
              text={body}
              setText={text => {
                setBody(text, true);
                refs[AvailableEditors.body].current?.setValue(text);
              }}
              trackingSource={CAMPAIGNS_TRACKING_SOURCE}
              intent={convertTemplateToIntent(templateType)}
              contentType={AiAssistantTextReviseContentType.EMAIL_TEMPLATE}
            />
            <Menu
              activator={
                <Button
                  label={formatMessage(messages.insertVariable)}
                  type="primary"
                  variation="subtle"
                  size="small"
                />
              }
              items={menuItems}
            />
          </div>
          {templateType !== Template.REFERRAL && (
            <CtaButtonInput refs={refs} loading={loading} />
          )}
        </Content>
      </div>
    </div>
  );
}

const templateToIntentMap = {
  [Template.RE_ENGAGE]: AiAssistantTextReviseIntent.REENGAGE,
  [Template.UPSELL]: AiAssistantTextReviseIntent.UPSELL,
  [Template.START_FROM_SCRATCH]: undefined,
  [Template.AUTOMATED_RE_ENGAGE]: AiAssistantTextReviseIntent.REENGAGE,
  [Template.AUTOMATED_WIN_BACK_LOST_LEADS]:
    AiAssistantTextReviseIntent.WIN_BACK,
  [Template.REFERRAL]: AiAssistantTextReviseIntent.REFERRAL,
  [Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES]:
    AiAssistantTextReviseIntent.CLOSE_ON_PENDING_QUOTES,
  [Template.SEASONAL_PROMOTION]: AiAssistantTextReviseIntent.SEASONAL_PROMOTION,
  [Template.HOLIDAYS]: AiAssistantTextReviseIntent.HOLIDAYS,
  [Template.LEAD]: AiAssistantTextReviseIntent.LEAD,
  [Template.SPECIAL_OFFER]: AiAssistantTextReviseIntent.SPECIAL_OFFER,
  [Template.ANNOUNCEMENT]: AiAssistantTextReviseIntent.ANNOUNCEMENT,
};

function convertTemplateToIntent(
  template: Template,
): AiAssistantTextReviseIntent | undefined {
  return templateToIntentMap[template];
}
