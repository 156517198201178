import { useCallback, useMemo, useState } from "react";
import type { ComboboxOption } from "@jobber/components/Combobox";
import { ReviewQueryParams, ReviewStatuses } from "../types";

export interface FilterState {
  readonly status: ComboboxOption[];
}

export interface UseFilterStateValues {
  readonly filterState: FilterState;
  readonly filtered: boolean;
  readonly setStatus: (status: ComboboxOption[]) => void;
}

export function useFilterState({
  defaultStatus,
}: {
  defaultStatus: ComboboxOption;
}): UseFilterStateValues {
  const [filterState, setFilterState] = useState<FilterState>({
    status: [defaultStatus],
  });

  const callbacks = useCallback(() => {
    return {
      setStatus: (status: ComboboxOption[]) => {
        const params = new URLSearchParams(window.location.search);
        params.set(ReviewQueryParams.filter, status[0].label.toLowerCase());
        window.history.replaceState(
          null,
          "",
          `${window.location.pathname}?${params}`,
        );
        setFilterState(prevState => ({ ...prevState, status }));
      },
    };
  }, [setFilterState]);
  const isFiltered = useMemo(
    () => filterState.status[0].id !== ReviewStatuses.All,
    [filterState.status],
  );

  return { filterState, filtered: isFiltered, ...callbacks() };
}
