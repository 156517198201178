import { defineMessages } from "react-intl";

export const messages = defineMessages({
  instantPayoutUnavailable: {
    id: "managedAccount.paymentsCard.instantPayoutDetails.instantPayoutUnavailable",
    defaultMessage:
      "Instant payouts are not available because you're receiving automatic hourly payouts to your Jobber Money account for free.",
    description:
      "Message when instant payouts are not available because the users payout accout is a Jobber Money account",
  },
  standardPayoutScheduleTitle: {
    id: "managedAccount.payoutSchedule.standardPayoutScheduleTitle",
    defaultMessage: "Standard Payouts",
    description: "Title for the standard payout schedule section",
  },
  hourlyPayoutScheduleTitle: {
    id: "managedAccount.payoutSchedule.hourlyPayoutScheduleTitle",
    defaultMessage: "Hourly Payouts",
    description: "Title for the hourly payout schedule section",
  },
  temporaryPayoutsDelayDescription: {
    id: "managedAccount.payoutSchedule.temporaryPayoutsDelayDescription",
    defaultMessage:
      "Payouts are temporarily being made on a {payoutScheduleDelayDays} {delayDayType} day schedule",
    description:
      "Payouts description when SP in initial auth temporary delay state",
  },
  payoutsUnavailableDescription: {
    id: "managedAccount.payoutSchedule.payoutsUnavailableDescription",
    defaultMessage: "Automatic payouts are currently unavailable",
    description: "Payouts description when SP is in manual payout state",
  },
  standardPayoutsDescription: {
    id: "managedAccount.payoutSchedule.standardPayoutsDescription",
    defaultMessage:
      "Payouts are made on a {payoutScheduleDelayDays} {delayDayType} day schedule",
    description: "Payouts description for standard payouts",
  },
  standardPayoutCardTitle: {
    id: "managedAccount.payoutSchedule.standardPayoutCardTitle",
    defaultMessage: "Credit or debit card payments:",
    description: "Header for the credit or debit card payments section",
  },
  standardPayoutAchTitle: {
    id: "managedAccount.payoutSchedule.standardPayoutAchTitle",
    defaultMessage: "ACH bank payments:",
    description: "Header for the ACH bank payments section",
  },
  standardPayoutSchedule: {
    id: "managedAccount.payoutSchedule.standardPayoutSchedule",
    defaultMessage:
      "{payoutScheduleDelayDays} {delayDayType} day payout schedule",
    description:
      "Description for Card or ACH payout schedule when ACH is enabled",
  },
  hourlyPayoutsDescription: {
    id: "managedAccount.payoutSchedule.hourlyPayoutsDescription",
    defaultMessage:
      "Payouts arrive in your Jobber Money account in as little as one hour",
    description: "Payouts description for hourly payouts",
  },
  delayDaysBusiness: {
    id: "managedAccount.payoutSchedule.delayDaysBusiness",
    defaultMessage: "business",
    description: "Payouts schedule based on business days",
  },
  delayDaysCalendar: {
    id: "managedAccount.payoutSchedule.delayDaysCalendar",
    defaultMessage: "calendar",
    description: "Payouts schedule based on calendar days",
  },
  learnMore: {
    id: "managedAccount.payoutSchedule.learnMore",
    defaultMessage: "Learn about payout schedules",
    description: "Text to learn more about payout schedules link",
  },
  bankDetailsTitle: {
    id: "managedAccount.bankDetails.title",
    defaultMessage: "Your bank details",
    description: "Title for the bank details section",
  },
  changeBankAccount: {
    id: "managedAccount.bankDetails.changeBankAccount",
    defaultMessage: "Change bank account",
    description: "Text for the change bank account button",
  },
  accountEndingDigits: {
    id: "managedAccount.bankDetails.accountEndingDigits",
    defaultMessage: "Account Ending in {bankLast4}",
    description: "Bank account ending digits",
  },
  depositedPayoutsHeading: {
    id: "managedAccount.depositedPayouts.heading",
    defaultMessage: "Deposited payouts",
    description: "Heading for deposited payouts section",
  },
  depositedPayoutsThisWeekTitle: {
    id: "managedAccount.depositedPayouts.thisWeek",
    defaultMessage: "This week",
    description: "Title for this week deposited payouts",
  },
  depositedPayoutsThisMonthTitle: {
    id: "managedAccount.depositedPayouts.thisMonth",
    defaultMessage: "This month",
    description: "Title for this month deposited payouts",
  },
  instantPayouts: {
    id: "managedAccount.instantPayouts",
    defaultMessage: "Instant Payouts",
    description: "Title for instant payouts section",
  },
  instantPayoutDescription: {
    id: "managedAccount.instantPayouts.description",
    defaultMessage:
      "Payouts are made within minutes 24/7 for an additional 1% fee.",
    description: "Description for instant payouts",
  },
  learnAboutInstantPayout: {
    id: "managedAccount.instantPayouts.learnMore",
    defaultMessage: "Learn about instant payouts",
    description: "Link to learn more about instant payouts",
  },
  disableJobberPaymentsTitle: {
    id: "managedAccount.jobberPaymentsSettingsPage.disableJobberPayments",
    defaultMessage: "Disable Jobber Payments",
    description: "Title for Disabling Jobber Payments on JPay Settings Page",
  },
  disableJobberPaymentsDescription: {
    id: "managedAccount.jobberPaymentsSettingsPage.disableJobberPaymentsDescription",
    defaultMessage:
      "Clients will not be able to pay any outstanding or new quotes and invoices online.",
    description: "Message for Disabling Jobber Payments on JPay Settings Page",
  },
});
