/* eslint-disable import/no-internal-modules */

import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  type Operation,
  type PossibleTypesMap,
  createHttpLink,
} from "@apollo/client";
import { jobberOnline } from "legacy/components/JobberOnline/jobberOnline";
import possibleTypes from "./possibleTypes.json";
import { typePolicies } from "./typePolicies";
import type { ActionCableInterface } from "./ActionCableTypes.types";
import { performanceLink } from "./performanceLink";

export enum AlternateSchema {
  Anchor = "anchor",
  Bunker = "bunker",
  External = "external",
  Franchise = "franchise",
}

const GRAPHQL_API_VERSION = "2025-01-20";

declare const ActionCable: ActionCableInterface;
const getLocation = () => {
  if (window.location.pathname.substr(1).split("/")[0] === "client_hubs") {
    return "ch";
  } else {
    return "j";
  }
};

const changeUriIntoWebSocketUri = (uri: string) => uri.replace("http", "ws"); // This also changes https to wss

const getCableUrl = (alternateSchema?: AlternateSchema) => {
  const actionCableSocketUrl = changeUriIntoWebSocketUri(
    jobberOnline.constants.actionCableUrl,
  );

  let url = `${actionCableSocketUrl}?location=${getLocation()}`;
  switch (alternateSchema) {
    case undefined: // Main schema.
      url += `&version=${GRAPHQL_API_VERSION}`;
      break;
    case AlternateSchema.Anchor:
      url += "&schema=anchor";
      break;
    default:
      throw new Error(`Unknown alternate schema: ${alternateSchema}`);
  }

  return url;
};

const httpJobberOnlineLink = createHttpLink({
  uri: `${jobberOnline.constants.graphqlEndpoint}?location=${getLocation()}`,
  credentials: "include",
  headers: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "X-JOBBER-GRAPHQL-VERSION": GRAPHQL_API_VERSION,
  },
});

const httpAnchorLink = createHttpLink({
  uri: `${
    jobberOnline.constants.graphqlAnchorEndpoint
  }?location=${getLocation()}`,
  credentials: "include",
});

const httpBunkerLink = createHttpLink({
  uri: `${
    jobberOnline.constants.graphqlBunkerEndpoint
  }?location=${getLocation()}`,
  credentials: "include",
});

const httpFranchiseManagementLink = createHttpLink({
  uri: `${
    jobberOnline.constants.graphqlFranchiseManagementEndpoint
  }?location=${getLocation()}`,
  credentials: "include",
});

const httpFranchiseLink = createHttpLink({
  uri: `${
    jobberOnline.constants.graphqlFranchiseEndpoint
  }?location=${getLocation()}`,
  credentials: "include",
});

const httpExternalLink = createHttpLink({
  uri: `${
    jobberOnline.constants.graphqlExternalEndpoint
  }?location=${getLocation()}`,
});

const inAnchorOperation = () => {
  return (
    window.location.pathname.substring(1).split("/")[0] === "administration"
  );
};

const inBunkerOperation = (operation: Operation) => {
  const schema = operation.getContext().schema;

  return schema === AlternateSchema.Bunker;
};

const inFranchisePortalOperation = (operation: Operation) => {
  if (operation.operationName === "leadRoutes") {
    return true;
  }

  return window.location.pathname.substring(1).split("/")[0] === "hq_hub";
};

const inFranchiseOperation = (operation: Operation) => {
  const schema = operation.getContext().schema;

  return schema === AlternateSchema.Franchise;
};

const inExternalOperation = (operation: Operation) => {
  const schema = operation.getContext().schema;

  return schema === AlternateSchema.External;
};

const httpLink = ApolloLink.concat(
  performanceLink(),
  ApolloLink.split(
    inAnchorOperation,
    httpAnchorLink,
    ApolloLink.split(
      inBunkerOperation,
      httpBunkerLink,
      ApolloLink.split(
        inFranchisePortalOperation,
        httpFranchiseManagementLink,
        ApolloLink.split(
          inFranchiseOperation,
          httpFranchiseLink,
          ApolloLink.split(
            inExternalOperation,
            httpExternalLink,
            httpJobberOnlineLink,
          ),
        ),
      ),
    ),
  ),
);

const wsJobberOnlineLink = new ActionCableLink({
  cable: ActionCable.createConsumer(getCableUrl()),
});

const wsAnchorLink = new ActionCableLink({
  cable: ActionCable.createConsumer(getCableUrl(AlternateSchema.Anchor)),
});

const wsLink = ApolloLink.split(
  inAnchorOperation,
  wsAnchorLink,
  wsJobberOnlineLink,
);

const hasSubscriptionOperation = (operation: Operation) => {
  const query = operation.query;

  for (const definition of query.definitions) {
    if (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    ) {
      return true;
    }
  }

  return false;
};

const link = ApolloLink.split(hasSubscriptionOperation, wsLink, httpLink);
const possibleTypesMap = possibleTypes.possibleTypes as PossibleTypesMap;

const cache = new InMemoryCache({
  possibleTypes: possibleTypesMap,
  typePolicies,
  // The default is 'true', but we want to be explicit because
  // our code expects typename to be _always_ be present. And it
  // would be unfortunate if we accidentally changed the value
  // to false.
  addTypename: true,
});

// --> hasSubscriptionOperation -- true --> wsLink  --> inAnchorOperation -- true --> wsAnchorLink
//                                                                        -- false -> wsJobberOnlineLink
//                              -- false -> httpLink -> inAnchorOperation -- true --> httpAnchorLink
//                                                                        -- false -> inFranchisePortalOperation -- true --> httpFranchiseManagementLink
//                                                                                                               -- false -> inFranchiseOperation -- true --> httpFranchiseLink
//                                                                                                                                                -- false -> httpJobberOnlineLink

export const APIClient = new ApolloClient({
  cache: cache,
  link: link,
});
