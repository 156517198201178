import { useMemo } from "react";
import { useIntl } from "react-intl";
import type { ComboboxOption } from "@jobber/components/Combobox";
import { ReviewStatuses } from "../types";
import { messages } from "../components/ReviewFilter/messages";

export function useStatusOptions(filterQueryParam?: string | null): {
  defaultStatus: ComboboxOption;
  statusOptions: ComboboxOption[];
} {
  const { formatMessage } = useIntl();
  const statusOptions = useMemo(
    () => [
      {
        id: ReviewStatuses.All,
        label: formatMessage(messages.all),
      },
      {
        id: ReviewStatuses.Unanswered,
        label: formatMessage(messages.unanswered),
      },
    ],
    [formatMessage],
  );
  let defaultStatus = statusOptions[0] as ComboboxOption;
  if (
    filterQueryParam?.toLowerCase() === ReviewStatuses.Unanswered.toLowerCase()
  ) {
    defaultStatus = statusOptions[1] as ComboboxOption;
  }
  return {
    defaultStatus,
    statusOptions,
  };
}
