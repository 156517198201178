import React from "react";
import { Text } from "@jobber/components/Text";
import { Icon } from "@jobber/components/Icon";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { useIntl } from "react-intl";
import { ConversationPills } from "jobber/jobberAssistant/components/ConversationPills";
import type { AiAssistantActionFollowup } from "~/utilities/API/graphql";
import { AiAssistantFeedbackSentiment } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { messages } from "./messages";
import type { Followup } from "./ConversationListItem";
import { useActionFollowupsDelete, useFeedbackCreateMutation } from "../hooks";
import type { PromptType } from "../AssistantPromptEventQueueProvider";

interface SupportQuestionFollowupProps {
  message: string;
  requestId: string;
  onClick?: (prompt: PromptType) => void;
  followups: AiAssistantActionFollowup[];
}

const FEEDBACK = "FEEDBACK";

export const SupportQuestionFollowup = ({
  message,
  requestId,
  onClick,
  followups,
}: SupportQuestionFollowupProps) => {
  const { formatMessage } = useIntl();
  const {
    createFeedback,
    createFeedbackErrorMessage,
    createFeedbackSubmitted,
    createFeedbackLoading,
  } = useFeedbackCreateMutation({ requestId });
  const { deleteActionFollowups, called } = useActionFollowupsDelete({
    requestId,
  });

  const options: Followup[] = followups.map(item => ({
    displayText: item.displayText,
    prompt: item.metadata === FEEDBACK ? FEEDBACK : item.prompt,
    loading: item.metadata === FEEDBACK && createFeedbackLoading,
  }));

  const shouldShowOptions = options.length > 0 && !called;

  return (
    <>
      {createFeedbackErrorMessage && (
        <Banner type={"error"} dismissible={false}>
          {formatMessage(messages.supportQuestionFollowupSavingError)}
        </Banner>
      )}
      {shouldShowOptions && (
        <>
          <Text>{formatMessage(messages.supportQuestionFollowupPrompt)}</Text>
          <ConversationPills onClick={handleClick} prompts={options} />
        </>
      )}
      {createFeedbackSubmitted && (
        <Text align="end" variation="subdued" size="small">
          <Icon name="checkmark" size="small" />
          &nbsp;
          <Emphasis variation="italic">
            {formatMessage(messages.supportQuestionFollowupAcknowledgement)}
          </Emphasis>
        </Text>
      )}
    </>
  );

  async function handleClick(prompt: PromptType) {
    if (prompt.message === FEEDBACK) {
      const feedbackCreated = await handleFeedbackClick();
      if (!feedbackCreated) {
        return;
      }
    } else {
      handleSupportClick(prompt);
    }

    return deleteActionFollowups();
  }

  async function handleFeedbackClick(): Promise<boolean> {
    return createFeedback(AiAssistantFeedbackSentiment.HELPFUL, "");
  }

  function handleSupportClick(prompt: PromptType) {
    Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
      name: "connect_with_support_clicked",
      requestId,
    });

    onClick?.(buildPromptWithSupportEscalation(prompt));
  }

  function buildPromptWithSupportEscalation(prompt: PromptType) {
    return {
      message: prompt.message,
      supportEscalationPrompt: message,
    };
  }
};
