import React from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { SetupGuideWithProviders } from "./SetupGuide";
import type { SetupGuideProps } from "./types";

export function SetupGuideLoader({
  routing,
  companyName,
  isConnected,
}: SetupGuideProps) {
  return (
    <IntlProvider>
      <SetupGuideWithProviders
        routing={routing}
        companyName={companyName}
        isConnected={isConnected}
      />
    </IntlProvider>
  );
}
