import type { MutableRefObject } from "react";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { useOnMount } from "@jobber/hooks/useOnMount";
import GetStartedImage from "@images/upgradeWaveExpansion/GetStarted.png";
import { Intercom } from "legacy/utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import type { CallToActionRef } from "~/bunker/CallToAction/CallToAction";
import {
  CallToAction,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { messages } from "./messages";
import { FeatureCard } from "../../../../components/Expansion/FeatureCard/FeatureCard";

export function InTrialModal({
  open,
  onRequestClose,
  onRequestPageReload,
  source,
}: {
  open: boolean;
  onRequestClose: () => void;
  onRequestPageReload?: () => void;
  source: string;
}) {
  const { formatMessage: t } = useIntl();
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;
  const ctaName = "connect_to_grow_trial_in_trial_modal";
  const content: (keyof typeof messages)[] = [
    "growContent",
    "chatWithProductExpert",
    "reminder",
  ];

  useOnMount(() => {
    if (open) {
      Amplitude.TRACK_EVENT("CTA Viewed", {
        name: ctaName,
        source,
      });
    }
  });

  return (
    <CallToAction ref={ctaRef} ctaName={ctaName}>
      <Modal
        open={open}
        size="large"
        dismissible={true}
        title=""
        onRequestClose={handleDismiss}
        primaryAction={{
          label: t(messages.inTrialPrimaryButton),
          onClick: handlePrimaryAction,
        }}
        secondaryAction={{
          label: t(messages.inTrialSecondaryButton),
          onClick: handleSecondaryAction,
        }}
      >
        <FeatureCard
          image={{
            src: GetStartedImage as string,
            alt: t(messages.imageAlt),
          }}
          title={t(messages.growTitle)}
          content={content.map(c => t(messages[c]))}
        />
      </Modal>
    </CallToAction>
  );

  function handlePrimaryAction() {
    convertCTA(ctaRef, { source, type: "get_started" })();
    handleDismissActions();
  }

  function handleSecondaryAction() {
    convertCTA(ctaRef, { source, type: "chat_with_us" })();
    Intercom.EXECUTE("showNewMessage");
    onRequestClose();
  }

  function handleDismiss() {
    dismissCTA(ctaRef, { source })();
    handleDismissActions();
  }

  function handleDismissActions() {
    onRequestClose();
    onRequestPageReload?.();
  }
}
