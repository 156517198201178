import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Glimmer } from "@jobber/components/Glimmer";
import { debounce } from "lodash";
import type { AllClientsCriteriaInput } from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { useClientSegmentData } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentData/useClientSegmentData";
import { AdditionalCriteria } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/AdditionalCriteria";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/types";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { parseAdditionalCriteria } from "jobber/campaigns/utils/segmentCriteriaUtils";
import { SEGMENT_CLIENT_DEBOUNCE_TIME } from "jobber/campaigns/constants";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import { messages } from "./messages";

export interface AllClientsSegmentEditProps {
  additionalCriteria: AdditionalCriteriaUnion[];
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
  pageSize?: number;
  openSideDrawer?: () => void;
}

const clientSegmentDataConditionalVariables = {
  isAllClientDrawer: true,
};

export function AllClientsSegmentEdit({
  additionalCriteria,
  updateAdditionalCriteria,
  pageSize = 20,
  openSideDrawer,
}: AllClientsSegmentEditProps): JSX.Element {
  const { formatMessage } = useIntl();

  const {
    campaignSegment: { isSegmentCriteriaFormValid, isDirty },
  } = useCampaignWizardContext();

  const { refetch, fullReload, clientSegmentsData } = useClientSegmentData({
    conditionalVariables: clientSegmentDataConditionalVariables,
  });

  const debounceFetchData = useMemo(
    () =>
      debounce((newSegmentCriteria: AllClientsCriteriaInput) => {
        const {
          clientTagCriteria,
          clientTypeCriteria,
          lineItemCriteria,
          jobStatusCriteria,
          jobTypeCriteria,
          ...newCriteria
        } = newSegmentCriteria;
        refetch({
          first: pageSize,
          after: btoa("0"),
          ...{
            ...newCriteria,
            allClientTagCriteria: clientTagCriteria,
            allClientTypeCriteria: clientTypeCriteria,
            allClientLineItemCriteria: lineItemCriteria,
            allClientJobStatusCriteria: jobStatusCriteria,
            allClientJobTypeCriteria: jobTypeCriteria,
          },
        });
      }, SEGMENT_CLIENT_DEBOUNCE_TIME),
    [refetch, pageSize],
  );

  useEffect(() => {
    if (isSegmentCriteriaFormValid || !isDirty) {
      debounceFetchData({
        clientTagCriteria:
          parseAdditionalCriteria(additionalCriteria).clientTagCriteria,
        clientTypeCriteria:
          parseAdditionalCriteria(additionalCriteria).clientTypeCriteria,
        lineItemCriteria:
          parseAdditionalCriteria(additionalCriteria).lineItemCriteria,
        jobStatusCriteria:
          parseAdditionalCriteria(additionalCriteria).jobStatusCriteria,
        jobTypeCriteria:
          parseAdditionalCriteria(additionalCriteria).jobTypeCriteria,
      });
    }
  }, [
    debounceFetchData,
    isSegmentCriteriaFormValid,
    additionalCriteria,
    isDirty,
  ]);

  return (
    <Content spacing="base">
      <Text>{formatMessage(messages.allClientsSegmentCardDescription)}</Text>
      <AdditionalCriteria
        criteria={additionalCriteria}
        updateAdditionalCriteria={(value: AdditionalCriteriaReducerActions) =>
          updateAdditionalCriteria(value, true)
        }
      />
      <Text>
        {fullReload ? (
          <Glimmer.Text />
        ) : (
          <TextActionButton
            label={formatMessage(messages.clients, {
              totalCount: clientSegmentsData.allClients?.total,
            })}
            onClick={openSideDrawer}
          />
        )}
      </Text>
    </Content>
  );
}
