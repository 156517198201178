import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { InputText } from "@jobber/components/InputText";
import { useIntl } from "react-intl";
import { Banner } from "@jobber/components/Banner";
import { Form, type FormRef } from "@jobber/components/Form";
import { Modal } from "@jobber/components/Modal";
import { getValidations } from "jobber/campaigns/components/SendTestEmailForDemoModal/utils/getValidations";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";

export interface SendTestEmailForDemoModalProps {
  isInErrorState?: boolean;
  isOpen: boolean;
  closeModal: () => void;
  onSendTestEmail: (testEmail?: string) => Promise<void>;
}

export function SendTestEmailForDemoModal(
  props: SendTestEmailForDemoModalProps,
) {
  return (
    <IntlProvider>
      <SendTestEmailForDemoModalInternal {...props} />
    </IntlProvider>
  );
}

function SendTestEmailForDemoModalInternal({
  isInErrorState = false,
  closeModal,
  onSendTestEmail,
  isOpen,
}: SendTestEmailForDemoModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const formRef = React.createRef<FormRef>();
  const [testEmail, setTestEmail] = useState<string>("");

  return (
    <Modal
      title={formatMessage(messages.title)}
      open={isOpen}
      dismissible={true}
      onRequestClose={closeModal}
      primaryAction={{
        label: formatMessage(messages.sendEmailButton),
        onClick: () => {
          formRef.current?.submit();
        },
      }}
      secondaryAction={{
        label: formatMessage(messages.cancelButton),
        onClick: closeModal,
      }}
      size="small"
    >
      <Content>
        {isInErrorState && (
          <Banner type={"error"}>{formatMessage(messages.errorMessage)}</Banner>
        )}
        <Text>{formatMessage(messages.textLine1)}</Text>
        <Form onSubmit={handleSubmitSendTestEmail} ref={formRef}>
          <InputText
            name="testEmail"
            onChange={setTestEmail as (arg: string) => void}
            value={testEmail}
            placeholder={formatMessage(messages.emailAddressInputPlaceholder)}
            validations={getValidations({ formatMessage }).testEmail}
          />
        </Form>
      </Content>
    </Modal>
  );

  async function handleSubmitSendTestEmail() {
    await onSendTestEmail(testEmail);
    closeModal();
  }
}
