import React from "react";
import { Intercom } from "utilities/chat";
import { useCloseDrawer } from "jobber/jobberAssistant/hooks/useCloseDrawer";
import type { AiAssistantActionFollowup } from "~/utilities/API/graphql";
import { ConversationPills } from "./ConversationPills";
import type { Followup } from "./ConversationListItem";

interface ExistingSupportConversationProps {
  conversationId: string;
  followups: AiAssistantActionFollowup[];
}

export const ExistingSupportConversation = ({
  conversationId,
  followups,
}: ExistingSupportConversationProps) => {
  const closeChatSidebar = useCloseDrawer();
  const supportOptions: Followup[] = followups.map(item => ({
    displayText: item.displayText,
    prompt: item.prompt,
    loading: false,
  }));
  return (
    <ConversationPills
      prompts={supportOptions}
      onClick={handleClick}
      fullWidth
    />
  );

  function handleClick() {
    // We intentionally call the method twice because there is a bug in the Intercom,
    // JS API that sometimes causes the Intercom modal to not open the conversation
    // screen even though the conversation exists. A bug for this has been filed with
    // Intercom, but for now a workaround is to call the API twice.
    Intercom.EXECUTE("showConversation", conversationId);
    Intercom.EXECUTE("showConversation", conversationId);
    closeChatSidebar?.();
  }
};
