/* eslint-disable max-statements */
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { showToast } from "@jobber/components/Toast";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
  JobberPaymentsSettingsName,
} from "~/utilities/API/graphql";
import type { ClientHubSettingsQuery } from "~/utilities/API/graphql";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { CTA_DISMISS } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";
import { withSplitClient } from "utilities/split";
import { useOnlinePaymentsDefaultUpdateSplit } from "jobber/managed_accounts/Settings/useOnlinePaymentsDefaultUpdateSplit";
import { messages } from "./messages";
import { SettingsSwitch } from "./SettingsSwitch";
import { DefaultPaymentRadioGroup } from "./DefaultPaymentRadioGroup";
import styles from "./QuotePreferenceSettings.module.css";
import { useScrollToComponentAnchor } from "./useScrollToComponentAnchor";

const genericError = new Error("Could not update settings");
declare const window: Window & { dataLayer: Record<string, unknown>[] };
interface InvoicePreferencesSettingsProps {
  invoiceData?: ClientHubSettingsQuery;
  setMutationErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  payInvoices?: boolean;
  setPayInvoices: (_: boolean) => void;
  tip?: boolean;
  setTip: (_: boolean) => void;
  dismissCta?(): void;
  setToggleAchFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  updateDefaultPreference?: {
    preference: string;
    achEnabled: boolean;
  };
  setIsAchEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  isAchEnabled?: boolean;
}

function InvoicePreferencesSettings({
  invoiceData,
  setMutationErrorMessage,
  payInvoices,
  setPayInvoices,
  tip,
  setTip,
  dismissCta,
  setToggleAchFlag,
  updateDefaultPreference,
  setIsAchEnabled,
  isAchEnabled,
}: InvoicePreferencesSettingsProps) {
  const { inOnlinePaymentsDefaultUpdateSplit } =
    useOnlinePaymentsDefaultUpdateSplit();
  const { formatMessage } = useIntl();
  const isFirstRender = useRef(true);
  const [preference, setDefaultPreference] = useState<
    JobberPaymentsDefaultPaymentPreference | string
  >(
    !invoiceData?.achPayments.enabled && !inOnlinePaymentsDefaultUpdateSplit
      ? JobberPaymentsDefaultPaymentPreference.CREDIT_CARD
      : invoiceData?.jobberPaymentsSettings?.invoicePaymentOptionPreference ??
          JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
  );

  const anchorRef = useScrollToComponentAnchor("#invoice-preferences");

  useEffect(() => {
    // Should be removed with OnlinePaymentDefault clean up - JOB-110205
    setDefaultPreference(
      !invoiceData?.achPayments.enabled && !inOnlinePaymentsDefaultUpdateSplit
        ? JobberPaymentsDefaultPaymentPreference.CREDIT_CARD
        : invoiceData?.jobberPaymentsSettings?.invoicePaymentOptionPreference ??
            JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inOnlinePaymentsDefaultUpdateSplit]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (updateDefaultPreference) {
      setDefaultPreference(updateDefaultPreference.preference);
      setIsAchEnabled?.(updateDefaultPreference?.achEnabled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDefaultPreference]);

  const [ctaDismiss] = useMutation(CTA_DISMISS);
  const { permissions } = useJobberPayments();

  return (
    <div ref={anchorRef}>
      <Card title={formatMessage(messages.invoicePreferenceSettingsCardTitle)}>
        <Content spacing="large">
          <div className={styles.lastSwitch}>
            <SettingsSwitch
              settingsName={JobberPaymentsSettingsName.PAY_INVOICES}
              title={formatMessage(messages.invoicePreferenceSettingsTitle)}
              defaultEnabled={invoiceData?.payInvoices.enabled ?? false}
              onError={handleMutationError}
              onSuccessCallback={updatePayInvoicesSetting}
            />
          </div>
          {permissions.canToggleTips && (
            <>
              <Divider />
              <div className={styles.lastSwitch}>
                <SettingsSwitch
                  settingsName={JobberPaymentsSettingsName.ALLOW_TIPS}
                  title={formatMessage(messages.tipSettingsTitle)}
                  defaultEnabled={invoiceData?.allowTips.enabled ?? false}
                  onError={handleMutationError}
                  onSuccessCallback={updateTipSetting}
                />
              </div>
            </>
          )}

          {permissions.canToggleAchPayments && (
            <>
              <Divider />
              <DefaultPaymentRadioGroup
                handleOnChange={handleRadioOnChange}
                selectedOption={preference}
                objectType={InvoiceOrQuote.INVOICE}
                onError={handleMutationError}
                isAchEnabled={isAchEnabled}
                setIsAchEnabled={setIsAchEnabled}
                setToggleAchFlag={setToggleAchFlag}
              />
            </>
          )}
        </Content>
      </Card>
    </div>
  );

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }

  function updatePayInvoicesSetting() {
    setPayInvoices(!payInvoices);
  }

  function updateTipSetting() {
    dismissCta?.();
    ctaDismiss({ variables: { id: "jobber_payments_tips_cta" } }).catch(
      (ctaDismissError: Error) => {
        handleMutationError(ctaDismissError as Error);
      },
    );
    setTip(!tip);
  }

  function handleRadioOnChange(value: JobberPaymentsDefaultPaymentPreference) {
    setDefaultPreference(value);
    sendGoogleTagManagerEvent(value);
    showToast({
      message: formatMessage(messages.paymentOptionChangeToastMessage),
    });
  }

  function sendGoogleTagManagerEvent(
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    window.dataLayer?.push({
      event: "select_invoice_payment_option",
      option: value, //both card and ach bank payments, card payments only or ach bank payments only
    });
  }
}

const SplitWrappedComponent = withSplitClient(InvoicePreferencesSettings);
export { SplitWrappedComponent as InvoicePreferencesSettings };
