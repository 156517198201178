import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";

export interface BottomBarDefaultFlowProps {
  enableAutomation: boolean | undefined;
  onNext: () => void;
  setShowScheduleSendModal: (value: boolean) => void;
  validateCampaignForSending: () => boolean;
  loadingAll: boolean;
  submitButtonLabel: string;
}

export function BottomBarDefaultFlow({
  enableAutomation,
  onNext,
  setShowScheduleSendModal,
  validateCampaignForSending,
  loadingAll,
  submitButtonLabel,
}: BottomBarDefaultFlowProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [initialPageLoading, setInitialPageLoading] = useState(true);

  useEffect(() => {
    if (!loadingAll && initialPageLoading) {
      setInitialPageLoading(false);
    }
  }, [initialPageLoading, loadingAll]);

  return (
    <>
      {!enableAutomation && !initialPageLoading && (
        <Button
          label={formatMessage(messages.sendCampaignLaterButton)}
          onClick={() => {
            if (validateCampaignForSending()) {
              setShowScheduleSendModal(true);
            }
          }}
          variation={"work"}
          type={"secondary"}
          fullWidth={true}
          loading={loadingAll}
        />
      )}
      {initialPageLoading ? (
        <Glimmer.Button />
      ) : (
        <Button
          label={submitButtonLabel}
          onClick={() => onNext()}
          variation={"work"}
          fullWidth={true}
          loading={loadingAll}
        />
      )}
    </>
  );
}
