import React, { useEffect, useMemo, useState } from "react";
import { Icon, type IconNames } from "@jobber/components/Icon";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import classes from "./QuickCreate.module.css";

type Label = string;
type AriaLabel = string;
type URL = string;
interface JsEvent {
  event: string;
  detail?: Record<string, string>;
}

export interface QuickCreateProps {
  items: [Label, AriaLabel, IconNames, URL | JsEvent][];
}

const InternalQuickCreate = ({ items }: QuickCreateProps) => {
  const [toggled, setToggled] = useState(false);
  const isQuickCreateCampaignExperimentEnabled = useFeatureFlag(
    SplitNames.CreateCampaignFromQuickCreateExperiment,
  );

  // Close menu if open when clicking anywhere
  useEffect(() => {
    if (toggled && isQuickCreateCampaignExperimentEnabled) {
      Amplitude.TRACK_EVENT("Interacted with Experiment", {
        experiment: "create_campaign_from_quick_create_experiment",
        interaction: "Clicked on Quick Create menu",
        source: "Quick Create",
      });
    }

    if (toggled) {
      const onClickAnyWhere = () => {
        setToggled(false);
      };

      const onKeyUp = (evt: KeyboardEvent) => {
        if (evt.key === "Escape") {
          // eslint-disable-next-line no-unused-expressions
          document
            .querySelector<HTMLButtonElement>(`.${classes.button}`)
            ?.focus();
          setToggled(false);
        }
      };

      window.addEventListener("click", onClickAnyWhere);
      window.addEventListener("keyup", onKeyUp);

      return () => {
        window.removeEventListener("click", onClickAnyWhere);
        window.removeEventListener("keyup", onKeyUp);
      };
    }
  }, [toggled, isQuickCreateCampaignExperimentEnabled]);

  // Render menu items
  const isExperimentEnabled = items.some(item => item?.[0] === "Campaign");
  const itemsEl = useMemo(
    () =>
      items.map(([label, ariaLabel, icon, urlOrEvent]) => {
        if (typeof urlOrEvent === "string") {
          const url = urlOrEvent as URL;
          return (
            <div
              className={isExperimentEnabled ? classes.experiment : ""}
              key={url}
            >
              <a
                key={url}
                aria-label={ariaLabel}
                href={url}
                onClick={() => {
                  if (label === "Campaign") {
                    Amplitude.TRACK_EVENT("Interacted with Experiment", {
                      experiment:
                        "create_campaign_from_quick_create_experiment",
                      interaction: "Clicked on Campaign create menu experiment",
                      source: "Quick Create",
                    });
                  }
                }}
              >
                <Icon name={icon} />
                {label}
              </a>
            </div>
          );
        } else {
          const event = urlOrEvent as JsEvent;
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={event.event}
              aria-label={ariaLabel}
              onClick={() => {
                // eslint-disable-next-line no-console
                window.dispatchEvent(
                  new CustomEvent(event.event, { detail: event.detail }),
                );
              }}
              href="#"
            >
              <Icon name={icon} />
              {label}
            </a>
          );
        }
      }),
    [items],
  );

  const onToggle = () => {
    setToggled(!toggled);
  };

  return (
    <div
      className={classes.container}
      data-elevation={"elevated"}
      data-expanded={toggled}
    >
      <button
        id="sidenav-quick-create"
        className={classes.button}
        onClick={onToggle}
        aria-label="Quick Create Menu"
        aria-haspopup="true"
        aria-controls="sidenav-quick-create-menu"
        aria-expanded={toggled}
      >
        <span className={classes.icon}>
          <Icon name="plus" color={"interactiveSubtle"} />
        </span>
        <span>Create</span>
      </button>
      <nav
        id="sidenav-quick-create-menu"
        aria-labelledby="sidenav-quick-create"
        data-toggled={toggled}
        className={classes.menu}
      >
        {itemsEl}
      </nav>
    </div>
  );
};

export const QuickCreate = withSplitClient(InternalQuickCreate);
