import { useCallback } from "react";
import { useCampaignCreateAllClientsMutation } from "jobber/campaigns/hooks/useCampaignCreateAllClientsMutation/useCampaignCreateAllClientsMutation";
import { useUpdateAllClientsSegmentCampaign } from "jobber/campaigns/hooks/useUpdateAllClientsSegmentCampaign/useUpdateAllClientsSegmentCampaign";
import type {
  UpdateClientSegmentArgs,
  UseUpdateClientSegmentHelperArgs,
} from "../../types";

export function useUpdateAllClientSegment({
  clientSegment,
  defaultTemplate,
}: UseUpdateClientSegmentHelperArgs) {
  const { createAllClientsCampaign } = useCampaignCreateAllClientsMutation();
  const { updateAllClientsSegmentCampaign } =
    useUpdateAllClientsSegmentCampaign();

  const updateAllClientsSegment = useCallback(
    async ({
      newSegmentCriteria,
      onSuccess,
      onError,
    }: UpdateClientSegmentArgs) => {
      if (clientSegment) {
        const result = await updateAllClientsSegmentCampaign({
          input: {
            id: clientSegment.id,
            criteria: newSegmentCriteria,
          },
        });
        if (result.userErrors.length) {
          onError(result.userErrors[0].message);
        } else if (result.clientSegment) {
          onSuccess({ clientSegment: result.clientSegment });
        }
      } else {
        if (
          !defaultTemplate.body ||
          !defaultTemplate.subject ||
          !defaultTemplate.header
        ) {
          onError("Template is missing required fields");
        }
        const result = await createAllClientsCampaign({
          input: {
            template: defaultTemplate,
            criteria: newSegmentCriteria,
          },
        });
        if (result.userErrors.length) {
          onError(result.userErrors[0].message);
        } else if (result.clientSegment) {
          onSuccess({
            clientSegment: result.clientSegment,
            campaignId: result.campaign?.id,
          });
        }
      }
    },
    [
      clientSegment,
      createAllClientsCampaign,
      defaultTemplate,
      updateAllClientsSegmentCampaign,
    ],
  );
  return updateAllClientsSegment;
}
