import { defineMessages } from "react-intl";

export const messages = defineMessages({
  titleStart: {
    id: "marketingSuiteExpansion.reviewsEngagement.titleStart",
    defaultMessage: "You have",
    description:
      "First half of seperated title for the component, title is seperated by a component.",
  },
  titleEnd: {
    id: "marketingSuiteExpansion.reviewsEngagement.titleEnd",
    defaultMessage: " reviews { equal } the { industry }",
    description:
      "Last half of seperated title for the component, title is seperated by a component.",
  },
  subtitle: {
    id: "marketingSuiteExpansion.reviewsEngagement.subtitle",
    defaultMessage:
      " than industry leaders, Google shows businesses with a large number of reviews higher in search results",
    description: "Subtitle for the component",
  },
  equalReviews: {
    id: "marketingSuiteExpansion.reviewsEngagement.equalReviews",
    defaultMessage: " the same number of",
    description:
      "Message for when the account has the same number of reviews as the industry average",
  },
});
