import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "billing.MarketingToolAddToCartModal.modalTitle",
    defaultMessage: "{addonName} added to cart",
    description: "Default title of button.",
  },
  primaryModalLabel: {
    id: "billing.MarketingToolAddToCartModal.primaryModalLabel",
    defaultMessage: "Continue to checkout",
    description: "Default label of primary button.",
  },
  secondaryModalLabel: {
    id: "billing.MarketingToolAddToCartModal.secondaryModalLabel",
    defaultMessage: "Stay on page",
    description: "Default label of secondary button.",
  },
  description: {
    id: "billing.MarketingToolAddToCartModal.description",
    defaultMessage:
      "You're one step closer to unlocking powerful marketing tools to grow your business. Continue to checkout to select a plan.",
    description: "Default description of modal.",
  },
});
