import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import type { QuoteFollowUpMaxDaysQuery } from "~/utilities/API/graphql";
import {
  AUTOMATIONS_PAGE_PATH,
  COMMUNICATION_SETTINGS_PATH,
} from "jobber/campaigns/constants";
import { QUOTE_FOLLOW_UP_MAX_DAYS_QUERY } from "./useQuoteFollowUpMaxDaysCalculator.graphql";

export function useQuoteFollowUpMaxDaysCalculator() {
  const { data, loading } = useQuery<QuoteFollowUpMaxDaysQuery>(
    QUOTE_FOLLOW_UP_MAX_DAYS_QUERY,
  );

  return useMemo(() => {
    if (loading) {
      return {
        maxQuoteFollowUpSentDays: undefined,
        redirectUrl: undefined,
      };
    }

    if (data?.feature?.enabled && data.feature.available) {
      const activeAutomationDays =
        data?.automationRules.nodes
          .filter(
            node =>
              node.trigger.arguments.model_type === "Quote" && node.active,
          )
          .map(node => node.trigger.arguments.days)
          .flat() || [];

      const maxAutomationDays =
        activeAutomationDays.length > 0
          ? Math.max(...activeAutomationDays)
          : undefined;

      return {
        maxQuoteFollowUpSentDays: maxAutomationDays,
        redirectUrl: maxAutomationDays ? AUTOMATIONS_PAGE_PATH : undefined,
      };
    }

    if (data?.clientNotification?.enabled) {
      const notificationDays = data.clientNotification.schedules.nodes
        .filter(
          (node): node is { offset: { value: number } } =>
            node.offset !== undefined,
        )
        .map(node => node.offset.value);

      const maxNotificationDays =
        notificationDays.length > 0 ? Math.max(...notificationDays) : undefined;

      return {
        maxQuoteFollowUpSentDays: maxNotificationDays,
        redirectUrl: COMMUNICATION_SETTINGS_PATH,
      };
    }

    return {
      maxQuoteFollowUpSentDays: undefined,
      redirectUrl: undefined,
    };
  }, [data, loading]);
}
