import { type ApolloError, useLazyQuery } from "@apollo/client";
import { useCallback, useMemo, useRef, useState } from "react";
import type {
  AllClientsCriteriaInput,
  ClientSegmentWithClientFieldsFragment,
  GetCommsClientsSegmentQuery,
  GetCommsClientsSegmentQueryVariables,
  PastClientsCriteriaInput,
  Segment,
  UpcomingClientsCriteriaInput,
} from "~/utilities/API/graphql";
import {
  PAST_CLIENT_DEFAULT_INTERVAL,
  PAST_CLIENT_DEFAULT_UNIT,
  UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_INTERVAL,
  UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_UNIT,
} from "jobber/campaigns/constants";
import {
  type ClientsCriteriaFromSegment,
  getSegmentCriteriasFromSegments,
} from "jobber/campaigns/utils/segmentCriteriaUtils";
import { GET_CLIENTS_SEGMENT } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentData/useClientSegmentData.graphql";

export interface ClientSegmentPayload {
  total?: number;
  data?: ClientSegmentWithClientFieldsFragment;
  type?: Segment;
  criteria?: ClientsCriteriaFromSegment;
}

export interface ClientSegmentConditionalVariables {
  isPastClientDrawer?: boolean;
  isAllClientDrawer?: boolean;
  isUpcomingClientDrawer?: boolean;
}

export interface ClientSegmentsData {
  pastClients: ClientSegmentPayload;
  allClients: ClientSegmentPayload;
  upcomingClients: ClientSegmentPayload;
}

export interface UseClientSegmentData {
  fetchData: (variables: GetCommsClientsSegmentQueryVariables) => void;
  refetch: (variables: GetCommsClientsSegmentQueryVariables) => void;
  fullReload: boolean;
  loadingMore: boolean;
  error?: ApolloError;
  clientSegmentsData: ClientSegmentsData;
}

export interface useClientSegmentDataPayload {
  conditionalVariables: ClientSegmentConditionalVariables;
  segmentCriterias?: {
    allClientsSegmentCriteria: ClientsCriteriaFromSegment;
    pastClientsSegmentCriteria: ClientsCriteriaFromSegment;
    upcomingClientsSegmentCriteria: UpcomingClientsCriteriaInput;
  };
  segmentType?: Segment;
}
export function useClientSegmentData({
  conditionalVariables,
  segmentCriterias: segmentCriterias,
  segmentType,
}: useClientSegmentDataPayload): UseClientSegmentData {
  const [data, setData] = useState<GetCommsClientsSegmentQuery>();
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);

  const segmentCriteriaVariables = useMemo(() => {
    return getSegmentCriteriasFromSegments({
      segmentCriterias: segmentCriterias,
      segmentType,
    });
  }, [segmentCriterias, segmentType]);

  const [execQuery, { fetchMore, error }] = useLazyQuery<
    GetCommsClientsSegmentQuery,
    GetCommsClientsSegmentQueryVariables
  >(GET_CLIENTS_SEGMENT, {
    fetchPolicy: "cache-first",
    variables: { ...conditionalVariables, ...segmentCriteriaVariables },
  });

  const initialQueryLoaded = useRef(false);
  const _fetchData = useCallback(
    (variables: GetCommsClientsSegmentQueryVariables) => {
      if (!initialQueryLoaded.current) return;
      setLoadingMore(true);
      void fetchMore({
        variables,
      })
        .then(value => {
          setData(value.data);
        })
        .finally(() => {
          setLoadingMore(false);
        });
    },
    [fetchMore],
  );

  const _refetch = useCallback(
    (variables: GetCommsClientsSegmentQueryVariables) => {
      setLoadingRefresh(true);
      void execQuery({
        variables,
      })
        .then(value => {
          setData(value.data);
        })
        .finally(() => {
          initialQueryLoaded.current = true;
          setLoadingRefresh(false);
        });
    },
    [execQuery],
  );

  const clientSegmentsData: ClientSegmentsData = useMemo(() => {
    return {
      pastClients: {
        total: data?.commsPastClientsSegment?.clients?.totalCount,
        data: data?.commsPastClientsSegment,
        type: data?.commsPastClientsSegment?.type,
        criteria: {
          unit: PAST_CLIENT_DEFAULT_UNIT,
          interval: PAST_CLIENT_DEFAULT_INTERVAL,
        } as PastClientsCriteriaInput,
      },
      allClients: {
        total: data?.commsAllClientsSegment?.clients?.totalCount,
        data: data?.commsAllClientsSegment,
        type: data?.commsAllClientsSegment?.type,
        criteria: {} as AllClientsCriteriaInput,
      },
      upcomingClients: {
        total: data?.commsUpcomingClientsSegment?.clients?.totalCount,
        data: data?.commsUpcomingClientsSegment,
        type: data?.commsUpcomingClientsSegment?.type,
        criteria: {
          unit: UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_UNIT,
          interval: UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_INTERVAL,
        } as UpcomingClientsCriteriaInput,
      },
    };
  }, [data]);

  return {
    fetchData: _fetchData,
    refetch: _refetch,
    fullReload: loadingRefresh || !initialQueryLoaded.current,
    loadingMore,
    error,
    clientSegmentsData,
  };
}
