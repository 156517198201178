import { gql } from "@apollo/client";

export const AI_ASSISTANT_ACTION_FOLLOWUPS_DELETE = gql`
  mutation AiAssistantActionFollowupsDelete($requestId: Uuid!) {
    aiAssistantActionFollowupsDelete(requestId: $requestId) {
      userErrors {
        message
        path
      }
    }
  }
`;
