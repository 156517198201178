/* eslint-disable no-restricted-imports */
import type { BillingAddress } from "~/bunker/paymentMethodForm/components/BillingAddress/interfaces/BillingAddress";
import type { BankPaymentACHSettings } from "../BankPaymentACH";
import type { GroupedPaymentTypes } from "../PaymentMethodSelector";
import type { ClientBalanceDetail } from "../ClientBalanceDisplay";
import type { SuperSaveItem } from "../SuperSaveButton";

export enum PaymentMethodListType {
  CREDIT_CARD = "credit_card",
  BANK_ACCOUNT = "bank_account",
  CREDIT_CARD_AND_BANK_ACCOUNT = "credit_card_and_bank_account",
}

export interface CollectPaymentDialogProps {
  amount: string;
  attachedToId: string;
  attachedToType: "invoice" | "quote";
  clientId: string;
  paymentOrigin: number;
  groupedPaymentTypes: GroupedPaymentTypes;

  isDialogOpen?: boolean;
  title?: string;
  clientName?: string;
  billingAddress?: BillingAddress;
  itemLabel?: string;
  oauthStateId?: string;

  defaultNoteValue?: string;
  reloadAfterSuccess?: boolean;
  clientDetails?: ClientBalanceDetail[];
  superSaveItems?: SuperSaveItem[];

  disableAmountEdit?: boolean;
  disablePaymentTypeEdit?: boolean;
  signatureRequired?: boolean;
  showSecurityAssurance?: boolean;
  showSavePaymentMethod?: boolean;

  mandatoryCardOnFile?: boolean;
  bankPaymentACHSettings: BankPaymentACHSettings;
  allowCreditCardPayments: boolean;
  companyName: string;
  isSuperSave: boolean;
  achAllowed: boolean;
  defaultPaymentMethod?: string;

  onDialogClose?(): void;
  onChargeButtonFormat?(formattedAmount: string): string;
}
