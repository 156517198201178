import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { messageType } from "./ConversationListItem";
import styles from "./ChatIcon.module.css";

interface ChatIconProps {
  type: messageType;
}

export function ChatIcon({ type }: ChatIconProps) {
  const [isComplete] = useState(type === messageType.RESPONSE);

  if (isComplete) {
    return (
      <Icon
        rotationContainerStyle={{ animationName: "none" }}
        state={"complete"}
      />
    );
  }

  return <ChatIconAnimated type={type} />;
}

function ChatIconAnimated({ type }: ChatIconProps) {
  const isLoading = [
    messageType.PARTIAL_RESPONSE,
    messageType.LOADING,
  ].includes(type);
  // Counts animation iterations, used to stop the animation cleanly
  const rotationIteration = useRef(0);

  const onAnimationIteration = useCallback(() => {
    rotationIteration.current++;
  }, []);

  const state = isLoading ? "loading" : "post-loading";

  const rotationContainerStyle = useMemo(
    () => ({
      animationIterationCount:
        state === "loading" ? "infinite" : rotationIteration.current + 1,
    }),
    [state],
  );

  return (
    <Icon
      state={state}
      onAnimationIteration={onAnimationIteration}
      rotationContainerStyle={rotationContainerStyle}
    />
  );
}

interface IconProps {
  onAnimationIteration?: () => void;
  rotationContainerStyle: React.CSSProperties;
  state: "loading" | "post-loading" | "complete";
}

function Icon({
  rotationContainerStyle,
  state,
  onAnimationIteration,
}: IconProps) {
  const computedStyles = useMemo(
    () => ({
      bigSparklesContainerClass: classNames(styles.sparkle, {
        [styles.bigSparkleTranslateLoading]: state === "loading",
        [styles.bigSparkleTranslateUnloading]:
          state !== "loading" && state !== "complete",
      }),
      bigSparklesInnerClass: classNames(styles.sparkleInner, {
        [styles.bigSparkleInnerLoading]: state === "loading",
        [styles.bigSparkleInnerUnloading]:
          state !== "loading" && state !== "complete",
      }),
      smallSparklesClass: classNames(styles.sparkle, {
        [styles.smallSparkleLoading]: state === "loading",
        [styles.smallSparkleUnloading]:
          state !== "loading" && state !== "complete",
      }),
    }),
    [state],
  );

  return (
    <div
      className={styles.container}
      data-testid={state !== "complete" ? "loading-container" : undefined}
    >
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={computedStyles.smallSparklesClass}
          clipRule="evenodd"
          d="M6 2C6.55231 2 7 2.44772 7 3V4L8 5H9C9.55231 5 10 5.44772 10 6C10 6.55228 9.55231 7 9 7H8L7 8V9C7 9.55228 6.55231 10 6 10C5.44769 10 5 9.55228 5 9V8L4 7H3C2.44769 7 2 6.55228 2 6C2 5.44772 2.44769 5 3 5H4L5 4V3C5 2.44772 5.44769 2 6 2Z"
          fillRule="evenodd"
        />
        <path
          className={computedStyles.smallSparklesClass}
          clipRule="evenodd"
          d="M7 14C7.55231 14 8 14.4477 8 15V16L9 17H10C10.5523 17 11 17.4477 11 18C11 18.5523 10.5523 19 10 19H9L8 20V21C8 21.5523 7.55231 22 7 22C6.44769 22 6 21.5523 6 21V20L5 19H4C3.44769 19 3 18.5523 3 18C3 17.4477 3.44769 17 4 17H5L6 16V15C6 14.4477 6.44769 14 7 14Z"
          fillRule="evenodd"
        />
        <g className={computedStyles.bigSparklesContainerClass}>
          <g
            className={styles.bigSparkleRotationContainer}
            onAnimationIteration={onAnimationIteration}
            style={rotationContainerStyle}
          >
            <path
              clipRule="evenodd"
              d="M15.9386 3.99994C15.6833 2.99481 14.2554 2.99481 14.0001 3.99994L12.0001 9.49994L8.68653 11.1145C7.97298 11.4891 7.97298 12.5107 8.68653 12.8853L12.0001 14.4999L14.0309 20.1836C14.2861 21.1887 15.7141 21.1887 15.9693 20.1836L18.0001 14.4999L21.3136 12.8853C22.0272 12.5107 22.0272 11.4891 21.3136 11.1145L18.0001 9.49994L15.9386 3.99994Z"
              fillRule="evenodd"
            />
            <path
              className={computedStyles.bigSparklesInnerClass}
              clipRule="evenodd"
              d="M14.0001 13.9999L13.0001 12.9999L11.0001 11.9999L13.0001 10.9999L14.0001 9.99994L15.0001 6.99994L16.0001 9.99994L17.0001 10.9999L19.0001 11.9999L17.0001 12.9999L16.0001 13.9999L15.0001 16.9999L14.0001 13.9999Z"
              fillRule="evenodd"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_961_30965"
            x1="11.9244"
            x2="12.1822"
            y1="2"
            y2="21.9967"
          >
            <stop offset="0.15" stopColor="#2E2452" />
            <stop offset="0.9" stopColor="#7462B9" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_961_30965"
            x1="11.9244"
            x2="12.1822"
            y1="2"
            y2="21.9967"
          >
            <stop offset="0.15" stopColor="#2E2452" />
            <stop offset="0.9" stopColor="#7462B9" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint2_linear_961_30965"
            x1="11.9244"
            x2="12.1822"
            y1="2"
            y2="21.9967"
          >
            <stop offset="0.15" stopColor="#2E2452" />
            <stop offset="0.9" stopColor="#7462B9" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
