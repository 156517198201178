import React from "react";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { UkKycComplianceRegistrationMain } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration";

export function UkKycComplianceRegistrationWrapper() {
  const { countryCode } = useAccount();

  const ukKycEligible = countryCode === "GB";

  return <>{ukKycEligible && <UkKycComplianceRegistrationMain />}</>;
}
