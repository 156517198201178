import { forwardRef, useEffect, useImperativeHandle } from "react";
import type { MutableRefObject, ReactNode, Ref } from "react";
import { useMutation, useQuery } from "@apollo/client";
import type { CtaLookupQuery } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import {
  CTA_CONVERT,
  CTA_DISMISS,
  CTA_QUERY,
  CTA_SHOWN,
} from "./CallToAction.graphql";

export interface CallToActionRef {
  convert(eventProperties?: EventProperties): void;
  dismiss(eventProperties?: EventProperties): void;
}

export interface CallToActionProps {
  readonly children: ReactNode | ReactNode[];

  /**
   * Name of CTA.
   */
  readonly ctaName: string;
  /**
   * Whether to track the CTA viewed event.
   */
  readonly trackCtaViewed?: boolean;
}

const AMPLITUDE_EVENTS = {
  CTA_CONVERT: "CTA Clicked",
  CTA_DISMISS: "CTA Dismissed",
  CTA_VIEWED: "CTA Viewed",
};

export const CallToAction = forwardRef(function CallToActionInternal(
  { children, ctaName, trackCtaViewed = false }: CallToActionProps,
  ref: Ref<CallToActionRef>,
) {
  const { loading, error, data } = useQuery<CtaLookupQuery>(CTA_QUERY, {
    variables: { id: ctaName },
  });

  const [ctaShown] = useMutation(CTA_SHOWN);
  const [ctaConvert] = useMutation(CTA_CONVERT);
  const [ctaDismiss] = useMutation(CTA_DISMISS);

  // Expose a `dismiss` method through this component's ref.
  useImperativeHandle(ref, () => ({
    convert: eventProperties => {
      ctaConvert({
        variables: { id: ctaName },
      }).catch((ctaConvertError: string) => {
        throw new Error(ctaConvertError);
      });
      trackAnalytics(AMPLITUDE_EVENTS.CTA_CONVERT, ctaName, eventProperties);
    },
    dismiss: eventProperties => {
      ctaDismiss({
        variables: { id: ctaName },
      }).catch((ctaDismissError: string) => {
        throw new Error(ctaDismissError);
      });
      trackAnalytics(AMPLITUDE_EVENTS.CTA_DISMISS, ctaName, eventProperties);
    },
  }));

  useEffect(() => {
    if (data?.callToAction?.shouldShow) {
      ctaShown({
        variables: { id: ctaName },
      }).catch((ctaShownError: string) => {
        throw new Error(ctaShownError);
      });

      if (trackCtaViewed) {
        trackAnalytics(AMPLITUDE_EVENTS.CTA_VIEWED, ctaName);
      }
    }
  }, [data?.callToAction?.shouldShow, ctaName, ctaShown, trackCtaViewed]);

  if (loading || error || !data) {
    return <></>;
  }

  return <>{data.callToAction?.shouldShow && children}</>;
});

export function trackAnalytics(
  eventName: string,
  ctaName: string,
  eventProperties?: EventProperties,
) {
  /* Takes the location href and removes any '/' at the end */
  const sourceUrl = window.location.href.replace(/\/$/, "");

  /* Gets the last segment of the href */
  const source = sourceUrl.substr(sourceUrl.lastIndexOf("/") + 1);

  /* Send to Amplitude */
  Amplitude.TRACK_EVENT(eventName, {
    source: source,
    name: ctaName,
    ...eventProperties,
  });
}

export function dismissCTA(
  ref: MutableRefObject<CallToActionRef>,
  eventProperties?: EventProperties,
) {
  return () => {
    if (ref.current) ref.current.dismiss(eventProperties);
  };
}

export function convertCTA(
  ref: MutableRefObject<CallToActionRef>,
  eventProperties?: EventProperties,
) {
  return () => {
    if (ref.current) ref.current.convert(eventProperties);
  };
}
