import { defineMessages } from "react-intl";

export const messages = defineMessages({
  invoiceAverageTotalMetricTitle: {
    id: "invoices.invoicesIndex.invoicesKPISection.invoicesAverage.metricTitle",
    defaultMessage: "Average invoice",
    description: "The title of the average invoices total metric",
  },
  invoiceIssuedMetricTitle: {
    id: "invoices.invoicesIndex.invoicesKPISection.invoicesIssued.metricTitle",
    defaultMessage: "Issued",
    description: "The title of the invoices issued metric",
  },
  invoiceThirtyDayMetricDateRange: {
    id: "invoices.invoicesIndex.invoicesKPISection.metric30DayRange",
    defaultMessage: "Past 30 days",
    description: "The 30 day date range for the metric",
  },
  invoiceOverview: {
    id: "invoices.invoicesIndex.invoicesKPISection.invoiceOverview",
    defaultMessage: "Overview",
    description: "The title of the invoice overview section",
  },
  averageDaysToGettingPaidTitle: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidTitle",
    defaultMessage: "Avg time to paid",
    description: "The title of the average days to getting paid metric",
  },
  averageDaysToGettingPaidSubtitle: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidSubtitle",
    defaultMessage: "Past 30 days",
    description: "The subtitle of the average days to getting paid metric",
  },
  averageDaysToGettingPaidTooltip: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidTooltip",
    defaultMessage:
      "Average time it took to get an invoice from issued to paid in the last 30 days.",
    description: "The tooltip for the average days to getting paid metric",
  },
  averageDaysToGettingPaidCommercialExplanationTooltip: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidSplitTooltip",
    defaultMessage:
      "Average time in days between invoices issued and paid dates. Clients with a company name are considered commercial.",
    description:
      "The tooltip for the average days to getting paid split card metric",
  },
  averageDaysToGettingPaid: {
    id: "managedAccount.financialInsightsSection.receivables.averageDaysToGettingPaid",
    defaultMessage:
      "{averageDaysToGettingPaid, plural, one {# day} other {# days}}",
    description: "The average days it takes the user to get paid",
  },
  averageDaysToGettingPaidCommercialTrend: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidCommercialTrend",
    defaultMessage: "Commercial",
    description: "The trend for the first average days to getting paid metric",
  },
  averageDaysToGettingPaidResidentialTrend: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidResidentialTrend",
    defaultMessage: "Residential",
    description: "The trend for the second average days to getting paid metric",
  },
  averageDaysToGettingPaidTrendValue: {
    id: "invoices.invoicesIndex.invoicesKPISection.averageDaysToGettingPaidTrendValue",
    defaultMessage: "{value, plural, one {# day} other {# days}}",
    description: "The trend value for the average days to getting paid metric",
  },
});
