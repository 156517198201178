import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { withSplitClient } from "utilities/split";
import type { GetCurrentUserQuery } from "~/utilities/API/graphql";
import styles from "./OnlinePaymentsToggle.module.css";
import { PaymentMethodToggle } from "./PaymentMethodToggle";
import { messages } from "./messages";
import { usePartialPaymentsSplit } from "./usePartialPaymentsSplit";
import { MandatoryPaymentMethodOnFile } from "./MandatoryPaymentMethodOnFile";

export interface OnlinePaymentsSettingsProps {
  showAchPayments: boolean;
  isMandatoryCardOnFileSettingEnabled: boolean;
  inMandatoryPaymentMethodOnFileSplit?: boolean;
  initalMandatoryPaymentMethodOnFile?: boolean;
  objectName: "quote" | "invoice";
  allowOnlinePayment: boolean;
  disableCreditCardPayments: boolean;
  disableAchPayments: boolean;
  currentUser?: GetCurrentUserQuery;
  jobberPaymentsEnabled?: boolean;
  partialPaymentsDisabled?: boolean;
  isProgressInvoicing?: boolean;
  showPaymentsToggle?: boolean;
}

const OnlinePaymentsSettings: React.FC<OnlinePaymentsSettingsProps> = ({
  showAchPayments,
  isMandatoryCardOnFileSettingEnabled,
  inMandatoryPaymentMethodOnFileSplit,
  initalMandatoryPaymentMethodOnFile,
  objectName,
  allowOnlinePayment,
  disableAchPayments,
  disableCreditCardPayments,
  jobberPaymentsEnabled,
  partialPaymentsDisabled,
  isProgressInvoicing,
  showPaymentsToggle,
  currentUser,
}) => {
  const { hasPartialPayments } = usePartialPaymentsSplit();
  const { formatMessage } = useIntl();
  const [creditCardEnabled, setCreditCardEnabled] = useState<boolean>(
    !disableCreditCardPayments,
  );
  const [achEnabled, setAchEnabled] = useState<boolean>(!disableAchPayments);
  const [partialPaymentsEnabled, setPartialPaymentsEnabled] = useState<boolean>(
    !partialPaymentsDisabled,
  );
  const [
    mandatoryPaymentMethodOnFileEnabled,
    setMandatoryPaymentMethodOnFileEnabled,
  ] = useState<boolean>(Boolean(initalMandatoryPaymentMethodOnFile));
  const [
    disableMandatoryPaymentMethodOnFile,
    setDisableMandatoryPaymentMethodOnFile,
  ] = useState<boolean>(!achEnabled && !creditCardEnabled);

  const paymentName = {
    quote: "Quote Deposits",
    invoice: "Invoice Payments",
  };
  const shouldShowPartialPaymentsToggle =
    objectName == "invoice" &&
    isProgressInvoicing !== true &&
    hasPartialPayments &&
    jobberPaymentsEnabled;

  useEffect(() => {
    const paymentMethodEnabled = creditCardEnabled || achEnabled;
    setDisableMandatoryPaymentMethodOnFile(!paymentMethodEnabled);
    if (!paymentMethodEnabled) {
      setMandatoryPaymentMethodOnFileEnabled(false);
    }
  }, [achEnabled, creditCardEnabled]);

  const isAdminOrOwner = !!(
    currentUser?.user?.isAdmin || currentUser?.user?.isAccountOwner
  );

  return (
    <div>
      <Content>
        <div>
          {(showPaymentsToggle !== false ||
            shouldShowPartialPaymentsToggle) && (
            <Heading level={4}>
              {formatMessage(messages.onlinePaymentSettingsTitle, {
                object: objectName === "quote" ? "Deposit" : "Invoice",
              })}
            </Heading>
          )}
          {showPaymentsToggle !== false && (
            <div className={styles.secondaryText}>
              <Text
                size="base"
                variation={isAdminOrOwner ? "success" : "subdued"}
              >
                {formatMessage(messages.onlinePaymentSettingsSecondaryText, {
                  object: objectName,
                  link: isAdminOrOwner ? (
                    <a
                      href={`/jobber_payments/setup#${objectName === "invoice" ? "invoice-preferences" : "quote-preferences"}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={styles.underlineText}
                    >
                      {formatMessage(messages.onlinePaymentSettingsLinkText)}
                    </a>
                  ) : (
                    formatMessage(messages.onlinePaymentSettingsLinkText)
                  ),
                })}
              </Text>
            </div>
          )}
        </div>

        <Content>
          {showPaymentsToggle !== false && (
            <>
              <PaymentMethodToggle
                labelText={formatMessage(
                  messages.onlinePaymentSettingsCardPaymentToggleText,
                )}
                paymentMethodType={"credit_card"}
                objectName={objectName}
                enabled={creditCardEnabled}
                onChange={setCreditCardEnabled}
              />
              {showAchPayments && (
                <>
                  <PaymentMethodToggle
                    labelText={formatMessage(
                      messages.onlinePaymentSettingsAchPaymentToggleText,
                    )}
                    paymentMethodType={"ach"}
                    disableToggle={isMandatoryCardOnFileSettingEnabled}
                    objectName={objectName}
                    disabledToggleHelpText={formatMessage(
                      messages.onlinePaymentSettingsAchPaymentDisabledText,
                    )}
                    enabled={achEnabled}
                    onChange={setAchEnabled}
                  />
                </>
              )}
            </>
          )}

          {inMandatoryPaymentMethodOnFileSplit && objectName === "quote" && (
            <MandatoryPaymentMethodOnFile
              mandatoryPaymentMethodOnFileEnabled={
                mandatoryPaymentMethodOnFileEnabled
              }
              setMandatoryPaymentMethodOnFileEnabled={
                setMandatoryPaymentMethodOnFileEnabled
              }
              disableToggle={disableMandatoryPaymentMethodOnFile}
            />
          )}

          {shouldShowPartialPaymentsToggle && (
            <div className={styles.partialPaymentToggle}>
              <PaymentMethodToggle
                labelText={formatMessage(
                  messages.onlinePaymentSettingsPartialPaymentToggleText,
                )}
                paymentMethodType={"partial_payment"}
                objectName={objectName}
                enabled={partialPaymentsEnabled}
                onChange={setPartialPaymentsEnabled}
              />
            </div>
          )}

          {showPaymentsToggle !== false &&
            showAchPayments &&
            !allowOnlinePayment && (
              <Text variation="subdued">
                {formatMessage(messages.onlinePaymentSettingsNotEnabledText, {
                  object: paymentName[objectName],
                  link: (
                    <a
                      href={"/jobber_payments/setup"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.underlineText}
                    >
                      {formatMessage(
                        messages.onlinePaymentSettingsNotEnabledLinkText,
                      )}
                    </a>
                  ),
                })}
              </Text>
            )}
        </Content>
      </Content>
    </div>
  );
};

const SplitWrappedComponent = withSplitClient(OnlinePaymentsSettings);
export { SplitWrappedComponent as OnlinePaymentsSettings };
