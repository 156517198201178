import { useEffect, useReducer, useState } from "react";
import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import type {
  AutomationRule,
  AutomationRuleEditMutation,
  AutomationRuleEditMutationVariables,
  Template,
} from "~/utilities/API/graphql";
import { AUTOMATION_RULE_EDIT_MUTATION } from "~/jobber/automations/graphql/AutomationRuleEdit.graphql";
import { AUTOMATION_RULE_NAME } from "jobber/campaigns/constants";
import { updatedCampaignAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import {
  parseConditions,
  parseIncomingConditions,
} from "jobber/campaigns/utils/automationRuleUtils";
import { getDefaultAutomationRule } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/defaultAutomationRule";
import { useAutomationControlsCreateCampaign } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useAutomationControls/useAutomationControlsCreateCampaign";
import type { AddConditionAction } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/RuleBuilderReducer";
import { reducer as ruleBuilderReducer } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/RuleBuilderReducer";
import type { AllConditionTypes } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import { CreateRuleConditionTypes } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import { messages } from "./messages";

export interface useAutomationControlsProps {
  onNextNav: () => void;
  savedRule?: AutomationRule;
  templateType?: Template;
  isAutomated?: boolean;
}

export interface useAutomationControlsResponse {
  conditions: AllConditionTypes[];
  dispatch: React.Dispatch<AddConditionAction>;
  trigger: number;
  setTrigger: (val: number) => void;
  onNext: () => void;
  loading: boolean;
  errors: string[] | null;
  clearError: () => void;
}

export function useAutomationControls({
  onNextNav,
  savedRule,
  templateType,
  isAutomated,
}: useAutomationControlsProps): useAutomationControlsResponse {
  const { formatMessage } = useIntl();

  const defaultAutomationRule = getDefaultAutomationRule(
    templateType as Template,
  );
  const [trigger, setTrigger] = useState(
    defaultAutomationRule?.trigger.arguments.time || 0,
  );

  const [conditions, dispatch] = useReducer(ruleBuilderReducer, [
    ...(defaultAutomationRule?.condition.conditions ?? []),
  ]);

  const [errors, setErrors] = useState<string[] | null>(null);

  useEffect(() => {
    if (savedRule) {
      setTrigger(savedRule.trigger.arguments.time);
      dispatch({
        type: "loadConditions",
        payload: parseIncomingConditions(savedRule, defaultAutomationRule),
      });
    }
  }, [defaultAutomationRule, savedRule]);

  const [editRuleMutation, { loading: loadingEditRule }] = useMutation<
    AutomationRuleEditMutation,
    AutomationRuleEditMutationVariables
  >(AUTOMATION_RULE_EDIT_MUTATION);

  const validateInputs = () => {
    const inputErrors: string[] = [];
    conditions.forEach(condition => {
      if (condition === undefined || !condition.arguments.type) return;

      switch (condition.arguments.type) {
        case CreateRuleConditionTypes.ClientTags:
        case CreateRuleConditionTypes.QuoteClientTags:
          if (condition.arguments.fields.tag === "") {
            inputErrors.push(formatMessage(messages.tagErrorMessage));
          }
          break;
        case CreateRuleConditionTypes.LineItems:
        case CreateRuleConditionTypes.QuoteLineItems:
          if (condition.arguments.fields.item === "") {
            inputErrors.push(formatMessage(messages.lineItemErrorMessage));
          }
          break;
      }
    });
    if (inputErrors.length > 0) {
      setErrors(inputErrors);
      return false;
    }
    return true;
  };

  const { createCampaign, loadingCreateAutomation } =
    useAutomationControlsCreateCampaign({
      defaultAutomationRule,
      trigger,
      conditions,
      savedRule,
      templateType,
      isAutomated,
      setErrors,
    });

  const onNext = async () => {
    setErrors(null);

    if (!validateInputs()) {
      return;
    }

    if (savedRule) {
      await editRule();
    } else {
      createCampaign();
    }
  };

  const editRule = async () => {
    await editRuleMutation({
      variables: {
        ruleId: savedRule?.id ?? "", // this should never be undefined
        trigger: {
          task: defaultAutomationRule?.trigger.task,
          arguments: {
            ...defaultAutomationRule?.trigger.arguments,
            time: trigger,
            /* eslint-disable @typescript-eslint/naming-convention */
            model_type: defaultAutomationRule?.trigger.arguments.model_type,
            model_id: savedRule?.trigger.arguments.model_id,
            /* eslint-enable @typescript-eslint/naming-convention */
          },
        },
        condition: {
          task: "condition",
          conditions: parseConditions(conditions as AllConditionTypes[]),
        },
        action: defaultAutomationRule?.action,
        name:
          AUTOMATION_RULE_NAME.find(
            automation => automation.id === templateType,
          )?.name || "",
      },
      onCompleted: data => {
        const userErrors = data?.automationRuleEdit.userErrors ?? [];
        if (userErrors.length > 0) {
          // there was a user error
          setErrors([userErrors[0].message]);
        } else {
          onNextNav();
        }
        updatedCampaignAmplitudeEvent({
          interaction: "Conditions saved",
          /* eslint-disable @typescript-eslint/naming-convention */
          is_automated: isAutomated,
          template_type: templateType,
          /* eslint-enable @typescript-eslint/naming-convention */
        });
      },
      onError: () => {
        // there was a server error
        setErrors([formatMessage(messages.editErrorMessage)]);
      },
    });
  };

  return {
    conditions: conditions as AllConditionTypes[],
    dispatch,
    trigger,
    setTrigger: setTrigger as (val: number) => void,
    onNext,
    loading: loadingCreateAutomation || loadingEditRule,
    errors,
    clearError: () => setErrors(null),
  };
}
