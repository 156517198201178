import React, { useState } from "react";
import { Banner, Content, Flex, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { RuleBuilder } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/RuleBuilder";
import type {
  AddConditionAction,
  RemoveConditionAction,
  UpdateConditionAction,
} from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/RuleBuilderReducer";
import type { AutomationRule } from "~/utilities/API/graphql";
import type { AllConditionTypes } from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/types";
import { messages } from "./messages";
import styles from "./SetAutomationRules.module.css";

interface SetAutomationRulesProps {
  automationRule: AutomationRule;
  conditions: AllConditionTypes[];
  dispatch: React.Dispatch<
    AddConditionAction | UpdateConditionAction | RemoveConditionAction
  >;
  triggerArguments: number;
  setTriggerArguments: (val: number) => void;
  errors: string[] | null;
  clearError: () => void;
}

export function SetAutomationRules({
  automationRule,
  conditions,
  dispatch,
  triggerArguments,
  setTriggerArguments,
  errors,
  clearError,
}: SetAutomationRulesProps) {
  const { formatMessage } = useIntl();
  const [actionArguments, setActionArguments] = useState("");
  return (
    <div className={styles.editorContainer}>
      <Content spacing="large">
        <Text>{formatMessage(messages.automationsSubTitle)}</Text>
        {errors && errors.length > 0 && (
          <Banner type="error" dismissible onDismiss={clearError}>
            {errors.length > 1 &&
              formatMessage(messages.mutlipleConditionErrors)}
            <ul className={errors.length > 1 ? styles.listStyle : undefined}>
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </ul>
          </Banner>
        )}
        <Flex template={["shrink", "shrink"]} direction="column">
          <RuleBuilder
            selectedAutomation={automationRule}
            triggerArguments={{
              argument: triggerArguments,
              setArgument: setTriggerArguments,
            }}
            actionArguments={{
              argument: actionArguments,
              setArgument: setActionArguments,
            }}
            conditions={conditions}
            dispatch={dispatch}
            hasSubmitted={!!errors}
          />
        </Flex>
      </Content>
    </div>
  );
}
