import { generatePath, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_TEMPLATE_EDIT_PATH,
  CAMPAIGNS_TEMPLATE_PATH,
} from "jobber/campaigns/constants";
import { messages } from "jobber/campaigns/components/Bottombar/messages";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";

export function useClientSegmentPageNavigation(
  campaignId?: string,
  setRenderExitConfirmationModal?: (newValue: boolean) => void,
  renderExitConfirmationModal?: boolean,
) {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const query = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const fromLanding = query.get("from_landing") === "1";
  const backPath = fromLanding
    ? generatePath(CAMPAIGNS_LANDING_PAGE_PATH)
    : campaignId
      ? generatePath(CAMPAIGNS_TEMPLATE_EDIT_PATH, { campaignId })
      : generatePath(CAMPAIGNS_TEMPLATE_PATH);

  const {
    campaignSegment: {
      isSegmentCriteriaFormValid,
      isDirty,
      resetCriteriaState,
    },
  } = useCampaignWizardContext();

  const shouldRenderExitConfirmationModal =
    isDirty && isSegmentCriteriaFormValid && setRenderExitConfirmationModal;

  function onCancel() {
    if (shouldRenderExitConfirmationModal) {
      setRenderExitConfirmationModal(true);
    } else {
      history.push(backPath);
    }
  }

  function onNext() {
    const path = campaignId
      ? shouldRenderExitConfirmationModal && renderExitConfirmationModal
        ? generatePath(CAMPAIGNS_TEMPLATE_EDIT_PATH, { campaignId })
        : generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId })
      : shouldRenderExitConfirmationModal && renderExitConfirmationModal
        ? generatePath(CAMPAIGNS_TEMPLATE_PATH)
        : generatePath(CAMPAIGNS_LANDING_PAGE_PATH);

    history.push({ pathname: path, search: history.location.search });
  }

  function onLeaveForm() {
    resetCriteriaState();
    history.push(backPath);
  }

  return {
    onCancel,
    onNext,
    onCancelLabel: formatMessage(
      backPath === CAMPAIGNS_LANDING_PAGE_PATH
        ? messages.cancelButton
        : messages.backButton,
    ),
    onLeaveForm,
  };
}
