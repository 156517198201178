import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "billing.editBillingInfoModal.modalTitle",
    defaultMessage: "Update payment details",
    description: "Title for editBillingInfo modal",
  },
  paymentDetailsButton: {
    id: "billing.editBillingInfoModal.paymentDetailsButton",
    defaultMessage: "Payment Details",
    description: "Payment Details button text",
  },
  saveInformationLabel: {
    id: "billing.editBillingInfoModal.saveInformationLabel",
    defaultMessage: "Save Information",
    description: "Save information button label text",
  },
  cancelLabel: {
    id: "billing.editBillingInfoModal.cancelLabel",
    defaultMessage: "Cancel",
    description: "Cancel button label text",
  },
  successfulUpdate: {
    id: "billing.editBillingInfoModal.successfulUpdate",
    defaultMessage: "Updated payment details",
    description: "Successful update message",
  },
  cancelAriaLabel: {
    id: "billing.editBillingInfoModal.cancelAriaLabel",
    defaultMessage: "Cancel updating payment details",
    description: "Aria label for Cancel updating payment details button",
  },
});
