import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailPreviewEmailSubjectFrom: {
    id: "campaigns.campaignReviewPage.emailPreviewEmailSubjectFrom",
    defaultMessage: "From: {fromEmail}",
    description: "From text in email preview email subject",
  },
  emailPreviewQuestionsMessage: {
    id: "campaigns.campaignReviewPage.emailPreviewQuestionsMessage",
    defaultMessage:
      "<b>Questions? </b>Reply to this email to start a conversation with the {companyName} team.",
    label: "Message in the end of the email preview about contacting the SP",
    description: "Preview questions message",
  },
  emailPreviewFooterCompanyInfo: {
    id: "campaigns.campaignReviewPage.emailPreviewFooterCompanyInfo",
    defaultMessage: "© {currentYear} {companyName}",
    description: "Company info in email footer",
  },
  emailPreviewFooterPoweredBy: {
    id: "campaigns.campaignReviewPage.emailPreviewFooterPoweredBy",
    defaultMessage: "Powered by Jobber",
    description: "Powered by Jobber in email footer",
  },
  emailPreviewFooterUnsubscribeMessage: {
    id: "campaigns.campaignReviewPage.emailPreviewFooterUnsubscribe",
    defaultMessage: "No longer want to receive these emails?",
    description: "Unsubscribe message in email footer",
  },
  emailPreviewFooterUnsubscribeLink: {
    id: "campaigns.campaignReviewPage.emailPreviewFooterUnsubscribeLink",
    defaultMessage: "Unsubscribe",
    description:
      "Unsubscribe link in email footer. Used for styling the text to look like a link",
  },
  emailPreviewCTAButtonLabel: {
    id: "campaigns.campaignReviewPage.emailPreviewCTAButtonLabel",
    defaultMessage: "Request a Quote",
    description: "Label for the CTA button in the email preview",
  },
  logoAltText: {
    id: "campaigns.campaignReviewPage.logoAltText",
    defaultMessage: "Company Logo",
    description: "Alt text for the logo in the email preview",
  },
});
