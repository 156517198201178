import { gql } from "@apollo/client";

export const ACCOUNT_SETTINGS_QUERY = gql`
  query accountSettings {
    account {
      id
      name
      settings {
        localization {
          currencySymbol
          countryCode
          currencyCode
          timeFormat
          dateFormat
        }
      }
    }
    jobCostingFeature: feature(key: "job_costing") {
      available
      enabled
      id
    }
    quoteMarginsFeature: feature(key: "quote_margins") {
      available
      enabled
      id
    }
    advancedQuotingFeature: feature(key: "advanced_quoting") {
      available
      enabled
      id
    }
    timeSheetsFeature: feature(key: "time_sheets") {
      available
      enabled
      id
    }
    expensesFeature: feature(key: "expenses") {
      available
      enabled
      id
    }
    selfServeBookingsFeature: feature(key: "self_serve_bookings") {
      available
      enabled
      id
    }
    franchiseReportingFeature: feature(key: "franchise_reporting") {
      available
      enabled
      id
    }
    arrivalWindowsFeature: feature(key: "arrival_windows") {
      available
      enabled
      id
    }
    leadOrganizationFeature: feature(key: "lead_organization") {
      available
      enabled
      id
    }
    salespersonAttributionFeature: feature(key: "salesperson_attribution") {
      available
      enabled
      id
    }
    leadAttributionFeature: feature(key: "lead_attribution") {
      available
      enabled
      id
    }
    marketingClientReferralsFeature: feature(
      key: "marketing_client_referrals"
    ) {
      available
      enabled
      id
    }
    schedulingJobsNotesFeature: feature(key: "scheduling_jobs_notes") {
      available
      enabled
      id
    }
    quoteTemplatesFeature: feature(key: "quote_templates") {
      available
      enabled
      id
    }
  }
`;
