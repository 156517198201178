import React, { useState } from "react";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import { Heading } from "@jobber/components/Heading";
import { ReferralMessagingModal } from "jobber/bunker/settings/components/ReferralMessagingModal/ReferralMessagingModal";
import type { ReferralMessagingModalFieldsFragment } from "~/utilities/API/graphql";

interface Props {
  switchState: boolean;
  messagingSettings: ReferralMessagingModalFieldsFragment;
  onSwitch: (enabled: boolean) => void;
}

export function SCReferralCard({
  switchState,
  messagingSettings,
  onSwitch,
}: Props) {
  const [modalOpen, setModalState] = useState<boolean>(false);
  const onEdit = () => setModalState(true);

  // The zero-width whitespace character, along with the two spaces is used
  // here to get the markdown to allow an empty line
  const description =
    `Display “refer a friend” prompt in client hub. Clients receive a link to your request form to share with friends over text, email, or social media. Each new lead is tagged with who referred them, so you can thank clients for their advocacy.` +
    "  \n​  \n" +
    "Your request form must be visible to clients and leads in your [requests settings](/work_request_settings/edit)";

  return (
    <Card title="Referrals settings">
      <FeatureSwitch
        enabled={switchState}
        title="Clients can refer a friend"
        description={description}
        hasSaveIndicator={true}
        onSwitch={onSwitch}
        onEdit={onEdit}
      >
        <Heading level={4}>Templates</Heading>
        <Text>
          Customize the messaging and incentives your client and their referred
          friend will see.{" "}
        </Text>
      </FeatureSwitch>
      <ReferralMessagingModal
        messagingSettings={messagingSettings}
        isOpen={modalOpen}
        setModalOpenState={setModalState}
      />
    </Card>
  );
}
