import { useCallback, useEffect, useMemo, useState } from "react";
import { interactedWithClientSegmentAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import {
  type ClientsCriteriaFromSegment,
  getClientsCriteriaFromSegment,
} from "jobber/campaigns/utils/segmentCriteriaUtils";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { type ClientSegmentFragment, Segment } from "~/utilities/API/graphql";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import type { AdditionalCriteriaUnion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/types";
import {
  DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA,
  DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
} from "jobber/campaigns/constants";

export enum DrawerMode {
  View = "VIEW",
  Edit = "EDIT",
}

export interface UseClientSegmentSideDrawer {
  isOpen: boolean;
  drawerState: DrawerMode;
  setDrawerState: (drawerState: DrawerMode) => void;
  openSideDrawer: (name: string) => void;
  closeSideDrawer: () => void;
  segmentName: string;
  segmentCriteria: ClientsCriteriaFromSegment;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
  additionalCriteria: AdditionalCriteriaUnion[];
  setSegmentCriteria: (
    value: ClientsCriteriaFromSegment,
    isDirty?: boolean,
  ) => void;
}

interface SegmentCriteriaSwitch {
  segmentCriteria: ClientsCriteriaFromSegment;
  additionalCriteria: AdditionalCriteriaUnion[];
  setSegmentCriteria: (
    value: ClientsCriteriaFromSegment,
    setDirty?: boolean,
  ) => void;
  updateAdditionalCriteria: (
    action: AdditionalCriteriaReducerActions,
    isDirty?: boolean,
  ) => void;
}

export interface ClientSegmentSideDrawerProps {
  clientSegment: ClientSegmentFragment | undefined;
}

// eslint-disable-next-line max-statements
export function useClientSegmentSideDrawer(
  { clientSegment }: ClientSegmentSideDrawerProps = {
    clientSegment: undefined,
  },
): UseClientSegmentSideDrawer {
  const [isOpen, setIsOpen] = useState(false);
  const [segmentName, setSegmentName] = useState("Past clients");
  const [drawerState, setDrawerState] = useState<DrawerMode>(DrawerMode.View);
  const {
    campaignSegment: {
      updateAllClientsAdditionalCriteria,
      updatePastClientsAdditionalCriteria,
      allClientsAdditionalCriteria,
      pastClientsAdditionalCriteria,
      upcomingClientsSegmentCriteria,
      upcomingClientsAdditionalCriteria,
      selectedSegmentType,
      allClientsSegmentCriteria,
      setPastClientsSegmentCriteria,
      setUpcomingClientsSegmentCriteria,
      updateUpcomingClientsAdditionalCriteria,
      pastClientsSegmentCriteria,
      setAllClientsSegmentCriteria,
    },
  } = useCampaignWizardContext();

  const openSideDrawer = useCallback((name: string) => {
    setIsOpen(true);
    setSegmentName(name);
    interactedWithClientSegmentAmplitudeEvent({
      interaction: "Drawer Viewed",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      segment_type: name,
    });
  }, []);

  // eslint-disable-next-line max-statements
  const resetToInitialState = useCallback(() => {
    function resetPastClientState() {
      updatePastClientsAdditionalCriteria({
        type: "resetState",
      });
      setPastClientsSegmentCriteria(DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA);
    }
    function resetUpcomingClientState() {
      updateUpcomingClientsAdditionalCriteria({
        type: "resetState",
      });
      setUpcomingClientsSegmentCriteria(
        DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA,
      );
    }
    function resetAllClientState() {
      updateAllClientsAdditionalCriteria({ type: "resetState" });
      setAllClientsSegmentCriteria(undefined);
    }

    if (clientSegment?.type === Segment.ALL_CLIENTS) {
      updateAllClientsAdditionalCriteria({
        type: "setState",
        payload: {
          additionalCriteria: clientSegment?.additionalCriteria?.nodes,
        },
      });
      setAllClientsSegmentCriteria(
        getClientsCriteriaFromSegment({
          clientSegmentType: clientSegment?.type,
          criteria: clientSegment?.criteria.nodes || [],
          additionalCriteria: clientSegment?.additionalCriteria?.nodes || [],
        }),
      );

      resetPastClientState();
      resetUpcomingClientState();
    } else if (clientSegment?.type === Segment.PAST_CLIENTS) {
      updatePastClientsAdditionalCriteria({
        type: "setState",
        payload: {
          additionalCriteria: clientSegment?.additionalCriteria?.nodes,
        },
      });
      setPastClientsSegmentCriteria(
        getClientsCriteriaFromSegment({
          clientSegmentType: clientSegment?.type,
          criteria: clientSegment?.criteria.nodes || [],
          additionalCriteria: clientSegment?.additionalCriteria?.nodes || [],
        }),
      );
      resetAllClientState();
      resetUpcomingClientState();
    } else if (clientSegment?.type === Segment.UPCOMING_CLIENTS) {
      updateUpcomingClientsAdditionalCriteria({
        type: "setState",
        payload: {
          additionalCriteria: clientSegment?.additionalCriteria?.nodes,
        },
      });
      setUpcomingClientsSegmentCriteria(
        getClientsCriteriaFromSegment({
          clientSegmentType: clientSegment?.type,
          criteria: clientSegment?.criteria.nodes || [],
          additionalCriteria: clientSegment?.additionalCriteria?.nodes || [],
        }),
      );
      resetAllClientState();
      resetPastClientState();
    }
  }, [
    clientSegment?.type,
    clientSegment?.additionalCriteria?.nodes,
    clientSegment?.criteria.nodes,
    updateAllClientsAdditionalCriteria,
    setAllClientsSegmentCriteria,
    updatePastClientsAdditionalCriteria,
    setPastClientsSegmentCriteria,
    updateUpcomingClientsAdditionalCriteria,
    setUpcomingClientsSegmentCriteria,
  ]);
  const closeSideDrawer = useCallback(() => {
    setIsOpen(false);
    setDrawerState(DrawerMode.View);
  }, []);

  useEffect(() => {
    if (!isOpen || drawerState === DrawerMode.View) {
      resetToInitialState();
    }
  }, [resetToInitialState, isOpen, drawerState]);

  const {
    additionalCriteria,
    segmentCriteria,
    setSegmentCriteria,
    updateAdditionalCriteria,
  } = useMemo((): SegmentCriteriaSwitch => {
    switch (selectedSegmentType) {
      case Segment.ALL_CLIENTS:
        return {
          segmentCriteria: allClientsSegmentCriteria,
          additionalCriteria: allClientsAdditionalCriteria,
          setSegmentCriteria: setAllClientsSegmentCriteria,
          updateAdditionalCriteria: updateAllClientsAdditionalCriteria,
        };
      case Segment.PAST_CLIENTS:
        return {
          segmentCriteria: pastClientsSegmentCriteria,
          additionalCriteria: pastClientsAdditionalCriteria,
          setSegmentCriteria: setPastClientsSegmentCriteria,
          updateAdditionalCriteria: updatePastClientsAdditionalCriteria,
        };
      case Segment.UPCOMING_CLIENTS:
        return {
          segmentCriteria: upcomingClientsSegmentCriteria,
          additionalCriteria: upcomingClientsAdditionalCriteria,
          setSegmentCriteria: setUpcomingClientsSegmentCriteria,
          updateAdditionalCriteria: updateUpcomingClientsAdditionalCriteria,
        };
    }
  }, [
    allClientsAdditionalCriteria,
    allClientsSegmentCriteria,
    pastClientsAdditionalCriteria,
    pastClientsSegmentCriteria,
    selectedSegmentType,
    setAllClientsSegmentCriteria,
    setPastClientsSegmentCriteria,
    setUpcomingClientsSegmentCriteria,
    upcomingClientsAdditionalCriteria,
    upcomingClientsSegmentCriteria,
    updateAllClientsAdditionalCriteria,
    updatePastClientsAdditionalCriteria,
    updateUpcomingClientsAdditionalCriteria,
  ]);

  return {
    isOpen,
    drawerState,
    setDrawerState,
    openSideDrawer,
    closeSideDrawer,
    segmentName,
    segmentCriteria,
    setSegmentCriteria,
    updateAdditionalCriteria,
    additionalCriteria,
  };
}
