import { useEffect } from "react";
import type { MessageCenterActionType } from "jobber/chat/components/MessageCenterButton/MessageCenterContext";
import { useUkKycRegistrationDetailsQuery } from "jobber/hooks/comms/useUkKycRegistrationDetailsQuery";
import {
  UkKycRegistrationRequirementTypes,
  UkKycRegistrationStatuses,
} from "~/utilities/API/graphql";
import type { AccountContextType } from "~/utilities/contexts/internal/AccountContext";

export function useUkKycRegistrationData(
  dispatch: React.Dispatch<MessageCenterActionType>,
  accountContext: AccountContextType,
) {
  const runUseUkKycRegistrationDetailsQuery =
    accountContext.countryCode === "GB";
  const { data, loading, error } = useUkKycRegistrationDetailsQuery(
    runUseUkKycRegistrationDetailsQuery,
  );
  // Completed UK registration has
  // 1. Twilio approved status
  // 2. Registration requirement is not required
  // 3. Acknowledged at date is present
  // 4. Account is from the UK
  const isUkKycRegistrationRequired = (): boolean => {
    if (accountContext.countryCode !== "GB") {
      return false;
    }

    if (
      data?.commsUkKycRegistrationDetails.registration?.status !==
        UkKycRegistrationStatuses.TWILIO_APPROVED ||
      data?.commsUkKycRegistrationDetails.registrationRequirement !==
        UkKycRegistrationRequirementTypes.NOT_REQUIRED ||
      !data?.commsUkKycRegistrationDetails.registration?.acknowledgedAt
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    dispatch({
      type: "SET_UK_KYC_REGISTRATION_DATA",
      ukKycComplianceRegistrationData: data?.commsUkKycRegistrationDetails,
      isUkKycComplianceRegistrationHardBlocked:
        data?.commsUkKycRegistrationDetails?.registrationRequirement ===
        UkKycRegistrationRequirementTypes.HARD_BLOCK,
    });
  }, [dispatch, data]);

  return {
    loading,
    error,
    isUkKycRegistrationRequired,
  };
}
