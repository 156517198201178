import React from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/messages";
import { JobTypeTypeEnum, Segment } from "~/utilities/API/graphql";
import type {
  AdditionalCriteriaClientTagCriteriaFragment,
  AdditionalCriteriaClientTypeCriteriaFragment,
  AdditionalCriteriaJobStatusCriteriaFragment,
  AdditionalCriteriaJobTypeCriteriaFragment,
  AdditionalCriteriaLineItemCriteriaFragment,
} from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { IntlSelect } from "jobber/campaigns/views/CampaignRecipientsPage/components/IntlSelect/IntlSelect";
import { ClientTagCriterion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/components/ClientTagCriterion/ClientTagCriterion";
import { LineItemCriterion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/components/LineItemCriterion/LineItemCriterion";
import { JobStatusCriterion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/components/JobStatusCriterion/JobStatusCriterion";
import { ClientTypeCriterion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/components/ClientTypeCriterion/ClientTypeCriterion";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import styles from "./AdditionalCriterion.module.css";
import {
  CLIENT_TAGS_OPTION,
  CLIENT_TYPE_OPTION,
  DEFAULT_OPTION,
  JOB_STATUS_OPTION,
  JOB_TYPE_ONE_OFF_OPTION,
  JOB_TYPE_RECURRING_OPTION,
  LINE_ITEMS_OPTION,
} from "./constants";
import type { AdditionalCriteriaUnion } from "../../types";
import { SupportedCriteriaTypes } from "../../types";

export const ADDITIONAL_CRITERIA_OPTIONS_BY_SEGMENT = {
  [Segment.ALL_CLIENTS]: [
    DEFAULT_OPTION,
    CLIENT_TAGS_OPTION,
    CLIENT_TYPE_OPTION,
    LINE_ITEMS_OPTION,
    JOB_TYPE_RECURRING_OPTION,
    JOB_TYPE_ONE_OFF_OPTION,
    JOB_STATUS_OPTION,
  ],
  [Segment.PAST_CLIENTS]: [
    DEFAULT_OPTION,
    CLIENT_TAGS_OPTION,
    LINE_ITEMS_OPTION,
    JOB_TYPE_RECURRING_OPTION,
    JOB_TYPE_ONE_OFF_OPTION,
  ],
  [Segment.UPCOMING_CLIENTS]: [
    DEFAULT_OPTION,
    CLIENT_TAGS_OPTION,
    LINE_ITEMS_OPTION,
    JOB_TYPE_RECURRING_OPTION,
    JOB_TYPE_ONE_OFF_OPTION,
    JOB_STATUS_OPTION,
  ],
};

interface AdditionalCriterionProps {
  criterion: AdditionalCriteriaUnion;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
  index: number;
}

export function isLineItemCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaLineItemCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.LINE_ITEMS;
}

function isClientTypeCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaClientTypeCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.CLIENT_TYPE;
}

function isClientTagCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaClientTagCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.CLIENT_TAGS;
}

function isJobTypeCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaJobTypeCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.JOB_TYPE;
}

function isJobStatusCriterion(
  criteria: AdditionalCriteriaUnion,
): criteria is AdditionalCriteriaJobStatusCriteriaFragment {
  return criteria.__typename === SupportedCriteriaTypes.JOB_STATUS;
}

function criterionValue(criterion: AdditionalCriteriaUnion) {
  if (isJobTypeCriterion(criterion)) {
    const jobTypeValue = (
      criterion as AdditionalCriteriaJobTypeCriteriaFragment
    ).jobTypeValue;

    return jobTypeValue === JobTypeTypeEnum.RECURRING
      ? JOB_TYPE_RECURRING_OPTION.value
      : JOB_TYPE_ONE_OFF_OPTION.value;
  }

  return criterion.__typename;
}

export function AdditionalCriterion({
  criterion,
  updateAdditionalCriteria,
  index,
}: AdditionalCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();

  const {
    campaignSegment: { selectedSegmentType, segmentCriteriaFormRef },
  } = useCampaignWizardContext();

  const criteriaOptions =
    ADDITIONAL_CRITERIA_OPTIONS_BY_SEGMENT[selectedSegmentType];

  return (
    <Content spacing="small">
      <Heading level={5}>
        {formatMessage(messages.andAdditionalCriteria)}
      </Heading>
      <div className={styles.criteriaSelection}>
        <IntlSelect
          options={criteriaOptions}
          placeholder={formatMessage(messages.chooseCriteria)}
          value={criterionValue(criterion)}
          onChange={handleCriteriaChange}
        />
        <Button
          icon={"cross"}
          ariaLabel={formatMessage(messages.removeCriteria)}
          type="tertiary"
          variation="subtle"
          onClick={handleRemoveCriteria}
        />
      </div>
      {isLineItemCriterion(criterion) && (
        <LineItemCriterion
          lineItemValue={criterion.lineItemValue}
          operator={criterion.operator}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isClientTagCriterion(criterion) && (
        <ClientTagCriterion
          clientTagValue={criterion.clientTagValue}
          operator={criterion.operator}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isJobStatusCriterion(criterion) && (
        <JobStatusCriterion
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
      {isClientTypeCriterion(criterion) && (
        <ClientTypeCriterion
          clientTypeValue={criterion.clientTypeValue}
          criteriaIndex={index}
          updateAdditionalCriteria={updateAdditionalCriteria}
        />
      )}
    </Content>
  );

  function handleCriteriaChange(selectedCriteria: string) {
    if (selectedCriteria === JOB_TYPE_RECURRING_OPTION.value) {
      updateAdditionalCriteria({
        type: "updateJobTypeCriteria",
        payload: {
          jobTypeValue: JobTypeTypeEnum.RECURRING,
          index,
        },
      });

      return;
    }

    if (selectedCriteria === JOB_TYPE_ONE_OFF_OPTION.value) {
      updateAdditionalCriteria({
        type: "updateJobTypeCriteria",
        payload: {
          jobTypeValue: JobTypeTypeEnum.ONE_OFF,
          index,
        },
      });

      return;
    }

    updateAdditionalCriteria({
      type: "updateCriteriaType",
      payload: { criteriaType: selectedCriteria, index: index },
    });
  }

  function handleRemoveCriteria() {
    updateAdditionalCriteria({
      type: "removeCriteria",
      payload: { index: index },
    });
    // We need to validate the form with this submit after
    // the potentially invalid field is removed
    setTimeout(() => {
      segmentCriteriaFormRef?.current?.submit();
    }, 100);
  }
}
