import React from "react";
import { Heading } from "@jobber/components";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { Receivables } from "./Receivables";
import { useReceivables } from "./useReceivables";
import { LoadingLayout } from "../LoadingLayout";
import { FinancialInsightCardWithButtonWrapper } from "../FinancialInsightCardWithButtonWrapper";

export const ReceivablesLoader = () => {
  const { formatMessage } = useIntl();
  const { data, loading } = useReceivables();
  let cardContent;

  if (loading) {
    cardContent = <LoadingLayout />;
  } else {
    cardContent = (
      <Receivables
        totalBalance={data?.topClientsBalance.totalBalance}
        numberOfClientsWithBalances={
          data?.topClientsBalance.numberOfClientsWithBalances
        }
        averageDaysToGettingPaid={mapAverageDaysToGettingPaid()}
        percentageOfUpcomingJobsOnAutopay={
          data?.insightsAndAccountingReceivables
            ?.percentageOfUpcomingJobsOnAutopay
        }
        automaticPaymentsEnabled={data?.feature?.available}
      />
    );
  }

  return (
    <FinancialInsightCardWithButtonWrapper>
      <Heading level={3}>{formatMessage(messages.receivablesHeading)}</Heading>
      {cardContent}
    </FinancialInsightCardWithButtonWrapper>
  );

  function mapAverageDaysToGettingPaid() {
    const invoiceReceivables =
      data?.insightsAndAccountingReceivables?.invoiceReceivables;
    return {
      commercial: invoiceReceivables?.commercial?.avgDaysToGettingPaid,
      residential: invoiceReceivables?.residential?.avgDaysToGettingPaid,
      previous: {
        commercial:
          invoiceReceivables?.previous?.commercial?.avgDaysToGettingPaid,
        residential:
          invoiceReceivables?.previous?.residential?.avgDaysToGettingPaid,
      },
    };
  }
};
