import React, { useEffect, useMemo, useState } from "react";
import { Button, type ButtonProps } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { showToast } from "@jobber/components/Toast";
import { Glimmer } from "@jobber/components";
import styles from "./BottomBar.module.css";
import { messages } from "./messages";

interface sendTestButton {
  onSendTest: () => void;
  buttonType: ButtonProps["type"];
}

export interface BottomBarProps {
  onCancel: () => void;
  onNext?: () => void;
  onSaveDraft?: () => Promise<string>;
  sendTestButton?: sendTestButton;
  step: number;
  loading?: boolean;
  isFromEmailValidated?: boolean;
  onCancelLabel?: string;
  children?: React.ReactNode;
}

export function BottomBar({
  onCancel,
  onNext,
  onSaveDraft,
  sendTestButton,
  step: currentStep,
  loading,
  isFromEmailValidated,
  onCancelLabel,
  children,
}: BottomBarProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { mediumAndUp } = useBreakpoints();

  const ctaLabel = useMemo(() => {
    switch (currentStep) {
      case 2: {
        return formatMessage(messages.continueToContent);
      }
      case 3: {
        return formatMessage(messages.reviewCampaign);
      }
      case 4: {
        // TODO: understand why the message should default to sendCampaign when the bottom bar is loading
        if (isFromEmailValidated || loading) {
          return formatMessage(messages.sendCampaign);
        }

        return formatMessage(messages.verifyFromEmail);
      }
      default: {
        return formatMessage(messages.continue);
      }
    }
  }, [currentStep, formatMessage, isFromEmailValidated, loading]);

  function saveDraft() {
    if (onSaveDraft) {
      void onSaveDraft()
        .then(() => {
          showToast({
            message: formatMessage(messages.savedDraftSuccessToast),
            variation: "success",
          });
        })
        .catch(() => undefined);
    }
  }

  const [initialpageLoading, setInitialPageLoading] = useState(true);

  useEffect(() => {
    if (!loading && initialpageLoading) {
      setInitialPageLoading(false);
    }
  }, [initialpageLoading, loading]);

  const displaySaveDraftButton =
    ctaLabel === formatMessage(messages.reviewCampaign);

  const nextButton = ({ fullWidth } = { fullWidth: false }) => {
    return (
      onNext &&
      (initialpageLoading ? (
        <Glimmer.Button></Glimmer.Button>
      ) : (
        <Button
          label={ctaLabel}
          onClick={onNext}
          type={"primary"}
          loading={loading}
          fullWidth={fullWidth}
        />
      ))
    );
  };

  const secondaryButtons = (displaySaveDraftButton || sendTestButton) && (
    <div className={styles.bottomBarRow}>
      {displaySaveDraftButton && (
        <div className={styles.column}>
          {initialpageLoading ? (
            <Glimmer.Button></Glimmer.Button>
          ) : (
            <Button
              label={formatMessage(messages.saveDraft)}
              onClick={saveDraft}
              variation={"work"}
              type={"tertiary"}
              fullWidth={true}
              loading={loading}
              id={"addons-save-draft-button"}
            />
          )}
        </div>
      )}
      {sendTestButton && (
        <div className={styles.column}>
          {initialpageLoading ? (
            <Glimmer.Button></Glimmer.Button>
          ) : (
            <Button
              label={formatMessage(messages.sendATestLabel)}
              onClick={sendTestButton?.onSendTest}
              type={sendTestButton?.buttonType}
              variation={"work"}
              size={"base"}
              fullWidth={true}
              loading={loading}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={styles.bottomBarContainer}
      data-testid="bottom-bar-container"
    >
      {!mediumAndUp && (
        <div
          className={
            !displaySaveDraftButton && !onNext
              ? styles.bottomBarMedAndDownNoButtons
              : styles.bottomBarMedAndDown
          }
        >
          {secondaryButtons}

          {children}

          {!children && onNext && (
            <div className={styles.bottomBarRow}>
              {nextButton({ fullWidth: true })}
            </div>
          )}
        </div>
      )}
      {mediumAndUp && (
        <div className={styles.bottomBarMedAndUp}>
          {initialpageLoading ? (
            <Glimmer.Button></Glimmer.Button>
          ) : (
            <Button
              label={getBackLabel()}
              onClick={onCancel}
              type={"primary"}
              variation={"subtle"}
              loading={loading}
            />
          )}
          <div className={styles.bottomRightBarMedAndUp}>
            {displaySaveDraftButton &&
              (initialpageLoading ? (
                <Glimmer.Button></Glimmer.Button>
              ) : (
                <Button
                  label={formatMessage(messages.saveDraft)}
                  onClick={saveDraft}
                  variation={"work"}
                  type={"tertiary"}
                  loading={loading}
                  id={"addons-save-draft-button"}
                />
              ))}
            {sendTestButton &&
              (initialpageLoading ? (
                <Glimmer.Button></Glimmer.Button>
              ) : (
                <Button
                  label={formatMessage(messages.sendATestLabel)}
                  onClick={sendTestButton?.onSendTest}
                  type={sendTestButton?.buttonType}
                  variation={"work"}
                  size={"base"}
                  loading={loading}
                />
              ))}
            {children ? children : nextButton()}
          </div>
        </div>
      )}
    </div>
  );

  function getBackLabel() {
    if (onCancelLabel) return onCancelLabel;
    return currentStep === 1
      ? formatMessage(messages.cancelButton)
      : formatMessage(messages.backButton);
  }
}
