import type { MutableRefObject } from "react";
import React, { useCallback, useRef, useState } from "react";
import { Heading, InlineLabel, SideDrawer } from "@jobber/components";
import { Button } from "@jobber/components/Button";
import { useIntl } from "react-intl";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { Intercom } from "utilities/chat";
import { AiAssistantConversationIntent } from "~/utilities/API/graphql";
import { useCurrentUser } from "jobber/settings/users/hooks/useCurrentUser";
import { OPEN_ASSISTANT_FROM_BUTTON } from "./constants";
import { JobberAssistantConversationLoader } from "./components/JobberAssistantConversationLoader";
import {
  AssistantPromptEventQueueProvider,
  useAssistantPromptEventQueue,
} from "./AssistantPromptEventQueueProvider";
import styles from "./JobberAssistantRoot.module.css";
import { messages } from "./messages";
import { useAssistantEventListeners } from "./hooks";

export function JobberAssistantRoot() {
  return (
    <AssistantPromptEventQueueProvider>
      <APIProvider>
        <IntlProvider>
          <JobberAssistantButtonInternal />
        </IntlProvider>
      </APIProvider>
    </AssistantPromptEventQueueProvider>
  );
}

function JobberAssistantButtonInternal() {
  const { formatMessage } = useIntl();
  const [conversationIntent, setConversationIntent] =
    useState<AiAssistantConversationIntent>();

  const { currentUser } = useCurrentUser(["isAdmin"]);

  const { enqueuePrompt } = useAssistantPromptEventQueue();
  const buttonContainerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const handleOpenFromInsightWidget = useCallback(
    (event: CustomEvent<{ message: string; widgetName?: string }>) => {
      enqueuePrompt({
        message: event.detail?.message,
        widgetName: event.detail?.widgetName,
      });
      setConversationIntent(AiAssistantConversationIntent.BUSINESS_COACHING);
      trackOpenEvent("insight-widget");
    },
    [enqueuePrompt],
  );
  const handleOpenFromTopNavButton = useCallback(() => {
    trackOpenEvent("top-nav");
    setConversationIntent(AiAssistantConversationIntent.BUSINESS_COACHING);
  }, []);
  const handleOpenFromSupportChat = useCallback(() => {
    trackOpenEvent("support-chat");
    setConversationIntent(AiAssistantConversationIntent.PRODUCT_SUPPORT);
  }, []);

  useAssistantEventListeners({
    handleOpenFromInsightWidget,
    handleOpenFromTopNavButton,
    handleOpenFromSupportChat,
  });

  const title = (
    <div className={styles.sidebarTitle}>
      <Heading level={2}>{formatMessage(messages.copilotName)}</Heading>
      <InlineLabel>{formatMessage(messages.betaFlag)}</InlineLabel>
    </div>
  );

  return (
    <div data-testid="jobber-assistant">
      {currentUser?.isAdmin && (
        <div className={styles.assistantButtonWrapper}>
          <Button
            type="secondary"
            variation="subtle"
            icon={"sparkles"}
            ariaLabel="Open Jobber Copilot"
            onClick={() =>
              window.dispatchEvent(
                new CustomEvent(OPEN_ASSISTANT_FROM_BUTTON, {
                  detail: { source: "top-nav" },
                }),
              )
            }
          />
        </div>
      )}
      <SideDrawer
        onRequestClose={onCloseSideDrawer}
        open={!!conversationIntent}
        variation="base"
      >
        <SideDrawer.Title>{title}</SideDrawer.Title>
        <JobberAssistantConversationLoader
          closeChatSidebar={onCloseSideDrawer}
          conversationIntent={conversationIntent}
        />
      </SideDrawer>
    </div>
  );

  function onCloseSideDrawer() {
    Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
      name: "chatbot_drawer_closed",
    });
    setConversationIntent(undefined);
    // We need the tick to wait for the redraw after the drawer is removed.
    setTimeout(() => buttonContainerRef.current?.focus(), 0);
  }
}

function trackOpenEvent(source: "top-nav" | "insight-widget" | "support-chat") {
  Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
    name: "chatbot_drawer_opened",
    source,
  });
  if (source === "top-nav") {
    Intercom.EXECUTE("trackEvent", "click_on_jobber_copilot");
  }
}
