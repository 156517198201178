import React from "react";
import { useIntl } from "react-intl";
import { Emphasis, Text } from "@jobber/components";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import styles from "../../UpcomingPayouts.module.css";
import { messages } from "../../messages";
import { parseISO8601AsLocalDate } from "../../utils";

interface UpcomingPayoutProps {
  expectedPayout: {
    arrivalDate: string;
    id: string;
    netAmount: number;
  };
}

export const UpcomingPayout = ({ expectedPayout }: UpcomingPayoutProps) => {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();
  const arrivalDate = parseISO8601AsLocalDate(expectedPayout.arrivalDate);

  return (
    <Text size="small" key={expectedPayout.id}>
      <Emphasis variation="bold">
        {currencyFormatter(expectedPayout.netAmount / 100)}
      </Emphasis>
      <span className={styles.expectedText}>
        {formatMessage(messages.estimatedBy)}
        {arrivalDate}
      </span>
    </Text>
  );
};
