import { useAtlantisTheme } from "@jobber/components";
import React from "react";

/* There is no typescript definition for the props passed into custom components in the Nivo library
 * and unknown how to test this function as of yet
 */
/* istanbul ignore next -- @preserve */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function HoverPoint(props: any) {
  const { theme, tokens } = useAtlantisTheme();
  const defaultChartColor =
    theme === "dark"
      ? tokens["color-base-purple--500"]
      : tokens["color-base-purple--700"];
  const { currentPoint } = props;

  if (currentPoint) {
    return (
      <g>
        <circle
          fill={tokens["color-surface"]}
          r={4}
          strokeWidth={2}
          stroke={defaultChartColor}
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
      </g>
    );
  }
}
