import { defineMessages } from "react-intl";

export const messages = defineMessages({
  uploadSizeError: {
    id: "campaign.imageUploader.uploadSizeError",
    defaultMessage: " is too large. Try an image size less than 5MB.",
    description:
      "Error message for when the user tries to upload an image that is too large",
  },
  uploadButton: {
    id: "campaign.imageUploader.uploadButton",
    defaultMessage: "Upload Image",
    description: "Button text displayed to the user",
  },
  uploadDescription: {
    id: "campaign.imageUploader.uploadDescription",
    defaultMessage: "Maximum size 5MB per file",
    description: "Description displayed below the image uploader",
  },
  uploadHeading: {
    id: "campaign.imageUploader.uploadHeading",
    defaultMessage: "Header image (optional)",
    description: "Heading for the header image upload section",
  },
  uploadSubheading: {
    id: "campaign.imageUploader.uploadSubheading",
    defaultMessage:
      "Adds an image to show below your heading. Recommended aspect ratio: Landscape",
    description: "Subheading for the header image upload section",
  },
});
