import React from "react";
import { Tooltip } from "@jobber/components/Tooltip";
import { StatusLabel } from "@jobber/components";
import type { CampaignStatus } from "~/utilities/API/graphql";
import { useCampaignStatusInformation } from "jobber/campaigns/views/CampaignsLandingPage/components/CampaignStatusLabel/hooks/useCampaignStatusInformation";

export type StatusNode = CampaignStatus;
interface CampaignStatusLabelProps {
  status: StatusNode;
  statusMessage: string | undefined;
  scheduledAt?: string | undefined;
}

export function CampaignStatusLabel({
  status,
  statusMessage,
  scheduledAt,
}: CampaignStatusLabelProps) {
  const { statusText, statusIndicator, statusTooltip } =
    useCampaignStatusInformation(status, statusMessage, scheduledAt);

  return statusTooltip ? (
    <Tooltip message={statusTooltip}>
      <StatusLabel status={statusIndicator} label={statusText} />
    </Tooltip>
  ) : (
    <StatusLabel status={statusIndicator} label={statusText} />
  );
}
