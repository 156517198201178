import {
  ClientTypeCriteriaValues,
  JobTypeTypeEnum,
} from "~/utilities/API/graphql";
import type {
  AdditionalCriteriaClientTagCriteriaFragment,
  AdditionalCriteriaClientTypeCriteriaFragment,
  AdditionalCriteriaFragment,
  AdditionalCriteriaJobStatusCriteriaFragment,
  AdditionalCriteriaJobTypeCriteriaFragment,
  AdditionalCriteriaLineItemCriteriaFragment,
  Operator,
} from "~/utilities/API/graphql";
import {
  type AdditionalCriteriaUnion,
  type NewAdditionalCriteria,
  SupportedCriteriaTypes,
} from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/types";
import { isLineItemCriterion } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/components/AdditionalCriterion/AdditionalCriterion";

export type AdditionalCriteriaReducerActions =
  | {
      type: "setState";
      payload: {
        additionalCriteria?: AdditionalCriteriaFragment[];
        isDirty?: boolean;
      };
    }
  | {
      type: "resetState";
    }
  | { type: "addNewCriteria"; payload?: { isDirty?: boolean } }
  | { type: "removeCriteria"; payload: { index: number; isDirty?: boolean } }
  | {
      type: "updateCriteriaType";
      payload: {
        criteriaType: string;
        index: number;
        isDirty?: boolean;
      };
    }
  | {
      type: "updateClientTagCriteria";
      payload: {
        operator: Operator;
        index: number;
        clientTagValue: string;
        isDirty?: boolean;
      };
    }
  | {
      type: "updateClientTypeCriteria";
      payload: {
        index: number;
        clientTypeValue: ClientTypeCriteriaValues;
        isDirty?: boolean;
      };
    }
  | {
      type: "updateLineItemCriteria";
      payload: {
        operator: Operator;
        index: number;
        lineItemValue: { id: string; name: string } | undefined;
        isDirty?: boolean;
      };
    }
  | {
      type: "updateJobTypeCriteria";
      payload: {
        index: number;
        jobTypeValue: JobTypeTypeEnum;
        isDirty?: boolean;
      };
    }
  | {
      type: "updateJobStatusCriteria";
      payload: {
        index: number;
        isDirty?: boolean;
      };
    };

export function additionalCriteriaReducer(
  additionalCriteria: AdditionalCriteriaUnion[],
  action: AdditionalCriteriaReducerActions,
) {
  switch (action.type) {
    case "setState":
      if (!action.payload.additionalCriteria) {
        return additionalCriteria;
      }
      return [...filterCriteria(action.payload.additionalCriteria)];
    case "resetState":
      return [];
    case "addNewCriteria":
      additionalCriteria.push({
        __typename: undefined,
        index: additionalCriteria.length,
      });
      return [...additionalCriteria];
    case "removeCriteria":
      additionalCriteria.splice(action.payload.index, 1);
      return [...resetIndicies(additionalCriteria)];
    case "updateCriteriaType":
      return [
        ...additionalCriteria.map((criterion, index) => {
          if (index === action.payload.index) {
            return setCriteriaDefaults(action.payload.criteriaType, index);
          }

          return criterion;
        }),
      ];
    case "updateClientTagCriteria": {
      return [
        ...additionalCriteria.map((criterion, index) => {
          if (index === action.payload.index) {
            return {
              ...criterion,
              operator: action.payload.operator,
              clientTagValue: action.payload.clientTagValue,
            };
          }

          return criterion;
        }),
      ];
    }
    case "updateClientTypeCriteria": {
      return [
        ...additionalCriteria.map((criterion, index) => {
          if (index === action.payload.index) {
            return {
              ...criterion,
              clientTypeValue: action.payload.clientTypeValue,
            };
          }

          return criterion;
        }),
      ];
    }
    case "updateLineItemCriteria": {
      return [
        ...additionalCriteria.map((criterion, index) => {
          if (index === action.payload.index) {
            return {
              ...criterion,
              operator: action.payload.operator,
              lineItemValue: action.payload.lineItemValue,
            };
          }

          return criterion;
        }),
      ];
    }
    case "updateJobTypeCriteria": {
      return [
        ...additionalCriteria.map((criterion, index) => {
          if (index === action.payload.index) {
            return {
              __typename: "JobTypeCriteria" as const,
              index,
              jobTypeValue: action.payload.jobTypeValue,
            };
          }

          return criterion;
        }),
      ];
    }
    case "updateJobStatusCriteria": {
      // only used on component mount since this has no properties to update
      return [
        ...additionalCriteria.map((criterion, index) => {
          if (index === action.payload.index) {
            return {
              ...criterion,
            };
          }
          return criterion;
        }),
      ];
    }
  }
}

function setCriteriaDefaults(criteriaType: string, index: number) {
  switch (criteriaType) {
    case SupportedCriteriaTypes.CLIENT_TAGS.toString(): {
      return {
        __typename: criteriaType,
        index: index,
        operator: undefined,
        clientTagValue: undefined,
      } as unknown as AdditionalCriteriaClientTagCriteriaFragment;
    }
    case SupportedCriteriaTypes.LINE_ITEMS.toString(): {
      return {
        __typename: criteriaType,
        index: index,
        operator: undefined,
        lineItemValue: { id: undefined, name: undefined },
      } as unknown as AdditionalCriteriaLineItemCriteriaFragment;
    }
    case SupportedCriteriaTypes.JOB_TYPE.toString(): {
      return {
        __typename: criteriaType,
        index: index,
        jobTypeValue: JobTypeTypeEnum.RECURRING,
      } as unknown as AdditionalCriteriaJobTypeCriteriaFragment;
    }
    case SupportedCriteriaTypes.CLIENT_TYPE.toString(): {
      return {
        __typename: criteriaType,
        index: index,
        jobTypeValue: ClientTypeCriteriaValues.ALL_CLIENTS,
      } as unknown as AdditionalCriteriaClientTypeCriteriaFragment;
    }
    case SupportedCriteriaTypes.JOB_STATUS.toString(): {
      return {
        __typename: criteriaType,
        index: index,
      } as unknown as AdditionalCriteriaJobStatusCriteriaFragment;
    }
    default: {
      return {
        __typename: undefined,
        index: index,
      } as NewAdditionalCriteria;
    }
  }
}

function resetIndicies(criteria: AdditionalCriteriaUnion[]) {
  return criteria.map((criterion, index) => {
    return {
      ...criterion,
      index: index,
    };
  });
}

//This function is implemented to ensure that we do not display removed products and services in the additional criteria
function filterCriteria(allCriteria: AdditionalCriteriaUnion[]) {
  return allCriteria.filter(criterion => {
    return !(isLineItemCriterion(criterion) && !criterion.lineItemValue);
  });
}
