import type { ManualPagination } from "@jobber/components/DataTable";
import { useCallback, useEffect } from "react";
import type { LoadingState } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/ClientSegmentDrawer/ClientSegmentDrawer";
import type { ClientSegmentsData } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentData/useClientSegmentData";
import type { ClientSegmentTableClientData } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentTable/ClientSegmentTable";
import {
  ClientSegmentSortableFields,
  type GetCommsClientsSegmentQueryVariables,
  type Segment,
} from "~/utilities/API/graphql";
import {
  type SortState,
  useClientSegmentTableSorting,
} from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentTableSorting/useClientSegmentTableSorting";
import { useClientSegmentDataTablePaginationState } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentTablePaginationState/useClientSegmentTablePaginationState";
import { useClientDataForSelectedSegment } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientDataForSelectedSegment/useClientDataForSelectedSegment";

export interface UseClientSegmentTableArgs {
  selectedSegment: Segment;
  fetchData(variables: GetCommsClientsSegmentQueryVariables): void;
  refetch(variables: GetCommsClientsSegmentQueryVariables): void;
  loadingState: LoadingState;
  clientSegments: ClientSegmentsData;
}

export interface ClientSegmentTableData {
  clientData: ClientSegmentTableClientData[];
  total?: number;
}

export interface UseClientSegmentTable {
  pageState: PageState;
  getPaginationProps: (totalItems: number) => ManualPagination;
  resetPagination: () => void;
  clientSegment: ClientSegmentTableData;
  clientSegmentSortingState: [SortState<ClientSegmentSortableFields>];
  handleSortingChange: (
    sortState: [SortState<ClientSegmentSortableFields>],
  ) => void;
}

export interface PageState {
  pageIndex: number;
  pageSize: number;
}

export function useClientSegmentTable({
  selectedSegment,
  refetch,
  fetchData,
  loadingState,
  clientSegments,
}: UseClientSegmentTableArgs): UseClientSegmentTable {
  const { endCursor, pageSize, pageState, setPagination, getPaginationProps } =
    useClientSegmentDataTablePaginationState();

  const { formatSort, clientSegmentSortingState, handleSortChange } =
    useClientSegmentTableSorting(
      ClientSegmentSortableFields.NAME,
      setPagination,
      false,
    );

  const resetPagination = useCallback(() => {
    setPagination({ pageIndex: 0, pageSize: pageSize });
    const after = btoa((0).toString());
    refetch({
      first: pageSize,
      after,
    });
  }, [pageSize, refetch, setPagination]);

  const handleSortingChange = useCallback(
    (value: [SortState<ClientSegmentSortableFields>]) => {
      if (!loadingState.fullReload && !loadingState.loadingMore) {
        handleSortChange(value);
      }
    },
    [loadingState, handleSortChange],
  );

  useEffect(() => {
    resetPagination();
  }, [resetPagination]);

  useEffect(() => {
    const { key, direction } = formatSort(clientSegmentSortingState);
    fetchData({
      first: pageSize,
      after: endCursor,
      sort: [
        {
          key,
          direction,
        },
      ],
    });
  }, [pageSize, fetchData, endCursor, clientSegmentSortingState, formatSort]);

  const { clientSegmentData, total } = useClientDataForSelectedSegment({
    clientSegments,
    selectedSegment,
  });

  return {
    pageState,
    getPaginationProps,
    resetPagination,
    clientSegment: {
      clientData: clientSegmentData,
      total: total,
    },
    clientSegmentSortingState,
    handleSortingChange,
  };
}
