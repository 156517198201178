import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancelModalTitle: {
    id: "subscriptionAddons.addonSubscriptionModal.cancelModalTitle",
    defaultMessage: "Cancel {addonName}?",
    description: "Cancel modal title",
  },
  reactivateModalTitle: {
    id: "subscriptionAddons.addonSubscriptionModal.reactivateModalTitle",
    defaultMessage: "Reactivate {addonName}?",
    description: "Reactivate modal title",
  },
  cancel: {
    id: "subscriptionAddons.addonSubscriptionModal.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button label",
  },
  unsubscribe: {
    id: "subscriptionAddons.addonSubscriptionModal.unsubscribe",
    defaultMessage: "Unsubscribe",
    description: "Unsubscribe button label",
  },
  reactivate: {
    id: "subscriptionAddons.addonSubscriptionModal.reactivate",
    defaultMessage: "Reactivate",
    description: "Reactivate button label",
  },
  cancelAriaLabel: {
    id: "subscriptionAddons.addonSubscriptionModal.cancelAriaLabel",
    defaultMessage: "Cancel { addonName }",
    description: "Cancel button aria label",
  },
  reactivateAriaLabel: {
    id: "subscriptionAddons.addonSubscriptionModal.reactivateAriaLabel",
    defaultMessage: "Reactivate to { addonName }",
    description: "Reactivate button aria label",
  },
  cancellationInformation: {
    id: "subscriptionAddons.addonSubscriptionModal.cancellationInformation",
    defaultMessage:
      "At the end of your billing period you will lose access to {addonCancellationDetails}, including:",
    description: "Information about what happens when you cancel an addon",
  },
  reactivationInformation: {
    id: "subscriptionAddons.addonSubscriptionModal.reactivationInformation",
    defaultMessage:
      "Your add-on is set to be cancelled. Reactivate your subscription to access all of your add-on functionality and data.",
    description:
      "Information about what happens when you reactivate a cancelled addon",
  },
  campaigns: {
    id: "subscriptionAddons.addonSubscriptionModal.Campaigns",
    defaultMessage: "Campaigns",
    description: "The Campaigns add-on you will lose",
  },
  referrals: {
    id: "subscriptionAddons.addonSubscriptionModal.Referrals",
    defaultMessage: "Referrals",
    description: "The Referrals add-on you will lose",
  },
  reviews: {
    id: "subscriptionAddons.addonSubscriptionModal.Reviews",
    defaultMessage: "Reviews",
    description: "The Reviews add-on you will lose",
  },
  marketingSuiteDetails: {
    id: "subscriptionAddons.addonSubscriptionModal.marketingSuiteDetails",
    defaultMessage: "all add-ons (Reviews, Referrals, and Campaigns)",
    description: "The addons you will lose if you cancel the marketing suite",
  },
  cancellationDisclaimerMessage: {
    id: "subscriptionAddons.addonSubscriptionModal.cancellationDisclaimerMessage",
    defaultMessage:
      "You will still have access to the addon until the end of this billing period",
    description: "Information about the cancellation policy",
  },
  reactivationDisclaimerMessage: {
    id: "subscriptionAddons.addonSubscriptionModal.reactivationDisclaimerMessage",
    defaultMessage:
      "You won't be charged today for the remainder of your billing cycle. Once reactivated, your {addonName} subscription will automatically renew at {monthlyCost} on {renewalDate}.",
    description: "Information about the reactivation policy",
  },
  campaignsFeature1: {
    id: "subscriptionAddons.addonSubscriptionModal.campaignsFeature1",
    defaultMessage: "creating and sending campaigns",
    description: "A feature that will be lost when cancelling campaigns",
  },
  campaignsFeature2: {
    id: "subscriptionAddons.addonSubscriptionModal.campaignsFeature2",
    defaultMessage: "viewing and tracking past campaigns",
    description: "A feature that will be lost when cancelling campaigns",
  },
  referralsFeature1: {
    id: "subscriptionAddons.addonSubscriptionModal.referralsFeature1",
    defaultMessage: "automating incentives for referrals",
    description: "A feature that will be lost when cancelling referrals",
  },
  referralsFeature2: {
    id: "subscriptionAddons.addonSubscriptionModal.referralsFeature2",
    defaultMessage: "tracking and reporting on referrals",
    description: "A feature that will be lost when cancelling referrals",
  },
  reviewsFeature1: {
    id: "subscriptionAddons.addonSubscriptionModal.reviewsFeature1",
    defaultMessage: "automatic review requests",
    description: "A feature that will be lost when cancelling reviews",
  },
  reviewsFeature2: {
    id: "subscriptionAddons.addonSubscriptionModal.reviewsFeature2",
    defaultMessage: "viewing and tracking reviews data",
    description: "A feature that will be lost when cancelling reviews",
  },
  marketingSuiteFeature1: {
    id: "subscriptionAddons.addonSubscriptionModal.marketingSuiteFeature1",
    defaultMessage: "Reviews: automatically request reviews from customers",
    description: "A feature that will be lost when cancelling marketing suite",
  },
  marketingSuiteFeature2: {
    id: "subscriptionAddons.addonSubscriptionModal.marketingSuiteFeature2",
    defaultMessage:
      "Referrals: track and reward customers that refer your business",
    description: "A feature that will be lost when cancelling marketing suite",
  },
  marketingSuiteFeature3: {
    id: "subscriptionAddons.addonSubscriptionModal.marketingSuiteFeature3",
    defaultMessage:
      "Campaigns: create and send campaigns to engage with your customers",
    description: "A feature that will be lost when cancelling marketing suite",
  },
  addonCancelled: {
    id: "subscriptionAddons.addonSubscriptionModal.addonCancelled",
    defaultMessage: "Cancelled {addonName}",
    description: "Message displayed when an addon is cancelled",
  },
  addonReactivated: {
    id: "subscriptionAddons.addonSubscriptionModal.addonReactivated",
    defaultMessage: "Reactivated {addonName}",
    description: "Message displayed when an addon is cancelled",
  },
  addonPurchasedIndividually: {
    id: "subscriptionAddons.addonSubscriptionModal.addonPurchasedIndividually",
    defaultMessage: "All add-ons may be purchased individually.",
    description:
      "Message displayed when the marketing suite addon is cancelled",
  },
  // This is used when the GQL mutation fails, but we don't get the error message back from the userErrors
  genericError: {
    id: "subscriptionAddons.addonSubscriptionModal.genericError",
    defaultMessage:
      "An unexpected error occurred, please contact us at 1-888-721-1115",
    description: "Generic error message when cancelling an addon fails",
  },
});
