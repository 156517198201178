import React, { useCallback, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  Breakpoints,
  useResizeObserver,
} from "@jobber/hooks/useResizeObserver";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { JobberLogo } from "jobber/jobberLogo/JobberLogo";
import jobberBlueprintLogo from "@images/jobber-blueprint-logo.svg";
import { withIntlProvider } from "@translations/withIntlProvider";
import { join } from "jobber/settings/users/utilities/routing";
import { useSetupWizardSteps } from "jobber/setupWizard/hooks/useSetupWizardSteps";
import { messages as jobberLogoMessages } from "jobber/jobberLogo/messages";
import styles from "./SetupWizardRoute.module.css";
import type {
  IndustryCategory,
  MessageFormat,
  PromoDetails,
  SetupWizardStep,
  SignupConsentData,
} from "./types";
import { messages } from "./messages";
import { PreloadStepImages } from "./components/PreloadStepImages";
import { SetupWizardContext } from "./context";
import {
  communityForum,
  lastSubmittedStepQuestionKey,
} from "./components/constants";
import { safelyExtractAnswer } from "./utils";

export interface SetupWizardRouteProps {
  industryMap: IndustryCategory[];
  promotionData: {
    promoTrackingTag: string;
    showPromo: boolean;
    showPromoStatusQuestionKey: string;
    showPromoStatusInterestedValue: string;
    promoDetails: PromoDetails;
  };
  attrName: string;
  signupConsentData: SignupConsentData;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// eslint-disable-next-line max-statements
function SetupWizardRouteWrapper({
  industryMap,
  promotionData,
  attrName,
  signupConsentData,
}: SetupWizardRouteProps) {
  const { formatMessage } = useIntl();
  const { path } = useRouteMatch();
  const [ref, { exactWidth }] = useResizeObserver<HTMLDivElement>();
  const isSmallScreen = (exactWidth as number) <= Breakpoints.small;
  const { wizardData } = useContext(SetupWizardContext);

  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  const {
    isLoading: isCalculatingSteps,
    currentSteps,
    currentStepIndex,
    showProgressBar,
    onNext,
  } = useSetupWizardSteps(promotionData.showPromo, attrName, isMobileDevice);

  const redirectToIncompleteStep = useCallback(() => {
    if (!wizardData.questionsAndAnswers) {
      return <Redirect to={currentSteps[0].route} />;
    }

    const lastSubmittedRoute = safelyExtractAnswer(
      lastSubmittedStepQuestionKey,
      wizardData.questionsAndAnswers,
    );

    if (!lastSubmittedRoute) return <Redirect to={currentSteps[0].route} />;

    const lastSubmittedIndex = currentSteps
      .map(x => x.route.toString().toLowerCase())
      .indexOf(lastSubmittedRoute.toLowerCase());

    // math.min so we don't go outside of the array's bounds
    const safeLastSubmittedIndex = Math.min(
      currentSteps.length - 1,
      lastSubmittedIndex + 1,
    );

    return <Redirect to={currentSteps[safeLastSubmittedIndex].route} />;
  }, [currentSteps, wizardData.questionsAndAnswers]);

  const renderHeader = () => {
    return (
      <div className={styles.static}>
        {attrName === communityForum ? (
          <img
            data-testid="jobberLogo"
            className={styles.largeBlueprintImage}
            src={jobberBlueprintLogo}
            alt={formatMessage(jobberLogoMessages.jobberLogoAltText)}
          />
        ) : (
          <JobberLogo className={styles.logo} />
        )}
        {showProgressBar && (
          <div
            data-testid="setupWizardControls"
            className={styles.setupWizardControls}
          >
            <ProgressBar
              currentStep={currentStepViewable(currentSteps, currentStepIndex)}
              totalSteps={totalStepsViewable(currentSteps)}
              size={"small"}
            />
          </div>
        )}
      </div>
    );
  };

  const renderRoutes = (formatFunc: (value: MessageFormat) => string) => {
    return (
      <Switch>
        {currentSteps.map((step: SetupWizardStep, index: number) => {
          const CurrentStep = step.component;
          return (
            <Route
              key={index}
              exact
              path={join(path, step.route)}
              render={() => (
                <CurrentStep
                  navigation={{
                    onNext,
                    isFinalStep: index === currentSteps.length - 1,
                    nextCtaCopy: nextCtaCopy(currentSteps, index, formatFunc),
                  }}
                  isSmallScreen={isSmallScreen}
                  industryMap={industryMap}
                  promoDetails={promotionData.promoDetails}
                  promoTrackingTag={promotionData.promoTrackingTag}
                  showPromoStatusQuestionKey={
                    promotionData.showPromoStatusQuestionKey
                  }
                  showPromoStatusInterestedValue={
                    promotionData.showPromoStatusInterestedValue
                  }
                  signupConsentData={signupConsentData}
                  attrName={attrName}
                />
              )}
            />
          );
        })}
        <Route path="*" render={redirectToIncompleteStep} />
      </Switch>
    );
  };

  return (
    <div ref={ref}>
      {isCalculatingSteps ? (
        <></>
      ) : (
        <div
          data-testid="setupWizardRoute"
          className={styles.setupWizardContainer}
        >
          {renderHeader()}
          <ScrollToTop />
          {renderRoutes(formatMessage)}
          <PreloadStepImages
            currentSteps={currentSteps}
            currentStepIndex={currentStepIndex}
          />
        </div>
      )}
    </div>
  );
}

function currentStepViewable(
  currentSteps: SetupWizardStep[],
  currentStepIndex: number,
) {
  const completedSteps = currentSteps.slice(0, currentStepIndex);
  return completedSteps.reduce(
    (greatestIndexSoFar, step) => {
      return step.includeInProgress
        ? greatestIndexSoFar + 1
        : greatestIndexSoFar;
    },
    1, // Offset zero indexing. We want progress bar to start at 1, not 0.
  );
}

function totalStepsViewable(currentSteps: SetupWizardStep[]) {
  return currentSteps.reduce((stepCount: number, step) => {
    return step.includeInProgress ? stepCount + 1 : stepCount;
  }, 0);
}

function nextCtaCopy(
  currentSteps: SetupWizardStep[],
  currentStepIndex: number,
  formatFunc: (message: MessageFormat) => string,
): string {
  return currentStepViewable(currentSteps, currentStepIndex) ===
    totalStepsViewable(currentSteps)
    ? formatFunc(messages.finishButtonLabel)
    : formatFunc(messages.nextButtonLabel);
}

export const SetupWizardRoute = withIntlProvider(SetupWizardRouteWrapper);
