import { defineMessages } from "react-intl";

export const messages = defineMessages({
  editButton: {
    id: "billing.paymentDetails.editButton",
    defaultMessage: "Edit",
    description: "Button text for edit button",
  },
  editPaymentButton: {
    id: "billing.paymentDetails.editPaymentButton",
    defaultMessage: "Edit Payment",
    description: "Button text for edit payment button",
  },
  cardTitle: {
    id: "billing.paymentDetails.cardTitle",
    defaultMessage: "Payment Information",
    description: "Title for form section Card",
  },
  paymentDetailsError: {
    id: "billing.paymentDetails.paymentDetailsError",
    defaultMessage: "Payment Error",
    description:
      "Text to indicate that there was an error with the payment details",
  },
  cancelButton: {
    id: "billing.paymentDetails.cancelButton",
    defaultMessage: "Cancel",
    description: "Button text for cancel button",
  },
  editButtonAriaLabel: {
    id: "billing.paymentDetails.editButtonArialLabel",
    defaultMessage: "Edit payment details",
    description: "Aria label for edit payment details button",
  },
  cancelButtonAriaLabel: {
    id: "billing.paymentDetails.cancelButtonArialabel",
    defaultMessage: "Cancel editing payment details",
    description: "Aria label for cancel edit payment details button",
  },
});
