import { Card } from "@jobber/components/Card";
import React from "react";
import { Divider } from "@jobber/components/Divider";
import type { ServerCreditCard } from "jobber/payments_sca/utils/mapServerCreditCard";
import { withRailsPropsAsContexts } from "~/utilities/contexts/internal/withRailsPropsAsContexts";
import type { Payout } from "./NextPayout";
import styles from "./PaymentsCard.module.css";
import {
  DisputesWaitingResponseRow,
  type DisputesWaitingResponseRowProps,
} from "./DisputesWaitingResponseRow";
import { PayoutDetails } from "./PayoutDetails";

interface JobberMoney {
  payoutIsJobberMoneyAccount: boolean;
  balance?: number;
  lastPayoutAmount?: number;
}

export interface PaymentsCardProps {
  nextPayouts: Payout[];
  canViewInstantPayouts: boolean;
  instantAvailableAmount: string;
  instantPayoutFeeMultiplier: string;
  currencyUnit: string;
  debitCard: ServerCreditCard | undefined;
  debitCardIsValid: boolean;
  showHeader?: boolean;
  disputesWaitingResponse: DisputesWaitingResponseRowProps;
  jobberMoney: JobberMoney;
}

function PaymentsCard(
  props: PaymentsCardProps & {
    shouldShowJobberPaymentCard: boolean;
    showPayoutDetails?: boolean;
  },
) {
  const {
    disputesWaitingResponse,
    showHeader = true,
    shouldShowJobberPaymentCard = true,
    showPayoutDetails = true,
  } = props;

  return (
    <div className={`${styles.cardContent} ${styles.dashboardContent}`}>
      <Card header={showHeader ? "Payments" : undefined}>
        <>
          {shouldShowJobberPaymentCard ? (
            <>
              {showPayoutDetails && <PayoutDetails {...props} />}

              {disputesWaitingResponse && (
                <>
                  {showPayoutDetails && <Divider />}
                  <DisputesWaitingResponseRow {...disputesWaitingResponse} />
                </>
              )}
            </>
          ) : (
            disputesWaitingResponse && (
              <DisputesWaitingResponseRow {...disputesWaitingResponse} />
            )
          )}
        </>
      </Card>
    </div>
  );
}

const Enhanced = withRailsPropsAsContexts()(PaymentsCard);
export { Enhanced as PaymentsCard };
