import React, { useEffect } from "react";
import { Option, Select } from "@jobber/components/Select";
import { useIntl } from "react-intl";
import { ClientTypeCriteriaValues } from "~/utilities/API/graphql";
import type { AdditionalCriteriaReducerActions } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignAdditionalSegmentCriteriaReducer";
import { messages } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/AdditionalCriteria/messages";

interface ClientTypeCriterionProps {
  clientTypeValue: ClientTypeCriteriaValues;
  criteriaIndex: number;
  updateAdditionalCriteria: React.Dispatch<AdditionalCriteriaReducerActions>;
}

export function ClientTypeCriterion({
  clientTypeValue,
  criteriaIndex,
  updateAdditionalCriteria,
}: ClientTypeCriterionProps): JSX.Element {
  const { formatMessage } = useIntl();

  const selectValue = clientTypeValue;

  const onSelectionChange = (value: ClientTypeCriteriaValues) => {
    updateAdditionalCriteria({
      type: "updateClientTypeCriteria",
      payload: {
        index: criteriaIndex,
        clientTypeValue: value,
        isDirty: true,
      },
    });
  };

  //This is to make sure the jobTypeValue and clientTypeValue is updated in the store when the component is mounted
  useEffect(() => {
    updateAdditionalCriteria({
      type: "updateClientTypeCriteria",
      payload: {
        index: criteriaIndex,
        clientTypeValue: selectValue
          ? selectValue
          : ClientTypeCriteriaValues.ALL_CLIENTS,
        isDirty: true,
      },
    });
    // We only want this to fire when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid={"clientTypeCriterion"}>
      <div>
        <Select defaultValue={selectValue} onChange={onSelectionChange}>
          <Option value={ClientTypeCriteriaValues.ALL_CLIENTS}>
            {formatMessage(messages.clientTypeAllClients)}
          </Option>
          <Option value={ClientTypeCriteriaValues.IS_LEAD}>
            {formatMessage(messages.clientTypeIsLead)}
          </Option>
        </Select>
      </div>
    </div>
  );
}
